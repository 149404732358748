import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../Components/footer';
import Navbar from '../../Components/navbar';
import useStyle from './style.js';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Filter from './filter';
import './style.css';
import Product from './product';
import { useEffect } from 'react';
import { useState } from 'react';
import apiservice from '../../Helpers/apiservices';
import { InputLabel } from '@mui/material';
import { I18nContext } from '../../contexts/i18n';
import ShimmerCard from '../../shared/ShimmerCard';

const ProductListing = () => {
    const { state = {} } = useLocation();
    const classes = useStyle();
    const { translate } = useContext(I18nContext);
    const [sortData, setSortData] = React.useState('');
    const [productList, setProductList] = useState([]);
    const [filterValues, setFilterValues] = useState({});
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const SORT_VALUES = (val) => {
        if (val === 'idDes') {
            return { orderBy: 'id', orderByValue: 'desc' }
        } else if (val === 'ratingDes') {
            return { orderBy: 'rating', orderByValue: 'desc' }
        } else if (val === 'priceAsc') {
            return { orderBy: 'price', orderByValue: 'asc' }
        } else if (val === 'priceDes') {
            return { orderBy: 'price', orderByValue: 'desc' }
        } else {
            return {}
        }
    }

    const emptySort = () => {
        setSortData('');
    }

    const FILTER_KEYS = {
        "brand": "filterBrandId",
        "categoryId": "filterCategoryId",
        "subCategory": "filterSubCategoryId",
        "color": "color",
        "madeIn": "madeIn"
    }

    const handleChange = async (event) => {
        setSortData(event.target.value);
        const stateObj = { [`${FILTER_KEYS[state?.type]}`]: state?.id }
        let payload;
        if (state) {
            payload = { ...SORT_VALUES(event.target.value), ...stateObj, ...filterValues };
        } else {
            payload = { ...SORT_VALUES(event.target.value), ...filterValues };
        }
        setLoader(true)
        const res = await apiservice.getSortedData(payload);
        if (res.success) {
            setProductList([...res.data])
        } else {
            setProductList([])
            console.error(res.error);
        }
        setLoader(false);
    };

    const filteredData = async (payload) => {
        setLoader(true);
        setFilterValues(payload);
        const res = await apiservice.getFilteredData(payload);
        if (res.success) {
            setProductList([...res.data])
        } else {
            setProductList([])
            console.error(res.error);
        }
        setLoader(false);
    };

    return (
        <>
            <Navbar />
            {loader && <ShimmerCard />}
            <div className={classes.wholeContainer}>
                <div className={classes.sortBy}>
                    <FormControl sx={{ m: 1, minWidth: 150, float: 'right', textAlign: 'left' }}>
                        <InputLabel id="demo-simple-select-helper-label">{translate("Sort by")}</InputLabel>
                        <Select
                            value={sortData}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            label={translate("Sort by")}
                        >
                            <MenuItem value={'idDes'}>{translate("Newest First")}</MenuItem>
                            <MenuItem value={'ratingDes'}>{translate("Popularity")}</MenuItem>
                            <MenuItem value={'priceAsc'}>{translate("Price -- Low to High")}</MenuItem>
                            <MenuItem value={'priceDes'}>{translate("Price -- High to Low")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.productListSec}>
                    <div className="row borderTops">
                        <div className="col-md-3 borderRights">
                            <Filter filteredData={filteredData} emptySort={emptySort} state={state}/>
                        </div>
                        <div className="col-md-9">
                            <Product state={state} productList={productList} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )

}

export default ProductListing;