/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import Images from '../../../Helpers/images';
import useStyle from '../style';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Carousel, { consts } from "react-elastic-carousel";
import { CAROUSEL_BREAKPOINTS } from '../../technician/technician-constant';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { I18nContext } from '../../../contexts/i18n';

const OtherProducts = ({ relatedProducts }) => {

    const classes = useStyle();
    const navigate = useNavigate();
    const { translate } = useContext(I18nContext);

    const onRightBtnClick = (e, isEdge, onClick) => {
        e.preventDefault();
        e.stopPropagation();
        if (isEdge) {
            navigate('/productList');
        } else {
            onClick()
        }
    }

    const renderArrows = ({ type, onClick, isEdge }) => {
        return type === consts.PREV ?
            <Button onClick={onClick} className={classes.arrowButton} sx={{ display: isEdge ? 'none' : 'block' }}>
                <KeyboardArrowLeftIcon className={classes.arrowIcon} sx={{ display: isEdge ? 'none' : 'block' }} />
            </Button>
            : <Button onClick={(e) => onRightBtnClick(e, isEdge, onClick)} className={classes.arrowButton} >
                <KeyboardArrowRightIcon className={classes.arrowIcon} />
            </Button>
    }

    return (
        <>
            {
                relatedProducts?.length && relatedProducts.map((product, ind) => {
                    return <div key={`product-${ind}`}>
                        {
                            product?.title ? <div className={classes.whyAwall}>
                                {product?.data?.length ? <h5 className={classes.popularHeader}>{product.title}</h5> : null}
                                <div className={classes.productSection}>
                                    {
                                        product?.data?.length ?
                                            <div className={classes.insideProduct}>
                                                <Carousel breakPoints={CAROUSEL_BREAKPOINTS} pagination={false}
                                                    renderArrow={renderArrows}
                                                >
                                                    {
                                                        product.data?.map((item, index) => {
                                                            return (
                                                                <div key={`item-${index}`} className={classes.card} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    navigate(`/productDetails/${item?.id}`, { replace: false });
                                                                    window.location.reload();
                                                                }}>
                                                                    <div className={classes.topCard}>
                                                                        <button className={classes.ratingBtn}>{item?.rating ? `${item.rating}.0` : '0.0'} <img src={Images.star} className={classes.star} /></button>
                                                                        {item?.isFavorites ? <FavoriteIcon sx={{ fill: '#da1739', cursor: 'pointer' }} />
                                                                            : <img src={Images.heart} className={classes.heartImg} />
                                                                        }
                                                                    </div>

                                                                    <img src={item?.image?.[0]?.url} className={classes.cardImg} />

                                                                    <p className={classes.productBrandName}>{item?.brandName}</p>
                                                                    {
                                                                        item?.modelNumber ? <p className="modelNumber">{item.modelNumber}</p>
                                                                            : null
                                                                    }
                                                                    <p className={classes.productName}>{item?.name}</p>
                                                                    <div className="prices">
                                                                        {item?.originalPrice && <p className={classes.oldPrice}>{`${translate("SAR")} ${item?.originalPrice}`}</p>}
                                                                        <p className={classes.originalPrice}>{`${translate("SAR")} ${item?.price}`}</p>

                                                                    </div>
                                                                    {
                                                                        item?.isStock ? <p className={classes.stock}>{translate("In Stock")}</p>
                                                                            : <p className={classes.outOfStock}>{translate("Out of stock")}</p>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            </div> : null
                                    }
                                </div>
                            </div>
                                : null
                        }
                    </div>
                })
            }
        </>
    )

}

export default OtherProducts;