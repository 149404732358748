/* eslint-disable no-unused-vars */
import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CustomSwitch } from '../../../shared/CustomSwitch.js';
import { ExpandCollapseWidget } from '../../../shared/ExpandCollapse.js';
import useStyle from '../styles.js'
import { CircularLoader } from '../../../shared/CircularLoader.js';
import apiservice from '../../../Helpers/apiservices.js';
import { I18nContext } from '../../../contexts/i18n.js';

export const Settings = () => {

  const { translate } = useContext(I18nContext);
  const [loader, setLoader] = useState(false);
  const [urls, setUrls] = useState({});

  useEffect(()=>{
    getURLS();
  }, [])

  const getURLS = async () => {
    setLoader(true)
    const res = await apiservice.getURLS();
    if (res.success) {
      let data = res?.data;
      setUrls(data);
    } else {
      console.error(res.error);
      setUrls([]);
    }
    setLoader(false);
  };

  const NOTIFICATIONS_TITLES = [
    { title: 'Terms & Condition', value: 'Terms & Condition', url:urls.termsAndCondition  },
    { title: 'Privacy Policy', value: 'Privacy Policy', url:urls.privacyPolicy },
    { title: 'About us', value: 'About us',url:urls.about },
    // { title: 'Support', value: 'Support', url:urls.support },
    // { title: 'Frequently Asked Questions', value: 'Frequently Asked Questions', url:urls.faq }
  ];

  return <Grid sx={{ margin: '1em 0 1em 2em' }}>
    {loader && <Grid><CircularLoader overlay={true} /></Grid>}
    {/* <ExpandCollapseWidget
      config={{ expandStatus: false, title: 'Notification', CustomIcon: CustomSwitch }} >
    </ExpandCollapseWidget> */}
    {
      NOTIFICATIONS_TITLES.map((notify, index) => {
        return <Grid item xs={12} key={`${notify.value}-${index}-container`} >
          <ExpandCollapseWidget
            config={{ expandStatus: false, title:`${translate(notify.title)}`, subTitle: notify.subTitle, url: notify.url }} >
          </ExpandCollapseWidget>
        </Grid>;
      })
    }
  </Grid>
}
