import React, { useContext, useState } from 'react';
import useStyles from '../styles';
import { Button, Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import Carousel from 'react-elastic-carousel'
import { CardMedia, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CAROUSEL_BREAKPOINTS, CHECK_AWAL_PRODUCT } from '../technician-constant';
import { CommonDialog } from '../../../shared/CommonDialog';
import useSnackBar from '../../../hooks/useSnackBar';
import { I18nContext } from '../../../contexts/i18n';

const Services = (props) => {
  const classes = useStyles();
  const { showSnack } = useSnackBar();
  const { handleStepClick = () => { }, services = [], getService=()=>{} } = props;
  const [dialogOpen, setDialogOpen] = useState(false)
  const [value, setValue] = React.useState('');
  const { translate } = useContext(I18nContext);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  const handleProceedBtnClick = () => {
    if(value){
    handleStepClick();
    }else{
      showSnack({ open: true, type: 'error', message: <>{translate('Select a value')}</> });
    }
  }

  const getDialogContent = () => {
    return <>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {
            CHECK_AWAL_PRODUCT?.map((item, index) => {
              return <Grid key={`item-${index}`}>
                <FormControlLabel value={item.value} control={<Radio className={classes.radioBtn} />} label={translate(item.label)} />
                {index === 0 && <p style={{marginLeft: "2em", color: "#f0493e"}}>{translate("Exclusive for Marco, Universal Air, Toshiba & MRJ items")}</p>}
              </Grid>
            })
          }
        </RadioGroup>
        <Button className={classes.proceedBtn} onClick={handleProceedBtnClick}>{translate("PROCEED")}</Button>
      </FormControl>
    </>
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container sx={{ margin: '1em', flexWrap: 'nowrap', alignItems: 'center' }} >
          <Carousel breakPoints={CAROUSEL_BREAKPOINTS} pagination={false}>
            {
              services && services?.length ? services.map((list, index) => {
                return <Card key={`list-${index}`} className={classes.card} onClick={() =>{ 
                  getService(list, index)
                  setDialogOpen(true)
                  }}>
                  <CardMedia
                    component="img"
                    alt="service image"
                    style={{ height: '90px', width: '100px' }}
                    image={list.image}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {list.name}
                    </Typography>
                  </CardContent>
                  <ArrowForwardIosIcon sx={{ marginLeft: 'auto' }} />
                </Card>
              }) : null
            }
          </Carousel>
        </Grid>
      </Paper>

      <CommonDialog options={{ maxWidth: 'md', dialogOpen: dialogOpen }} onDialogClose={handleDialog} >
        {getDialogContent()}
      </CommonDialog>

    </>
  )

}

export default Services;