import React from 'react';

const ShimmerCard = () => {
  return (
    <div>
    <div className='cardGrid'>
    <div className="shimmer-card">
      <div className="shimmer-card-image"></div>
      <div className="shimmer-card-content">
        <div className="shimmer-card-title"></div>
        <div className="shimmer-card-description"></div>
      </div>
    </div>
     <div className="shimmer-card">
     <div className="shimmer-card-image"></div>
     <div className="shimmer-card-content">
       <div className="shimmer-card-title"></div>
       <div className="shimmer-card-description"></div>
     </div>
   </div>
    <div className="shimmer-card">
    <div className="shimmer-card-image"></div>
    <div className="shimmer-card-content">
      <div className="shimmer-card-title"></div>
      <div className="shimmer-card-description"></div>
    </div>
  </div>
  </div>
  <div>
  <div className="shimmer-card">
    <div className="shimmer-card-image"></div>
    <div className="shimmer-card-content">
      <div className="shimmer-card-title"></div>
      <div className="shimmer-card-description"></div>
    </div>
  </div>
   <div className="shimmer-card">
   <div className="shimmer-card-image"></div>
   <div className="shimmer-card-content">
     <div className="shimmer-card-title"></div>
     <div className="shimmer-card-description"></div>
   </div>
 </div>
  <div className="shimmer-card">
  <div className="shimmer-card-image"></div>
  <div className="shimmer-card-content">
    <div className="shimmer-card-title"></div>
    <div className="shimmer-card-description"></div>
  </div>
</div>
</div>
</div>
  );
};

export default ShimmerCard;
