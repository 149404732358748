/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Images from '../../../Helpers/images';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/i18n';

export const Reviews = ({ reviews }) => {
    const { translate } = useContext(I18nContext);
    return <div className="rating">
        <h4 className="review_txt">{translate("Ratings & Reviews")}</h4>

        {
            reviews?.length ? reviews.map((review, index) => {
                return <div className="start_ratings" key={`review-${index}`}>
                    <div className="rate_star">
                        {review?.rating ? `${review?.rating}.0`: '0.0'}
                        <img src={Images.whiteStar} className="start" />
                    </div>
                    <div>
                        <p style={{ textAlign: 'left', marginLeft: '1em', marginTop: '-0.3em' }}>{review.firstName} {review.lastName}</p>
                        <p className="ratingTxt" >{review?.review}</p>
                    </div>
                </div>
            }) : <div>
                <p>{translate("No Ratings yet.")}</p>
            </div>
        }

    </div>
}