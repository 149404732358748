import React, { useEffect, useState } from 'react';
import Footer from '../../Components/footer';
import Navbar from '../../Components/navbar';
import useStyles from './styles';
import Services from './components/services';
import ProductSubServices from './components/productSubServices';
import ServiceDetails from './components/serviceDetails';
import BookingSummary from './components/bookingSummary';
import apiservice from '../../Helpers/apiservices';
import { Grid } from '@mui/material';
import { CircularLoader } from '../../shared/CircularLoader';
import ProductMainServices from './components/productIMainServices';
import { IMAGE_PLACEHOLDER } from '../../APP_CONST';

const Technician = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [services, setServices] = useState({});
  const [loader, setLoader] = useState(true);
  const [service, setService] = useState([]);
  const [mainService, setMainService] = useState({});
  const [subService, setSubService] = useState([]);
  const [address, setAddress] = useState({});

  useEffect(() => {
    getServices();
  }, [])

  const getServices = async () => {
    setLoader(true)
    const res = await apiservice.getServices();
    if (res.success) {
      setServices(res);
    } else {
      console.error(res.error);
      setServices({});
    }
    setLoader(false);
  };

  const getService = (data, index) => {
    const tempArr = [];
    tempArr.push({ data: data, index: index });
    setService([...tempArr, ...service]);
  }

  const getMainService = (data) => {
    setMainService(data?.id);
  }

  const getAddress = (data) => {
    setAddress(data);
  }

  const getSubService = (data) => {
    const tempArr = [];
    tempArr.push(data);
    setSubService([...tempArr, ...subService]);
  }

  const handleStepClick = () => {
    setActiveStep(activeStep + 1);
  }

  const handleStepDownClick = (step) => {
    setActiveStep(step);
  }

  const goToSubService = (data) => {
    const { step, id } = data;
    setActiveStep(step);
    setMainService(id);
  }

  return (
    <>
      <Navbar />

      {
        activeStep === 0 && <>
          {loader && <Grid><CircularLoader overlay={true} /></Grid>}
          <img alt='img' src={'../../../images/Techician Banner .png'} className={classes.bannerImg} />
          <Services handleStepClick={handleStepClick} services={services?.data} getService={getService} />
        </>
      }

      {
        activeStep === 1 && <>
          <img alt='img' src={services?.bannerList?.[0]?.image || IMAGE_PLACEHOLDER} className={classes.bannerImg} />
          <ProductMainServices handleStepClick={handleStepClick} serviceId={services?.data?.[service?.[0]?.index]?.id} getMainService={getMainService} />
        </>
      }

      {
        activeStep === 2 && <><ProductSubServices handleStepClick={handleStepClick} subService={subService} mainService={mainService} getSubService={getSubService} getAddress={getAddress} address={address} /></>
      }

      {
        activeStep === 3 && <><ServiceDetails servicess={services?.data} handleStepClick={handleStepClick} handleStepDownClick={handleStepDownClick} services={service} subServices={subService} address={address} goToSubService={goToSubService} /></>
      }

      {
        activeStep === 4 && <><BookingSummary handleStepClick={handleStepClick} /></>
      }

      <Footer />
    </>
  )

}

export default Technician;