
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wholeContainer: {
        width: '92%',
        margin: '0px auto',
        marginTop: '100px',
        '& .MuiAccordion-root': {
            marginTop: '23px',
            boxShadow: 'none',
            '&::before': {
                backgroundColor: '#fff'
            }
        },
        '& .MuiTypography-root': {
            color: ' #030202',
            textAlign: 'left',
            padding: '6px 0px'
        }
    },
    select: {
        borderColor: '#F0493E',
        width: '250px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F0493E',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F0493E',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F0493E',
        },
        '&:active .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F0493E',
        },
        '&:visited .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F0493E',
        },
    },
    inputLabel: {
        color: "#F0493E",
        "&.Mui-focused": {
            color: "#F0493E"
        }
    },
}));

export default useStyles;
