/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { expandCollapseStyles } from './styles';

export function ExpandCollapseWidget(props) {
  const defaultConfig = {
    edit: false,
    title: '',
    icon: null,
    subTitle: '',
    titleStyles: {},
    paperStyles: {},
    expandStatus: true,
    enableExpandCollapse: true,
    url: ''
  };
  const { config = {} } = props;
  const widConfig = { ...defaultConfig, ...config };
  const classes = expandCollapseStyles();
  const [expandStatus, setExpandStatus] = useState(false);

  const handleExpand = () => {
    setExpandStatus(!expandStatus);
  };

  const handleUrls = () => {
    window.open(widConfig.url);
  }

  return <Box sx={{ ...widConfig.paperStyles, cursor: 'pointer' }} component={Paper} className={classes.paper} onClick={handleUrls}>
    <Grid container className={classes.titleHeader}>
      <Grid container display='flex' direction="row" sx={{ fontSize: '0.9em', alignItems: 'center', ...widConfig.titleStyles }}>
        {widConfig.icon && <widConfig.icon className={classes.titleIcon} />}
        <Grid>
          <Typography className={classes.titleTypo}>{widConfig.title}</Typography>
          {widConfig.subTitle && <Typography className={classes.subTitle}>{widConfig.subTitle}</Typography>}
        </Grid>
        {/* {widConfig.enableExpandCollapse && <Grid className={classes.collapseSection}>
          {
            widConfig.CustomIcon ? <widConfig.CustomIcon />
              : expandStatus ? <>{widConfig.collapse}  <KeyboardArrowUpIcon onClick={handleExpand} className={classes.iconColor} /> </> :
                <>{widConfig.expand}<KeyboardArrowDownIcon onClick={handleExpand} className={classes.iconColor} /></>
          }
        </Grid>} */}
      </Grid>
    </Grid>
    {
      expandStatus && <Grid container className={classes.widgetContainer} >
        {props.children}
      </Grid>
    }
  </Box>;
}
