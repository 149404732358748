import enTranslation from '../locales/english.json';
import arTranslation from '../locales/arabic.json';

export const LOCALES = [
  { code: "en", language: "English" },
  { code: "ar", language: "عربي" }
];

export const RESOURCES = {
  en: {
    translation: enTranslation
  },
  ar: {
    translation: arTranslation
  }
};
