/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import { Button, Chip, Divider, FormControl, Grid, IconButton, InputBase, InputLabel, Paper, Select, Typography } from '@mui/material';
import React, { useContext } from 'react';
import useStyle from '../styles.js';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { CircularLoader } from '../../../shared/CircularLoader.js';
import apiservice from '../../../Helpers/apiservices.js';
import { useEffect } from 'react';
import '../styles.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TabsView } from './TabsView.js';
import Navbar from '../../../Components/navbar/index.js';
import useSnackBar from '../../../hooks/useSnackBar.js';
import { CommonDialog } from '../../../shared/CommonDialog.js';
import { Rating } from './rating.js';
import { DeleteDialog } from './DeleteDialog.js';
import { differenceInDays } from 'date-fns';
import { dateAfterTwoDays, getFormattedDateWithDay } from '../../../common-util.js';
import { I18nContext } from '../../../contexts/i18n.js';

export const Orders = () => {

  const classes = useStyle();
  const navigate = useNavigate();
  const { translate, locale } = useContext(I18nContext);
  const [loader, setLoader] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [reviewEdit, setReviewEdit] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [deliveryDays, setDeliveryDays] = useState(0);

  const handleDialog = () => {
    setDialogOpen(!dialogOpen);
  }

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  }

  useEffect(() => {
    getOrderDetails();
  }, [])

  const getOrderDetails = async () => {
    if (localStorage.getItem('authToken')) {
      setLoader(true)
      const res = await apiservice.getOrderDetails();
      if (res.success) {
        setOrderDetails(res?.data);
        setDeliveryDays(res?.deliveryTime);
        localStorage.setItem('deliveryTime', res?.deliveryTime || 0);
      } else {
        console.error(res.error);
        setOrderDetails([]);
      }
      setLoader(false);
    } else {
      navigate("/login", { state: { from: '/' } })
    }
  };

  const handleCancelOrder = async (id) => {
    setDeleteDialogOpen(true);
    setDeleteId(id);
  }

  const handleLoader = (load) => {
    setLoader(load);
  }

  const getDialogContent = () => {
    return <Rating isReviewEdit={reviewEdit} handleDialog={handleDialog} orderDetails={orderDetails[orderId]} handleLoader={handleLoader} getOrderDetails={getOrderDetails} />
  }

  const getDeleteDialogContent = () => {
    return <DeleteDialog handleDeleteDialog={handleDeleteDialog} deleteId={deleteId} getOrderDetails={getOrderDetails} />
  }

  const orderTracker = (orderStatus, item) => (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center align-content-center">
        <span className="d-flex justify-content-center align-items-center big-dot dot">
          <CheckCircleIcon sx={{ fill: item?.orderPlaceTime ? 'green' : 'grey' }} />
        </span>
        <hr className="flex-fill track-line" style={{ color: item?.orderPlaceTime ? 'green' : 'grey', opacity: 1, border: 'none' }} />
        {
          orderStatus !== 'CANCELLED' ?
            <>
              {!item?.addressType && <span className="d-flex justify-content-center align-items-center big-dot dot">
                <CheckCircleIcon sx={{ fill: (orderStatus === 'OUTFORDELIVERY' || orderStatus === 'COMPLETED') ? 'green' : 'grey' }} />
              </span>}
              <hr className="flex-fill track-line" style={{ color: (orderStatus === 'OUTFORDELIVERY' || orderStatus === 'COMPLETED') ? 'green' : 'grey', opacity: 1, border: 'none' }} />
              <span className="d-flex justify-content-center align-items-center big-dot dot">
                <CheckCircleIcon sx={{ fill: orderStatus === 'COMPLETED' ? 'green' : 'grey' }} />
              </span>
            </> : <>
              <span className="d-flex justify-content-center align-items-center big-dot dot">
                <CheckCircleIcon sx={{ fill: orderStatus === 'CANCELLED' ? 'green' : 'grey' }} />
              </span>
            </>
        }
      </div><div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-column align-items-start">
          <span style={{fontSize: '14px'}}>{translate("Order placed")}</span>
          <span style={{fontSize: '14px'}}>{item?.orderPlaceTime ? getFormattedDateWithDay(item?.orderPlaceTime): null}</span>
        </div>
        {
          orderStatus !== 'CANCELLED' ?
            <>
              {!item?.addressType && <div className="d-flex flex-column align-items-center">
                <span style={{fontSize: '14px'}}>{translate("Out for delivery")}</span>
                <span style={{fontSize: '14px'}}>{item?.outForDeliveryTime ? getFormattedDateWithDay(item?.outForDeliveryTime): null}</span>
              </div>}
              <div className="d-flex flex-column align-items-end">
                <span style={{fontSize: '14px'}}>{translate("Delivered")}</span>
                <span style={{fontSize: '14px'}}>{item?.deliveredTime ? getFormattedDateWithDay(item?.deliveredTime) : null}</span>
              </div>
            </> : <>
              <div className="d-flex flex-column align-items-end">
                <span style={{fontSize: '14px'}}>{translate("Cancelled")}</span>
                <span style={{fontSize: '14px'}}>{item?.cancelTime ? getFormattedDateWithDay(item?.cancelTime): null}</span>
              </div>
            </>
        }
      </div>
    </>
  )

  const handleDirections = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`http://maps.google.co.uk/maps?q=${item.latitude},${item.longitude}`)
  }

  const getButtons = (item, index) => {
    const { orderStatus, addressType, orderPlaceTime, outForDeliveryTime, deliveredTime, cartDetails, isReviewed, returnStatus } = item || {};
    const difference = differenceInDays(new Date(deliveredTime), new Date());
    if (orderStatus === 'CANCELLED') {
      return <Button className={classes.logOutBtn}>{translate("Cancelled")}</Button>
    } else {
      if (addressType == 1) {
        if (orderPlaceTime) {
          if (deliveredTime) {
            return <>
              {isReviewed ? <Button className={classes.addressBtn} style={{margin: '1em 1em 1em 0'}} onClick={() => { setOrderId(index); setDialogOpen(true); setReviewEdit(true); }}>{translate("EDIT REVIEW")}</Button> : <Button className={classes.addressBtn} style={{margin: '1em 1em 1em 0'}} onClick={() => { setDialogOpen(true); setOrderId(index); }}>{translate("WRITE REVIEW")}</Button>}
              {cartDetails?.[0]?.returnpolicy > difference ? <Button className={classes.logOutBtn} onClick={() => navigate(`/profile/order/return/${item.id}`)}>{translate("RETURN")}</Button> : null}
            </>
          } else {
            return <>
              <Button className={classes.logOutBtn} onClick={() => handleCancelOrder(item?.id)}>{translate("CANCEL")}</Button>
              <Button className={classes.logOutBtn} sx={{ml: '1em'}} onClick={(e) => handleDirections(e, item)}>{translate("GET DIRECTIONS")}</Button>
            </>
          }
        }
      } else {
        if (orderPlaceTime) {
          if (deliveredTime) {
            return <>
              {isReviewed ? <Button className={classes.addressBtn} style={{margin: '1em 1em 1em 0'}} onClick={() => { setOrderId(index); setDialogOpen(true); setReviewEdit(true); }}>{translate("EDIT REVIEW")}</Button> : <Button className={classes.addressBtn} style={{margin: '1em 1em 1em 0'}} onClick={() => { setDialogOpen(true); setOrderId(index); }}>{translate("WRITE REVIEW")}</Button>}
              {cartDetails?.[0]?.returnpolicy > difference ? <Button className={classes.logOutBtn} onClick={() => navigate(`/profile/order/return/${item.id}`)}>{translate("RETURN")}</Button> : null}
            </>
          } if (outForDeliveryTime) {
            return <Button className={classes.logOutBtn}>{translate("OUT FOR DELIVERY")}</Button>
          } if (returnStatus === 'INITIATED') {
            return <Button className={classes.logOutBtn}>{translate("RETURN INITIATED")}</Button>
          } if (returnStatus === 'COMPLETED') {
            return <Button className={classes.logOutBtn}>{translate("RETURN COMPLETED")}</Button>
          } if (orderStatus === 'COMPLETED') {
            return <>
              {isReviewed ? <Button className={classes.addressBtn} style={{margin: '1em 1em 1em 0'}} onClick={() => { setOrderId(index); setDialogOpen(true); setReviewEdit(true); }}>{translate("EDIT REVIEW")}</Button> : <Button className={classes.addressBtn} style={{margin: '1em 1em 1em 0'}} onClick={() => { setDialogOpen(true); setOrderId(index); }}>{translate("WRITE REVIEW")}</Button>}
              {cartDetails?.[0]?.returnpolicy > difference ? <Button className={classes.logOutBtn} onClick={() => navigate(`/profile/order/return/${item.id}`)}>{translate("RETURN")}</Button> : null}
            </>
          }
          else {
            return <Button className={classes.logOutBtn} onClick={() => handleCancelOrder(item?.id)}>{translate("CANCEL")}</Button>
          }
        } else if (!orderPlaceTime) {
          return <>
            <Button className={classes.logOutBtn}>{translate("ORDER PLACED")}</Button>
            <Button className={classes.logOutBtn} onClick={() => handleCancelOrder(item?.id)}>{translate("CANCEL")}</Button>
          </>
        }
      }
    }
  }


  return (
    <Grid container>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      <Grid container >
        {
          orderDetails?.length ? orderDetails.map((item, index) => {
            return <Paper key={`item-${index}`} className={classes.serviceDtlsPaper}>
              <Grid container sx={{ borderBottom: '1px dotted #777b7757', mb: '1em' }}>
                <Grid item >
                  <p style={{ fontWeight: 600 }}>{translate("Order Id")}: {item.orderId}</p>
                </Grid>
                <Grid item sx={{ m: locale.code === 'ar' ? '0 auto 0 0': '0 0 0 auto' }} >
                  {
                    item?.orderStatus !== 'CANCELLED' ? item?.orderStatus === 'COMPLETED' ?
                    <p style={{ fontWeight: 600, color: 'green' }}>{`${translate("Delivered on")} ${getFormattedDateWithDay(item.deliveredTime)}`}</p>
                     : <p style={{ fontWeight: 600, color: 'green' }}>{`${translate("Delivery By")} ${dateAfterTwoDays(item.orderPlaceTime, deliveryDays)}`}</p>
                      : <p style={{ fontWeight: 600, color: 'grey' }}>{`${translate("Cancelled on")} ${getFormattedDateWithDay(item.cancelTime)}`}</p>
                  }
                </Grid>
              </Grid>
              <Grid container sx={{ borderBottom: '1px dotted #777b7757', mb: '1em', pb: '1em' }}>
                <Grid item >
                  <img src={item?.cartDetails?.[0]?.image?.[0]?.url} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/productDetails/${item?.order?.productId}`)
                  }} style={{ height: '160px', width: '140px' }} />
                </Grid>
                <Grid item sx={{ m: '0 2em' }}>
                  <p className={classes.productBrandName} style={{ marginTop: 0 }}>{item?.cartDetails?.[0]?.brandName}</p>
                  <p className={classes.productName}>{item?.order?.productName}</p>
                  <div className={classes.prices}>
                    <Chip className={classes.quanchip} label={`${translate("Quantity")}: ${item?.cartDetails?.[0]?.quantity}`} />
                    {
                      item?.cartDetails?.[0]?.attributes?.length ? item?.cartDetails?.[0]?.attributes?.map((val, ind) => {
                        if (val?.type === 'text') {
                          return <Chip className={classes.quanchip} label={`${val?.title}: ${val?.value}`} />
                        } return null
                      }) : null
                    }
                  </div>
                </Grid>
                {
                  item?.orderStatus !== 'CANCELLED' &&
                  <Grid item sx={{  m: locale.code === 'ar' ? '0 auto 0 0': '0 0 0 auto', background: '#b7acac3b', p: '2em', width: '35%' }}>
                    {orderTracker(item?.orderStatus, item)}
                  </Grid>
                }
              </Grid>
              <Grid container>
                <Grid item>
                  {getButtons(item, index)}
                </Grid>
                {
                  item?.orderStatus !== 'CANCELLED' &&
                  <Grid item sx={{ m: locale.code === 'ar' ? '0 auto 0 0': '0 0 0 auto' }} >
                    <p style={{ fontWeight: 600 }} onClick={() => navigate(`/profile/order/orderDetails/${item.id}`)}>{translate('More Details')}<ArrowForwardIosIcon sx={{ fontSize: '1em', ml: '1em' }} /></p>
                  </Grid>
                }
              </Grid>
            </Paper>
          })
            : <Paper sx={{ margin: '2em', padding: '5em', width: '100%', height: '400px', display: 'flex' }}>
              <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {translate("You have not created any Orders.")}
              </Grid>
            </Paper>
        }
      </Grid>
      <CommonDialog options={{ maxWidth: 'sm', dialogOpen: dialogOpen }} onDialogClose={handleDialog} >
        {dialogOpen && getDialogContent()}
      </CommonDialog>
      <CommonDialog options={{ maxWidth: 'sm', dialogOpen: deleteDialogOpen }} onDialogClose={handleDeleteDialog} >
        {deleteDialogOpen && getDeleteDialogContent()}
      </CommonDialog>
    </Grid>
  )
}
