/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Autocomplete, debounce, Grid, InputBase, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { GlobalSearchStyles } from '../globalSearchSyles';
import apiservice from '../../../Helpers/apiservices';
import { I18nContext } from '../../../contexts/i18n';

const GlobalSearch = (props) => {
  const classes = GlobalSearchStyles();
  const { translate, locale } = useContext(I18nContext);
  const [autocompleteList, setAutocompleteList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const getAutocompleteResponse = async (term) => {
    const payload = {
      searchValue: term
    };
    const res = await apiservice.getFilteredData(payload);
    if (res.success) {
      setAutocompleteList([...res.data])
    } else {
      setAutocompleteList([])
      console.error(res.error);
    }
  };

  useEffect(() => {
    if (window?.location?.hash !== "#/productList") {
      localStorage.removeItem('searchVal')
    }
  }, [window.location])

  const debounceHandler = useCallback(debounce(
    (reqData) => { getAutocompleteResponse(reqData); },
    500),
    []);

  const handleInputChange = (e, value) => {
    localStorage.setItem('searchVal', value)
    setSearchValue(value);
    if (value && value.length >= 1) {
      debounceHandler(value);
    } else if (autocompleteList.length > 0) {
      setAutocompleteList([]);
    }
  };

  const getOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    return `${option.name ? option.name : ''}`;
  };

  const handleNavigateList = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const trimmedVal = locale.code === 'ar' ? item?.arName?.trim() : item?.enName?.trim();
    localStorage.setItem('searchVal', trimmedVal);
    setSearchValue(trimmedVal);
    if (trimmedVal) {
      if (window?.location?.hash === "#/productList") {
        navigate(0);
      }
      navigate(`/productList`, { state: { type: 'categoryId', id: item?.categoryId } });
    }
  }

  const renderOption = (optionProps, option) => {
    return <Grid container className={classes.optContainer} {...optionProps} onClick={(e) => handleNavigateList(e, option)}>
      <Grid item xs={12} sx={{ display: 'flex' }}>
        <Grid item>
          {
            locale.code === 'ar' ? <Typography className={classes.nameOption}>{option.arName}</Typography>
            : <Typography className={classes.nameOption}>{option.enName}</Typography>
          }
        </Grid>
      </Grid>
    </Grid>;
  };

  const handleOptionSelection = (e, option) => {
    localStorage.setItem('searchVal', option?.trim());
    if (option?.trim()) {
      if (autocompleteList?.length) {
        if (window?.location?.hash === "#/productList") {
          navigate(0);
        }
        navigate(`/productList`, { state: { type: 'categoryId', id: autocompleteList?.[0]?.categoryId } });
      } else {
        if (window?.location?.hash === "#/productList") {
          navigate(0);
        }
        navigate(`/productList`);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      handleOptionSelection(e, e?.target?.value);
    }
  };

  const handleSearchIconClick = (e) => {
    handleOptionSelection(e, searchValue);
  };

  return (
    <Autocomplete
      freeSolo
      fullWidth
      className={classes.clearIndicator}
      classes={{
        paper: classes.searchPaper
      }}
      value={localStorage.getItem('searchVal') || ''}
      options={autocompleteList}
      disableClearable
      onInputChange={(event, value) => handleInputChange(event, value)}
      onChange={(opt, values, data) => handleOptionSelection(opt, data)}
      getOptionLabel={(option) => getOptionLabel(option)}
      renderOption={(optionProps, option) => renderOption(optionProps, option)}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params;
        return <div className={classes.searchFieldSection}>
          <InputBase className={classes.searchField}
            sx={{ margin: '0 0.25em 0 0.4em', fontSize: '0.8em' }}
            placeholder={translate('Search')}
            onKeyDown={(e) => handleKeyDown(e)}
            {...params.InputProps} {...rest} />
          <div className={classes.iconContainer}>
            <SearchIcon className={classes.icon} onClick={handleSearchIconClick} />
          </div>
        </div>;
      }} />
  );
};

export default GlobalSearch;
