
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bannerImg: {
        width: '100%',
        margin: '2em 4em 1em 4em',
        height: "500px"
    },
    centerContainer: {
        width: '90%',
        margin: '0px auto',
        marginTop: '35px'
    },
    whyAwall: {

    },
    awallHeader: {
        textAlign: 'left',
        color: '#000000',
        fontWeight: 600
    },
    awalImg: {
        width: '100%',
        margin: '1em',
        height: '100%'
    },
    popularHeader: {
        textAlign: 'left',
        color: '#000000',
        margin: '1em 0',
        fontWeight: 600
    },
    brandImg: {
        height: '180px',
    },
    card: {
        // width: '460px',
        backgroundColor: '#FFFFFF',
        borderRadius: '9px',
        padding: '23px',
        width: '95%',
        //margin: '0 10px',
        border: '1px solid #e8e8e8',
        '&:hover': {
            boxShadow: '-4px 7px 19px #efefef',
            border: '1px solid #bbb',
        }
    },
    topCard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    heartImg: {
        width: '38px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease 0s;',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    ratingBtn: {
        backgroundColor: '#FBFBFB',
        border: 'none',
        boxShadow: '-1px 3px 9px #00000029',
        borderRadius: '5px',
        width: '57px',
        fontSize: '12px',
        textAlign: 'start',
        padding: '0px 8px',
        height: '32px',
    },
    star: {
        width: '15px',
        float: 'right'
    },
    cardImg: {
        width: '60%',
        marginTop: '27px',
        height: '105px',
        objectFit: 'contain'
    },
    productBrandName: {
        color: '#000000',
        fontSize: '11px',
        marginTop: '31px',
        textAlign: 'left',
        marginBottom: '5px'
    },
    productName: {
        color: '#000000',
        marginBottom: '3px',
        textAlign: 'left',
        fontSize: '17px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    oldPrice: {
        marginRight: '11px',
        textDecoration: 'line-through'
    },
    originalPrice: {
        color: '#F0493E',
    },
    stock: {
        color: '#16861F',
        textAlign: 'left',
    },
    outOfStock: {
        color: 'red',
        textAlign: 'left',
    },
    prices: {
        display: 'flex',
    },
    productSection: {
        width: '100%',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
    },
    insideProduct: {
        display: 'flex'
    },
    arrowButton: {
        margin: 'auto',
        height: '45px'
    },
    arrowIcon: {
        padding: '10px',
        borderRadius: '20px',
        background: '#afaeae',
        color: '#fff',
        fontSize: '3em'
    }
}));

export default useStyles;
