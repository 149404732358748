/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Images from '../../Helpers/images';
import useStyle from './style.js'
import './style.css';
import useSnackBar from '../../hooks/useSnackBar';
import GlobalSearch from '../../Pages/home/components/globalSearch';
import { AuthContext } from '../../contexts/AuthContext';
import { Badge } from '@mui/material';
import { useContext } from 'react';
import { LOCALES } from "../../../src/enums/i18n";
import { I18nContext } from '../../../src/contexts/i18n';

const Navbar = () => {

    const classes = useStyle();
    const { showSnack } = useSnackBar();
    const navigate = useNavigate();
    const authContext = React.useContext(AuthContext);

    const { locale, changeLocale } = useContext(I18nContext);
    const [openSearch, setOpenSearch] = useState(false);
    const { translate } = useContext(I18nContext);

    const handleSearch = ( ) => {
        setOpenSearch(true);
    }

    const handleOnChange = (evt) => {
        changeLocale(evt.target.value);
        localStorage.setItem('lang', evt.target.value);
        window.location.reload(true);
    };

    return (
        <div className="container-fluid borders">
            <div className="row p-3">
                <div className="col-5 col-md-3">
                    <div className="row">
                        <div className="col-4 col-md-1" >
                            <Link to="/" >
                                <img src={Images.logo} className={classes.logo} />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-5 col-md-4" style={{ margin: '0 3em 0 -3em' }}>
                    {
                        (window?.location?.hash === "#/" || window?.location?.hash === "#/productList" || !window?.location?.hash || window?.location?.hash === "/#/") && openSearch &&
                        <GlobalSearch />
                    }
                </div>
                <div className="col-2 col-md-5">
                    <nav className="navbar navbar-expand-lg p-0">
                        <div className="container-fluid justify-content-end">

                            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse flex-grow-0" id="navbarCollapse">
                                <div className="navbar-nav">
                               {!openSearch && (window?.location?.hash === "#/" || window?.location?.hash === "#/productList" || !window?.location?.hash || window?.location?.hash === "/#/") && <span className="nav-link" style={{cursor: "pointer"}} onClick={handleSearch}>{translate("Search")}</span>}
                                     
                                     {
                                        // eslint-disable-next-line eqeqeq
                                        localStorage.getItem('cartCount') != 0 ? 
                                        <Badge badgeContent={localStorage.getItem('cartCount')} color="primary" sx={{
                                        '& .MuiBadge-badge': {
                                            background: '#f0493e !important'
                                        }
                                    }}>
                                        <Link to="/cart" className="nav-item nav-link" style={{ paddingRight: '0px !important' }}>{translate("Cart")}</Link>
                                    </Badge>
                                    :  <Link to="/cart" className="nav-item nav-link" style={{ paddingRight: '0px !important' }}>{translate("Cart")}</Link>
                                    }
                                    {
                                        localStorage.getItem('authToken') ? <div className="nav-item dropdown">
                                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{translate("Profile")}</a>
                                            <div className="dropdown-menu">
                                                <Link to="/profile/updateProfile" className="dropdown-item">{translate("Profile")}</Link>
                                                <Link to="/profile/bookings" className="dropdown-item">{translate("My Bookings")}</Link>
                                                <Link to="/profile/order" className="dropdown-item">{translate("My Orders")}</Link>
                                                <Link to="/profile/address" className="dropdown-item">{translate("Manage Addresses")}</Link>
                                                <Link to="/profile/wishlist" className="dropdown-item">{translate("My Wishlist")}</Link>
                                                <Link to="/profile/coupon" className="dropdown-item">{translate("Coupon")}</Link>
                                                <Link to="/profile/settings" className="dropdown-item">{translate("Settings")}</Link>
                                                <Link to="/profile/helpSupport" className="dropdown-item">{translate("Help & Suport")}</Link>
                                                <Link to="/" className="dropdown-item logout" onClick={() => {
                                                    authContext.logout();
                                                }}>{translate("LOGOUT")}</Link>
                                            </div>
                                        </div>
                                            : <Link to="/login" className="dropdown-item">{translate("LOGIN")}</Link>
                                    }
                                    <select value={locale.code} onChange={handleOnChange} style={{ cursor: 'pointer', margin: '0 1em' }}>
                                        {LOCALES.map((l) => (
                                            <option key={l.code} value={l.code}>
                                                {l.language}
                                            </option>
                                        ))}
                                    </select>
                                    {
                                        localStorage.getItem('authToken') ?
                                            <Link to="/technician">
                                                <button className="nav-item nav-link tech-btn">{translate("Technician")}</button>
                                            </Link>
                                            : <button className="nav-item nav-link tech-btn" onClick={() => {
                                                navigate("/login", { state: { from: '/' } });
                                                showSnack({ open: true, type: 'error', message: <>{translate("Please login to add services")}</> });
                                            }}>{translate("Technician")}</button>
                                    }
                                    {/* <div className="nav-item dropdown">
                                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Messages</a>
                                        <div className="dropdown-menu">
                                            <a href="#" className="dropdown-item">Inbox</a>
                                            <a href="#" className="dropdown-item">Sent</a>
                                            <a href="#" className="dropdown-item">Drafts</a>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )

}

export default Navbar;