/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import { Button, Chip, Divider, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import useStyle from '../styles.js';
import { useState } from 'react';
import { CircularLoader } from '../../../shared/CircularLoader.js';
import apiservice from '../../../Helpers/apiservices.js';
import { useEffect } from 'react';
import '../styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import useSnackBar from '../../../hooks/useSnackBar.js';
import { I18nContext } from '../../../contexts/i18n.js';

export const OrderReturn = () => {
  const { id } = useParams();
  const classes = useStyle();
  const navigate = useNavigate();
  const { translate } = useContext(I18nContext);
  const { showSnack } = useSnackBar();
  const [loader, setLoader] = useState(false);
  const [notes, setNotes] = useState('');
  const [returnValue, setReturnValue] = useState(1);
  const [orderDetails, setOrderDetails] = useState([]);
  const [returnReasons, setReturnReasons] = useState([]);

  useEffect(() => {
    getOrderDetails();
    getReturnQues();
  }, [])

  const getOrderDetails = async () => {
    setLoader(true)
    const res = await apiservice.getOrderById(id);
    if (res.success) {
      let data = res?.data;
      setOrderDetails(data);
    } else {
      console.error(res.error);
      setOrderDetails([]);
    }
    setLoader(false);
  };

  const getReturnQues = async () => {
    setLoader(true)
    const res = await apiservice.getReturnReasons();
    if (res.success) {
      let data = res?.data;
      setReturnReasons(data);
    } else {
      console.error(res.error);
      setReturnReasons([]);
    }
    setLoader(false);
  };

  const confirmReturn = async () => {
    setLoader(true);
    const payload = {
      "productId": orderDetails?.[0]?.productId,
      "orderId": orderDetails?.[0]?.id,
      "commands": notes,
      "returnReasonId": 1
    }
    const res = await apiservice.confirmReturn(payload);
    if (res.success) {
      let data = res?.data;
      navigate('/profile/order');
      showSnack({ open: true, type: 'error', message: <>{translate("Return Request placed successfully")}</>  });
    } else {
      console.error(res.error);
      showSnack({ open: true, type: 'error', message: <>{translate("Something went wrong")}</> });
    }
    setLoader(false);
  };


  return (
    <Grid container>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      {
        orderDetails?.length && orderDetails.map((item, index) => {
          return <Paper key={`item-${index}`} className={classes.serviceDtlsPaper}>
            <Grid container >
              {/* <Grid item sx={{ ml: 'auto' }} >
                <p style={{ fontWeight: 600, color: '#40af40' }}>{'Delivered on 30,Apr'}</p>
              </Grid> */}
            </Grid>
            <Grid container sx={{ mb: '1em', background: '#f5f6f8', padding: '2em' }}>
              <Grid item >
                <img src={item?.cartDetails?.[0]?.image?.[0]?.url} style={{ height: '160px', width: '140px' }} />
              </Grid>
              <Grid item sx={{ m: '0 2em' }}>
                <p className={classes.productBrandName} style={{ marginTop: 0 }}>{item?.order?.brandName}</p>
                <p className={classes.productName}>{item?.order?.productName}</p>
                <div className={classes.prices}>
                  <Chip className={classes.quanchip} label={`Quantity: ${item?.cartDetails?.[0]?.quantity}`} />
                </div>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item sx={{ marginTop: '1em' }} >
                <p style={{ fontWeight: 700, fontSize: '16px' }}>Select Reason</p>
              </Grid>
              <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.5em' }}>
                <FormControl className={classes.summaryPaper}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={returnValue}
                onChange={(e) => {
                  setReturnValue(e.target.value);
                }}
                  >
                    {
                      returnReasons?.length ? returnReasons?.map((pay, index) => {
                        return <FormControlLabel key={`pay-${index}`} sx={{ display: 'flex' }} value={pay.id} control={<Radio />} label={pay.enQuestion} />
                      }) : null
                    }
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item sx={{ marginTop: '1em' }} >
                <p style={{ fontWeight: 700, fontSize: '16px' }}>Comments</p>
              </Grid>
              <Grid container sx={{ background: '#f5f6f8', padding: '0.5em' }}>
                <TextField
                  id="standard-multiline-static"
                  multiline
                  autoFocus
                  rows={4}
                  value={notes}
                  onChange={(event) => {
                    setNotes(event.target.value);
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    '& .MuiInputBase-root.MuiInput-root:before': {
                      borderBottom: 'none'
                    },
                    '& .MuiInputBase-root.MuiInput-root:after': {
                      borderBottom: 'none'
                    },
                    '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                      borderBottom: 'none'
                    },
                    '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):after': {
                      borderBottom: 'none'
                    },
                    '& .MuiInputBase-root.MuiInput-root': {
                      marginTop: 0
                    },
                  }}
                  variant={"standard"}
                />
              </Grid>
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} sx={{ mt: '1em' }}>
              <Button className={classes.addressBtn} >{`Refund Amount ${translate("SAR")} ${item?.order?.total}`}</Button>
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} sx={{ mt: '1em' }}>
              <Button className={classes.logOutBtn} onClick={confirmReturn}>{`CONFIRM RETURN`}</Button>
            </Grid>
          </Paper>
        })
      }
    </Grid>
  )
}
