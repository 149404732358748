/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UpdateProfile, Orders, Addresses, Wallet, Wishlist, Coupon, Settings, HelpSupport } from '../components';
import { Bookings } from './bookings';

export const TabsView = ({ selectedTab, setTab }) => {
    const { tabValue } = useParams();

    useEffect(() => {
        setTab(tabValue);
    }, [tabValue]);

    const renderTabs = () => {
        switch (tabValue) {
            case 'updateProfile':
                return <UpdateProfile />;
            case 'bookings':
                return <Bookings />;
            case 'order':
                return <Orders />;
            case 'address':
                return <Addresses />;
            case 'wallet':
                return <Wallet />;
            case 'wishlist':
                return <Wishlist />;
            case 'coupon':
                return <Coupon />;
            case 'settings':
                return <Settings />;
            case 'helpSupport':
                return <HelpSupport />;
            default:
                return null;
        }
    };

    return <>
        {renderTabs()}
    </>
}