import { useState } from 'react';
import './App.css';
import Router from './Routes/router';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LOCALES, RESOURCES } from './enums/i18n';
import { I18nContext } from './contexts/i18n';

function App() {
  const findLang = localStorage?.getItem('lang')==='ar' ? LOCALES[1] : LOCALES[0];
  const [locale, setLocale] = useState( findLang || LOCALES[0]);

  const changeLocale = (code) => {
    setLocale(LOCALES.find((l) => l.code === code) || LOCALES[0]);
  };

  const translate = (text) => {
    return RESOURCES[locale.code].translation[text];
  };

  return (
    <I18nContext.Provider
    value={{
      locale,
      changeLocale,
      translate
    }}
  >
    <div className="App">
      <Router />
    </div>
    </I18nContext.Provider>
  );
}

export default App;
