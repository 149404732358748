/* eslint-disable no-unused-vars */
import { Box, Grid, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { HEADER_TITLES, PROFILE_MENUS } from './constants';
import useStyles from './styles';
import { ProfileHeader } from './components';
import { Link, Route, Routes } from 'react-router-dom';
import { TabsView } from './components/TabsView';
import { useEffect } from 'react';
import { OrderDetails } from './components/orderDetails';
import { BookingDetails } from './components/bookingDetails';
import { OrderReturn } from './components/orderReturn';
import { useContext } from 'react';
import { I18nContext } from '../../contexts/i18n';

export const TabSection = () => {
    const classes = useStyles();
    const { translate } = useContext(I18nContext);
    const [tabVal, setTabVal] = useState();
    const [selectedTab, setSelectedTab] = useState(tabVal || PROFILE_MENUS?.[0]?.value);

    useEffect(() => {
        setSelectedTab(tabVal ? tabVal : PROFILE_MENUS?.[0]?.value);
    }, [tabVal]);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const setTab = (val) => {
        setTabVal(val);
    }

    return <>
        <Box style={{ display: 'flex', paddingBottom: '0.5em' }}>
            <Grid item sx={{m: '0 1em'}}>
                <Tabs orientation="vertical"
                    className={classes.tabsRoot}
                    TabIndicatorProps={{
                        style: {
                            display: 'none'
                        }
                    }}
                    value={selectedTab}
                    onChange={handleChangeTab}>
                    {
                        PROFILE_MENUS.map((type, index) =>
                            <Tab
                                key={index} onClick={() => setSelectedTab(type.value)}
                                component={Link}
                                to={`${type.value}`}
                                icon={<type.icon className={selectedTab === type.value ? classes.activeIcon : classes.inActiveIcon} />}
                                iconPosition="start" value={type.value} 
                                label={translate(type.label)}
                                className={selectedTab === type.value ? classes.activeTab : classes.inActiveTab} />
                        )}
                </Tabs>
            </Grid>
            <Grid item style={{ padding: '1em', flex: '1' }}>
                <Routes>
                    <Route path={':tabValue/*'} element={<TabsView selectedTab={selectedTab} setTab={setTab} />} />
                    <Route path={'order/orderDetails/:id/*'} element={<OrderDetails />} />
                    <Route path={'order/return/:id/*'} element={<OrderReturn />} />
                    <Route path={'bookings/bookingDetails/:id/*'} element={<BookingDetails />} />
                </Routes>
            </Grid>
        </Box>
    </>
}