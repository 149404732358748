import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import useStyles from './styles';
import Images from '../../Helpers/images';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './style.css';
import apiservice from '../../Helpers/apiservices';
import { useLocation, useNavigate } from 'react-router-dom';
import useSnackBar from '../../hooks/useSnackBar';
import { useState } from 'react';
import { CircularLoader } from '../../shared/CircularLoader';
import { useContext } from 'react';
import { I18nContext } from '../../contexts/i18n';

export default function Login() {
    const classes = useStyles();
    const navigate = useNavigate()
    const { showSnack } = useSnackBar();
    const [loading, setLoading] = useState(false);
    const { state= {} } = useLocation() || {};
    const { from = '/' } = state || {};
    const { translate } = useContext(I18nContext);

    const skipLogin = () => {
        navigate(from);
    }
   
    const [phone, setPhoneNumber] = React.useState({
        countryCode: null,
        mobileNumber: null
    })

    const handleOnChange = (data, country) => {
        setPhoneNumber({ mobileNumber: data, countryCode: `+${country.dialCode}` });
    }

    const handleSubmit = async () => {
        setLoading(true)
        const payload = {
            mobileNumber: phone.mobileNumber.slice(phone.countryCode.length - 1),
            countryCode: phone.countryCode
        }
        const postRes = await apiservice.sendOtp(payload);
        if (postRes?.success) {
            showSnack({ open: true, type: 'success', message: postRes.massage });
            navigate('/login/verifyOtp', { state: { phone: { ...payload }, from: from } })
        } else {
            showSnack({ open: true, type: 'error', message: <>{translate("Something went wrong")}</> });
        }
        setLoading(false)
    }


    return <>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <img alt='profile' src={Images.profile} />

                <Typography component="h1" variant="h5" sx={{ mt: 1, fontWeight: 600 }}>
                    {translate("Account")}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ marginTop: '2em' }}>
                        <PhoneInput
                            isValid={(value, country) => {
                                if (value.match(/12345/)) {
                                    return 'Invalid value: ' + value + ', ' + country.name;
                                } else if (value.match(/1234/)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }}
                            country={'sa'}
                            value={phone.mobileNumber}
                            onChange={(phone, country) => handleOnChange(phone, country)}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    disabled={!phone.mobileNumber}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    className={classes.loginBtn}
                    onClick={handleSubmit}
                >
                    {translate("LOGIN")}{loading.sendCode && <CircularLoader loaderProps={{ sx: { marginLeft: '1em' } }} />}
                </Button>
                <Button onClick={skipLogin} >{translate("Skip login")}</Button>
                <Grid item xs={12} sx={{ margin: '1em' }}>
                    <Typography className={classes.acceptanceTypo}>{translate("By Clicking , I accept the")} <span className={classes.acceptanceSpan}>{translate("Terms & Condion & Privacy Policy")}</span></Typography>
                </Grid>
            </Box>

        </Container>
    </>
}