/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Chip, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import useStyles from "../styles";
import CloseIcon from '@mui/icons-material/Close';
import ReactStars from "react-rating-stars-component";
import { useState } from "react";
import apiservice from "../../../Helpers/apiservices";
import useSnackBar from "../../../hooks/useSnackBar";
import { I18nContext } from "../../../contexts/i18n";

export const Rating = (props) => {
  const classes = useStyles();
  const { showSnack } = useSnackBar();
  const { translate } = useContext(I18nContext);
  const { orderDetails, handleDialog, handleLoader, getOrderDetails = () => { }, isReviewEdit = false } = props || {};
  const [starRating, setStarRating] = useState(1);
  const [notes, setNotes] = useState('');
  const [reviewId, setReviewId]= useState('');

  useEffect(() => {
    getReview();
  }, [isReviewEdit])

  const ratingChanged = (newRating) => {
    setStarRating(newRating);
  };

  const getReview = async () => {
    const payload = {
      "productId": orderDetails?.order?.productId,
      "orderId": orderDetails?.id,
    }
    handleLoader(true)
    const res = await apiservice.getReview(payload);
    if (res.success) {
      let data = res?.data?.[0];
      setReviewId(data?.id)
      setStarRating(data?.rating);
      setNotes(data?.review);
    } else {
      console.error(res.error);
    }
    handleLoader(false);
  };

  const submitReview = async () => {
    handleLoader(true);
    const payload = {
      "productId": orderDetails?.order?.productId,
      "orderId": orderDetails?.id,
      "rating": starRating,
      "review": notes
    }
    const updateReviewPayload={
      "rating": starRating,
      "review": notes
    }
    const res = isReviewEdit ? await apiservice.updateReview(updateReviewPayload, reviewId) : await apiservice.submitReview(payload);
    if (res.success) {
      handleDialog();
      showSnack({ open: true, type: 'success', message: res.massage });
      getOrderDetails();
    } else {
      console.error(res.error);
      showSnack({ open: true, type: 'error', message: <>{translate("Something went wrong")}</> });
    }
    handleLoader(false);
  };

  return (
    <Grid container>
      <Grid container justifyContent={'flex-end'}>
        <CloseIcon onClick={handleDialog} sx={{ cursor: 'pointer' }} />
      </Grid>
      <Grid container sx={{ background: '#ece9e9', padding: '1em' }}>
        <Grid item >
          <img alt='orderImg' src={orderDetails?.cartDetails?.[0]?.image?.[0]?.url} style={{ height: '160px', width: '140px' }} />
        </Grid>
        <Grid item sx={{ m: '0 2em', width: '50%' }}>
          <p className={classes.productBrandName} style={{ marginTop: 0 }}>{orderDetails?.order?.brandName}</p>
          <p className={classes.productName}>{orderDetails?.order?.productName}</p>
          <div className={classes.prices}>
            <Chip className={classes.quanchip} label={`${translate("Quantity")}: ${orderDetails?.cartDetails?.[0]?.quantity}`} />
          </div>
        </Grid>
      </Grid>
      <Grid container sx={{ m: '1em' }}>
        <Grid item display={'flex'} alignItems={'center'}>
          <Typography sx={{ fontWeight: 600 }}>{translate("Ratings")}</Typography>
        </Grid>
        <Grid item sx={{ ml: 'auto' }}>
          <ReactStars
            count={5}
            value={starRating}
            onChange={ratingChanged}
            size={24}
            activeColor="#ffd700"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ m: '1em' }}>
        <Typography sx={{ fontWeight: 600 }}>{translate("Write your review")}</Typography>
      </Grid>
      <Grid container sx={{ background: '#f5f6f8', padding: '0.5em' }}>
        <TextField
          id="standard-multiline-static"
          multiline
          autoFocus
          rows={4}
          value={notes}
          onChange={(event) => {
            setNotes(event.target.value);
          }}
          InputLabelProps={{
            className: classes.inputLabel
          }}
          sx={{
            display: 'flex',
            width: '100%',
            '& .MuiInputBase-root.MuiInput-root:before': {
              borderBottom: 'none'
            },
            '& .MuiInputBase-root.MuiInput-root:after': {
              borderBottom: 'none'
            },
            '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: 'none'
            },
            '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):after': {
              borderBottom: 'none'
            },
            '& .MuiInputBase-root.MuiInput-root': {
              marginTop: 0
            },
          }}
          variant={"standard"}
        />
      </Grid>
      <Grid container>
        <Button className={classes.logOutBtn} onClick={submitReview} sx={{ width: '100%' }}>{translate('SUBMIT')}</Button>
      </Grid>
    </Grid>
  )
}