/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from '../../Components/footer';
import Navbar from '../../Components/navbar';
import Products from '../../Components/products.js';
import Images from '../../Helpers/images';
import useStyle from './style.js';
import './style.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import apiservice from '../../Helpers/apiservices';
import { CircularLoader } from '../../shared/CircularLoader';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import useSnackBar from '../../hooks/useSnackBar';
import { Reviews } from './components/reviews';
import OtherProducts from '../home/components/otherProducts';
import { Highlights } from './components/Highlights';
import { I18nContext } from '../../contexts/i18n';
import { dateAfterTwoDays } from '../../common-util';

const ProductDetails = () => {
    const { id } = useParams();

    const classes = useStyle();
    const { showSnack } = useSnackBar();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { translate } = useContext(I18nContext);
    const [expanded, setExpanded] = React.useState(false);
    const [loader, setLoader] = useState(true)
    const [productDetails, setProductDetails] = useState({});
    const [reviews, setReviews] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const { brandName, name, isInstallment, mainVariant, highlights, modelNumber, subVariant, originalPrice, price, isStock, specification, productOverview, returnPolicy, image, warrenty, isFavorites = 0 } = productDetails || {};

    useEffect(() => {
        getProductDetails();
        AddRecentViewedProduct();
    }, [])

    const AddRecentViewedProduct = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const payload = {
                "userId": user?.id,
                "productId": id
            }
            const res = await apiservice.addRecentViewedProduct(payload);
            if (res.success) {
                console.info(res.success);
            } else {
                console.error(res.error);
            }
        }
    };

    const getProductDetails = async () => {
        //setLoader(true)
        const res = await apiservice.getProductDetails(id);
        if (res.success) {
            const subVariants = res?.data?.[0]?.subVariant?.filter(subVar => subVar?.productId == id)
            setProductDetails({ ...res?.data?.[0], subVariant: subVariants });
            setReviews([...res.review]);
            setRelatedProducts([...res.result])
            window.scrollTo(0, 0);
        } else {
            console.error(res.error);
            setProductDetails({});
            setReviews([]);
            setRelatedProducts([]);
            window.scrollTo(0, 0);
        }
        setLoader(false);
    };

    const images = () => {
        let imagesArr = [];
        image?.length && image?.forEach((image, index) => {
            if (image.type === 'image') {
                imagesArr.push({
                    original: image.url,
                    thumbnail: image.url,
                })
            } else {
                imagesArr.push({
                    original: image.url,
                    thumbnail: image.thump_url,
                    embedUrl: 'https://www.youtube.com/embed/VIDEO_ID',
                    renderItem: () => {
                        return (
                            <div className="video-wrapper">
                                <iframe
                                    title='video'
                                    src={image.url}
                                    allowFullScreen
                                />
                            </div>
                        );
                    }
                })
            }
        })
        return imagesArr;
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleWishlist = async (favoStatus) => {
        if (localStorage.getItem('authToken')) {
            const data = {
                "productId": id,
                "isFavourites": favoStatus,
            }
            const res = await apiservice.addToFavorites(data);
            if (res.success) {
                showSnack({ open: true, type: 'success', message: <>{translate("Added to Wishlist successfully")}</> });
            } else {
                showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
            }
            getProductDetails();
        } else {
            navigate("/login", { state: { from: pathname } });
            showSnack({ open: true, type: 'error', message: <>{translate("Please login to add the products in wishlist")}</> });
        }
    }

    const quantities = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
    ]

    const handleAddToCart = async () => {
        if (localStorage.getItem('authToken')) {
            setLoader(true);
            const data = {
                "productId": id,
                "quantity": quantity,
                "price": price,
                originalPrice: originalPrice ? originalPrice : 0,
            }
            const res = await apiservice.addToCart(data);
            if (res.success) {
                showSnack({ open: true, type: 'success', message: <>{translate("Product is added to your cart")}</> });
                navigate('/cart');
            } else {
                showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
            }
            setLoader(false);
            getProductDetails();
        } else {
            navigate("/login", { state: { from: pathname } });
            showSnack({ open: true, type: 'error', message: <>{translate("Please login to add the products in cart")}</> });
        }
    }

    const variantOnClick = (e, attribute) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/productDetails/${attribute?.productId}`)
        navigate(0);
    }

    const renderVariants = (attribute, index) => {
        return <> {
            attribute?.type === 'image' ? <div className="productCapacity" >
                {
                    id == attribute?.productId ? <img src={attribute?.url?.[0]} className="capacity activeCapacity imagehover" onClick={(e) => variantOnClick(e, attribute)} />
                        : <img src={attribute?.url?.[0]} className="capacity imagehover" onClick={(e) => variantOnClick(e, attribute)} />
                }
            </div>
                : <div className="productCapacity" >
                    {
                        id == attribute?.productId ? <div className="capacity activeCapacity" style={{ width: 'auto', height: '40px' }} onClick={(e) => variantOnClick(e, attribute)}>{attribute?.value}</div>
                            : <div className="capacity" style={{ width: 'auto', height: '40px' }} onClick={(e) => variantOnClick(e, attribute)} >{attribute?.value}</div>
                    }
                </div>
        }

        </>
    }

    return (
        <>
            <Navbar />
            {loader && <Grid><CircularLoader overlay={true} /></Grid>}
            <Products />

            <div className={classes.wholeContainer}>
                <div className="row">
                    <div className="col-md-6">
                        <ImageGallery items={images()}
                            disableSwipe={true}
                            showFullscreenButton={false}
                            //  thumbnailPosition='left'
                            showPlayButton={false}
                            lazyLoad={true}
                            showNav={false}
                        />
                    </div>
                    <div className="col-md-5">

                        <div className="product-details">
                            <div className="productSec">
                                <h4 className="productName">{brandName}</h4>
                                {
                                    isStock ? <p className="Instock">{translate("In Stock")}</p>
                                        : <p className="outOfStock">{translate("Out of stock")}</p>
                                }
                            </div>
                            {
                                modelNumber ? <p className="modelNumber">{modelNumber}</p>
                                    : null
                            }
                            <h4 className="washName">{name}</h4>
                            <div className="prices">
                                {originalPrice ? <p className="oldPrice">{`${translate("SAR")} ${originalPrice}`}</p> : null}
                                <p className="originalPrice">{`${translate("SAR")} ${price}`}</p>
                            </div>
                            {
                                isInstallment ?
                                    <div className="instaPlan">
                                        <p>{translate("INSTALLMENT")}</p>
                                        <button className="view_plan">{translate("View Plan")}</button>
                                    </div> : null
                            }
                            {
                                mainVariant?.length ?
                                    <Grid container>
                                        <p className="paraColor">{mainVariant?.[0]?.title}</p>
                                        {
                                            mainVariant?.length ? mainVariant?.map((attribute, index) => {
                                                return <Grid key={`attribute-${index}`}>
                                                    {renderVariants(attribute, index)}
                                                </Grid>
                                            }) : null
                                        }
                                    </Grid> : null
                            }
                            {
                                subVariant?.length ?
                                    <Grid container>
                                        <p className="paraColor">{subVariant?.[0]?.title}</p>
                                        {
                                            subVariant?.length ? subVariant?.map((attribute, index) => {
                                                return <Grid key={`attribute-${index}`}>
                                                    {renderVariants(attribute, index)}
                                                </Grid>
                                            }) : null
                                        }
                                    </Grid> : null
                            }

                            <Grid container sx={{ marginTop: '2em' }}>
                                <FormControl sx={{ minWidth: 80 }} size="small">
                                    <InputLabel id="demo-select-small" className={classes.inputLabel}>{translate("Quantity")}</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={quantity}
                                        disabled={isStock ? false : true}
                                        label={translate("Quantity")}
                                        className={classes.select}
                                        onChange={(e) => { setQuantity(e.target.value) }}
                                    >
                                        {
                                            quantities.map((quantity, ind) => {
                                                return <MenuItem key={`item-${ind}`} value={quantity.value}>{quantity.label}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <div className="productDelivery">
                                <p className="delivery">{translate("Delivery by")}</p>
                                <p className="deliveryDate">{`${dateAfterTwoDays(new Date(), localStorage.getItem('deliveryTime'))}`}</p>
                            </div>

                            <div className="warranty">
                                <div className="warrantyIn">
                                    <img src={Images.warranty} className="return" />
                                    <p>{warrenty} {translate("months warranty")}</p>
                                </div>
                                <div className="between">
                                    <span></span>
                                </div>
                                <div className="returnIn">
                                    <img src={Images.return} className="warrantyImg" />
                                    <p>{returnPolicy} days {translate("Return")}</p>
                                </div>

                            </div>


                            <div className="wish_btns">
                                {
                                    <button className="btn btn_red" style={{ margin: '0 1em', fontWeight: 600 }} disabled={!isStock} onClick={handleAddToCart}>{translate("GO TO CART")}<ShoppingCartCheckoutIcon /></button>
                                }
                                {
                                    isFavorites ? <button className="btn btn_grey" style={{ fontWeight: 600 }} onClick={() => handleWishlist(0)}>{translate("WISHLISTED")} <FavoriteIcon sx={{ fill: '#da1739' }} /></button>
                                        : <button className="btn btn_grey" style={{ fontWeight: 600 }} onClick={() => handleWishlist(1)}>{translate("ADD TO WISHLIST")}<img src={Images.wishlist} /></button>
                                }
                            </div>

                            <div className="accordions">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 600 }}>
                                            {translate("Product Overview")}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p className="fully">{productOverview}</p>
                                    </AccordionDetails>
                                </Accordion>
                                <Highlights highlights={highlights} />
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 600 }}>{translate("Specification")}</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            specification &&
                                            Object.entries(specification?.[0]).map((item, index) => {
                                                return <div key={`item-${index}`} className="details_summary">
                                                    <div className="details_name">
                                                        <div>
                                                            {item[0]}
                                                        </div>
                                                        <div>{item[1]}</div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <Reviews reviews={reviews} />
                        </div>
                    </div>

                    <OtherProducts relatedProducts={relatedProducts} />
                </div>
            </div>

            <Footer />
        </>
    )

}

export default ProductDetails;