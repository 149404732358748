/* eslint-disable no-unused-vars */
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import useStyle from '../styles.js'

export const Wallet = () => {

  const classes = useStyle();

  return (
    <Paper sx={{ margin: '2em', padding: '5em', width: '100%', height: '400px', display: 'flex' }}>
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        Wallet is Empty.
      </Grid>
    </Paper>
  )
}