/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
// eslint-disable no-param-reassign
import { format, addDays } from "date-fns";

export const getFormattedDateWithTime = (val) => format(val ? new Date(val) : new Date(), "MMMM do, yyyy hh:mm a");

export const formattedDate = (val) => format(new Date(val), "MMM d, yyyy");

export const getFormattedDateWithDay = (val) => format(val ? new Date(val) : new Date(), "EEEE, MMM d, yyyy");

export const dateAfterTwoDays = (d1, days)=>{
  const dateAfterSomeDays = addDays(d1 ? new Date(d1) : new Date(), days);
  return format(new Date(dateAfterSomeDays), "EEEE, MMM d, yyyy");
}

const hasProperty = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

export const isArray = (data) => data && data.constructor === Array;

export const isEmpty = (val) => {
  if (!val || val === null) {
    return true;
  }
  if (typeof val === "string") {
    return val.trim().length <= 0;
  }

  if (typeof val === "object") {
    if (isArray(val)) {
      return `${val}`.trim().length === 0;
    }
    return Object.keys(val).length === 0;
  }
  return `${val}`.trim().length === 0;
};

export const clone = (obj) => {
  if (!obj) {
    return obj;
  }
  if (isArray(obj)) {
    return obj.map((o) => JSON.parse(JSON.stringify(o)));
  }
  return JSON.parse(JSON.stringify(obj));
};

export const isEqual = (value, other) => {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) {
    return false;
  }

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) {
    return false;
  }

  // Compare the length of the length of the two items
  const valueLen = type === "[object Array]" ? value.length : Object.keys(value).length;
  const otherLen = type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) {
    return false;
  }

  // Compare two items
  const compare = function (item1, item2) {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!this.isEqual(item1, item2)) {
        return false;
      }
    } else {
      // Otherwise, do a simple comparison
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) {
        return false;
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) {
          return false;
        }
      } else if (item1 !== item2) {
        return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) {
        return false;
      }
    }
  } else {
    for (const key in value) {
      if (hasProperty(value, key)) {
        if (compare(value[key], other[key]) === false) {
          return false;
        }
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export const transform = (value, formatt, options) => {
  const TEXT_LIMIT = 40;
  const ellipsis = "...";

  if (!value || !formatt || !value || !formatt) {
    return "";
  }
  if (formatt === "STRING") {
    if (value === null || !value) {
      return "";
    }
    const tempVal = value.toString();
    if (tempVal.length > TEXT_LIMIT) {
      return `${value.substr(0, TEXT_LIMIT)}${ellipsis}`;
    }
    return value;
  }

  if (formatt === "ENUM") {
    return options === null || !options ? "" : options[value];
  }

  return value;
};

export const isValidDate = (d) => d instanceof Date && !isNaN(d);

export const isObject = (obj) => obj !== null && obj.constructor.name === "Object";

export const debounce = (func, delay) => {
  let timeout;
  return function (...args) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(this, args);
    }, delay);
  };
};

export const searchArrayObject = (values, filterValue, args) => {
  if (!values || !values.length) {
    return [];
  }
  if (!filterValue) {
    return values;
  }

  const filter = filterValue.toLowerCase();

  if (filter && isArray(values)) {
    // const keys = Object.keys(values[0]);
    // return values.filter(v => v && keys.some(k => v[k].toLowerCase().indexOf(filter) >= 0));
    if (args.multiple) {
      return values.filter((item) => args.keys.some((k) => nestedSearch(k, item, filter)));
      // return values.filter(item => args.keys.reduce((key, v) => c[v] || {}, item).indexOf(filter) >= 0);
      // return values.filter(o => Object.keys(o).some(k => args.keys.includes(k) && o[k].toLowerCase().includes(values)));
    }
    return values.filter((v) => v[args?.key]?.toLowerCase()?.indexOf(filter) >= 0);
  }
};

export const nestedSearch = (key, item, filter) => {
  if (key.includes(".")) {
    const splittedArr = key.split(".");
    const value = item[splittedArr[0]];
    if (!value) {
      return false;
    }
    if (Array.isArray(value)) {
      const updatedKey = splittedArr.slice(1).join(".");
      const result = [];
      value.forEach((val) => {
        result.push(nestedSearch(updatedKey, val, filter));
      });
      if (result.includes(true)) {
        return true;
      }
      return false;
    }
    if (isObject(value)) {
      const updatedKey = splittedArr.slice(1).join(".");
      if (value[splittedArr[1]]) {
        return nestedSearch(updatedKey, value[splittedArr[1]], filter);
      }
      return false;
    }
  }

  if (!item) {
    return false;
  }

  const val = item[key];
  if (!val) {
    return false;
  }
  const type = Array.isArray(val) ? "ARRAY" : isObject(val) ? "OBJECT" : "STRING";
  if (type === "OBJECT") {
    return ObjectFilter(val, filter);
  }
  if (type === "ARRAY") {
    return arrayFilter(val, filter);
  }
  return val ? val.toLowerCase().includes(filter) : false;
};

const ObjectFilter = (obj, str) =>
  Object.keys(obj).some((j) => (obj[j] ? obj[j].toLowerCase().includes(str) : false));

const arrayFilter = (arr, str) =>
  arr.some((item) => (item ? item.toLowerCase().includes(str) : false));

export function replaceItemAtIndex(arr, index, newValue) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export function removeItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

// Recurive function - To convert the empty values to null.
export function formatEmptyValuesToNull(data) {
  if (!data) {
    return null;
  }
  const type = Array.isArray(data) ? "ARRAY" : isObject(data) ? "OBJECT" : "VALUE";
  if (type === "VALUE") {
    if (isEmpty(data)) {
      return null;
    }
    return data;
  }
  if (type === "OBJECT") {
    let finalres = {};
    let allNull = true;
    Object.entries(data).forEach((obj) => {
      const result = formatEmptyValuesToNull(obj[1]);
      if (!isEmpty(result)) {
        allNull = false;
      }
      finalres = { ...finalres, [obj[0]]: result };
    });
    if (allNull) {
      return null;
    }
    return finalres;
  }
  if (type === "ARRAY") {
    const finalres = [];
    let allNull = true;
    data.forEach((item) => {
      const result = formatEmptyValuesToNull(item);
      if (!isEmpty(result)) {
        allNull = false;
      }
      finalres.push(result);
    });
    if (allNull) {
      return null;
    }
    return finalres;
  }
}

export const currencyFormat = (number) => {
  if (number) {
    const formattedCurrency = new Intl.NumberFormat({
      style: "currency",
      currency: "USD",
    }).format(number);
    return `$ ${formattedCurrency}`;
  }
  return "";
};

export const numberFormat = (number) => {
  if (number !== null) {
    const formattedNumber = new Intl.NumberFormat("en-IN").format(number);
    return formattedNumber;
  }
  return "";
};

const checkIndexValueAvailability = (updatedNumber, numLen, start, end) =>
  numLen > start
    ? numLen > end
      ? `-${updatedNumber.slice(start, end)}`
      : `-${updatedNumber.slice(start)}`
    : "";

export const getFormattedPhoneNumber = (number) => {
  if (number) {
    const updatedNumber = number.includes("+") ? number : `+${number}`;
    const numLen = updatedNumber.length;
    const countryLen = numLen > 12 ? updatedNumber.length % 10 : 2;
    const countryCode = updatedNumber.slice(0, countryLen);
    const formattedNumber = `${checkIndexValueAvailability(
      updatedNumber,
      numLen,
      countryLen,
      countryLen + 3
    )}${checkIndexValueAvailability(updatedNumber, numLen, countryLen + 3, countryLen + 6)}${
      numLen > countryLen + 6 ? `-${updatedNumber.slice(countryLen + 6)}` : ""
    }`;
    return `${countryCode}${formattedNumber}`;
  }
  return "";
};

export const splitName = (name = "") => {
  try {
    const [firstName, ...lastName] = name ? name.split(" ").filter(Boolean) : [];
    return {
      firstName,
      lastName: lastName.join(" "),
    };
  } catch (error) {
    console.error(error);
  }
};

export const isAllNameEmpty = (member) =>
  isEmpty(member?.firstName) && isEmpty(member?.lastName) && isEmpty(member?.middleName);

export const isNameAvailable = (member) =>
  !isEmpty(member?.firstName) || !isEmpty(member?.lastName) || !isEmpty(member?.middleName);

export const buildFullName = (member) => {
  if (isNameAvailable(member)) {
    let nameArray = [member?.firstName || "", member?.middleName || "", member?.lastName || ""];
    nameArray = nameArray.filter(Boolean);
    return nameArray.join(" ");
  }
  return member?.name || "";
};

export const roundNumber = (number, decimal) => {
  let value = number;
  let exp = decimal;
  try {
    if (typeof exp === "undefined" || +exp === 0) {
      return Math.round(value);
    }
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split("e");
    value = Math.round(+`${value[0]}e${value[1] ? +value[1] + exp : exp}`);
    // Shift back
    value = value.toString().split("e");
    return +`${value[0]}e${value[1] ? +value[1] - exp : -exp}`;
  } catch (error) {
    console.error(error);
  }
};

export const removeNullInObject = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null));
