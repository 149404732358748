
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '90px',
        height: '40px'
    },
    logoTxt: {
        textAlign: 'left',
        fontWeight: '500',
        color: '#0F0C0C',
        width: '120px',
        margin: '0.5em 0 0 5em'
    },
    dropTxt: {
        color: '#030202',
        fontSize: '15px',
        textAlign: 'left',
        display: 'flex'
    },
    navBar: {
        display: 'flex'
    }
}));

export default useStyles;
