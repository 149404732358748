import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid } from '@mui/material';

export function CircularLoader(props) {
  const { overlay = false, loaderProps = {} } = props;

  useEffect(() => {
    const html = document.getElementsByTagName('body')[0];

    if (overlay) {
      html.classList.add('lock-scroll');
    } else {
      html.classList.remove('lock-scroll');
    }
    return () => {
      html.classList.remove('lock-scroll');
    };
  }, [overlay]);

  return <>
    {
      overlay ? <Grid item xs={12} sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: 0.8,
        background: 'grey',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        alignItems: 'center',
        display: 'flex'
      }}>
        <CircularProgress color="inherit" size={100} sx={{ margin: '0 auto' }} {...loaderProps} />
      </Grid> :
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" size={30} {...loaderProps} />
        </Box>
    }
  </>;
}
