/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Button, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import useStyle from '../styles.js'
import Images from '../../../Helpers/images';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useEffect } from 'react';
import { useState } from 'react';
import { CircularLoader } from '../../../shared/CircularLoader';
import apiservice from '../../../Helpers/apiservices';
import useSnackBar from '../../../hooks/useSnackBar.js';
import { I18nContext } from '../../../contexts/i18n.js';

export const Wishlist = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { showSnack } = useSnackBar();
  const [loader, setLoader] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    getFavorites();
  }, [])

  const getFavorites = async () => {
    if (localStorage.getItem('authToken')) {
      setLoader(true)
      const res = await apiservice.getFavorites();
      if (res.success) {
        setFavorites(res?.data);
      } else {
        console.error(res.error);
        setFavorites([]);
      }
      setLoader(false);
    } else {
      navigate("/login", { state: { from: '/' } })
    }
  };

  const handleAddToCart = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    if (localStorage.getItem('authToken')) {
      const data = {
        "productId": item.productId,
        "quantity": 1,
        "price": item.price,
        originalPrice: item.originalPrice,
      }
      const res = await apiservice.addToCart(data);
      if (res.success) {
        showSnack({ open: true, type: 'success', message: res.massage });
      } else {
        showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
      }
      getFavorites();
    } else {
      showSnack({ open: true, type: 'error', message: <>{translate("Please login to add the products in cart")}</> });
    }
  }

  const handleWishlist = async (e, id, status) => {
    e.preventDefault();
    e.stopPropagation();
    const data = {
      "productId": id,
      "isFavourites": status,
    }
    const res = await apiservice.addToFavorites(data);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: <>{translate("Added to Wishlist successfully")}</> });
    } else {
      showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
    }
    getFavorites();
  }

  return (
    <div className="row" style={{ margin: '1em 2em' }}>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      {
        favorites?.length ? favorites.map((item, index) => {
          return (
            <div className="col-md-4" key={`item-${index}`}>
              <div className={classes.card}>
                <div className={classes.topCard}>
                  <button className={classes.ratingBtn}>{item?.productRating ? `${item.productRating}.0` : '0.0'}
                    <img alt='starImage' src={Images.star} className={classes.star} onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(`/productDetails/${item?.productId}`)
                    }} />
                  </button>
                  <div>
                    {item?.isFavorites ? <FavoriteIcon sx={{ fill: '#da1739', cursor: 'pointer' }} onClick={(e) => handleWishlist(e, item?.productId, 0)} />
                      : <img src={Images.heart} className={classes.heartImg} onClick={(e) => handleWishlist(e, item?.productId, 1)} />
                    }
                    {/* <CloseIcon sx={{ margin: '0 -10px 0 10px' }} /> */}
                  </div>
                </div>

                <img alt='cardImage' src={item?.image?.[0]?.url} className={classes.cardImg} />

                <p className={classes.productBrandName}>{item?.brandName}</p>
                <p className={classes.productName}>{item.name}</p>
                <div className="prices">
                 {item?.originalPrice ? <p className={classes.oldPrice}>{`${translate("SAR")} ${item.originalPrice}`}</p> : null}
                  <p className={classes.originalPrice}>{`${translate("SAR")} ${item.price}`}</p>
                  {
                      item?.modelNumber ? <p className="modelNumber">Model Number - {item?.modelNumber}</p>
                      : null
                     } 
                </div>
                <Button className={classes.cartBtn} onClick={(e) => handleAddToCart(e, item)}>{translate("Move To Cart")}</Button>
                {
                  item?.isStock ? <p className="Instock">{translate("In Stock")}</p>
                    : <p className="outOfStock">{translate("Out of stock")}</p>
                }
              </div>

            </div>
          )
        })
          : <Paper sx={{ margin: '2em', padding: '5em', width: '100%', height: '400px', display: 'flex' }}>
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
              {translate("No data found")}
            </Grid>
          </Paper>
      }
    </div>
  )
}
