
import { makeStyles } from '@material-ui/core/styles';
import { THEME_COLORS } from '../../theme';

const useStyles = makeStyles((theme) => ({
  quanchip: {
    // backgroundColor: theme.custom.icon.background,
    borderRadius: '0.4em',
    marginRight: '0.5em',
    margin: '0.3em 0.6em 0.3em 0em',
    height: 'auto',
    padding: '0.3em',
    '& .MuiChip-label': {
      whiteSpace: 'normal'
    }
  },
  awalText: {
    marginLeft: 'auto',
    color: '#f9b971',
    fontWeight: 600,
    fontSize: '12px',
    marginRight: '2em'
  },
  serviceDtlsPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
    height: 'auto',
    width: '100%',
    margin: '1em',
    cursor: 'pointer'
  },
  summaryPaper: {
    margin: '1em',
    height: 'auto !important',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer'
  },
  productBrandName: {
    color: '#000000',
    fontSize: '11px',
    textAlign: 'left',
    marginBottom: '5px'
  },
  productName: {
    color: '#000000',
    marginBottom: '3px',
    textAlign: 'left',
    fontSize: '17px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '350px'
  },
  oldPrice: {
    marginRight: '11px',
    textDecoration: 'line-through'
  },
  originalPrice: {
    color: '#F0493E',
  },
  stock: {
    color: '#16861F',
    textAlign: 'left',
  },
  prices: {
    display: 'flex',
  },
  couponGrid: {
    paddingRight: '1em',
  },
  button: {
    textTransform: 'none',
    color: `${THEME_COLORS.primaryColor}`,
  },
  percentIcon: {
    borderRadius: '30px',
    background: '#fff',
    padding: '12px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    fontSize: '3em',
    color: THEME_COLORS.primaryColor
  },
  mrpRate: {
    display: 'flex',
    flex: '1 1 50%',
    justifyContent: 'flex-end',
  },
  proceedBtn: {
    backgroundColor: `${THEME_COLORS.primaryColor} !important`,
    color: `${THEME_COLORS.textWhite} !important`,
    textTransform: 'none !important',
    margin: '2em 0'
  },
  removeBtn: {
    backgroundColor: `transparent !important`,
    color: `${THEME_COLORS.primaryColor} !important`,
    border: `1px solid ${THEME_COLORS.primaryColor} !important`,
    margin: '2em 0 !important',
    textTransform: 'none !important',
    marginRight: '2em !important'
  },
  addressText: {
    fontSize: '13px',
    color: `${THEME_COLORS.textGrey}`
  },
  addressGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  addressBtn: {
    backgroundColor: `transparent !important`,
    color: `${THEME_COLORS.primaryColor} !important`,
    border: `1px solid ${THEME_COLORS.primaryColor} !important`,
    textTransform: 'none !important'
  },
  addressContainer: {
    height: '400px',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'auto'
    }
  },
}));

export default useStyles;
