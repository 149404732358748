import { Button, Grid } from "@mui/material";
import React from "react";

export const Highlights = ({ highlights }) => {

  const handlePdf = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url);
  }

  return (
    <>
      {
        highlights?.length ? highlights.map((val, ind) => {
          if (val.type === 'image') {
            return <img alt="highlights" key={`val-${ind}`} src={val.url} style={{ width: '100%', height: '260px' }}></img>
          } else if (val.type === 'video') {
            return <video width="100%" key={`val-${ind}`} src={val.url} controls />
          } else if (val.type === 'pdf') {
            return <Grid container key={`val-${ind}`} style={{ background: '#f0493e', height: '100px', marginTop: '1em' }} alignItems={'center'}>
              <Grid item display={'flex'} alignContent={'center'}>
                <img src={'images/pdf-icon.png'} alt="pdf" style={{ width: '70px', height: '60px', marginLeft: '0.5rem' }} />
                <p style={{ color: '#fff', fontSize: '1.2rem', display: 'flex', alignItems: 'center', margin: '0 1em' }}>Detail of products</p>
              </Grid>
              <Grid item sx={{ ml: 'auto', mr: '1em' }}>
                <Button sx={{ color: '#fff', border: '1px solid' }} onClick={(e) => handlePdf(e, val.url)}>View PDF</Button>
              </Grid>
            </Grid>
          } else {
            return null
          }
        }) : null
      }
    </>
  )
}