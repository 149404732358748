/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import ProductListInCart from './productListInCart';
import CartTotal from './cartTotal';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/i18n';

const CartList = ({ counter, vatAmnt, setStepperValue, cartDetails, getCartList, cartTotal, step, setCartDetailsArray }) => {
  const { translate } = useContext(I18nContext);

  return (
    <>
      <Grid container sx={{ paddingTop: '1em' }}>
        <Grid container justifyContent={'center'} sx={{ marginBottom: '1em' }}>
          <Typography sx={{ fontWeight: 600 }}>{translate('Cart')}</Typography>
        </Grid>
        {
         cartDetails?.data?.length ? <Grid container>
            <Grid item sx={{ flex: '1 1 50%', padding: '0.5em 1em 0 1em' }}>
              <ProductListInCart step={step} setCartDetailsArray={setCartDetailsArray} setStepperValue={setStepperValue} cartDetails={cartDetails} getCartList={getCartList} />
            </Grid>
            <Grid item sx={{ flex: '0 0 0%', background: '#dad1d1d4' }}>
              <Divider orientation="vertical" />
            </Grid>

            <Grid item sx={{ flex: '1 1 49%', padding: '0.5em 1em 0 1em' }}>
              <CartTotal counter={counter} vatAmnt={vatAmnt} setStepperValue={setStepperValue} cartTotal={cartTotal} cartDetails={cartDetails} />
            </Grid>
          </Grid>
            : <Paper sx={{ margin: '2em', padding: '5em', width: '100%' }}>
              <Grid container sx={{ justifyContent: 'center' }}>
                {translate("Cart is Empty.")}
              </Grid>
            </Paper>
        }
      </Grid>
    </>
  )

}

export default CartList;