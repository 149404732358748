
import { makeStyles } from '@material-ui/core/styles';

export const dialogSyles = makeStyles((theme) => ({
  dialog: {
    padding: '2em !important'
  },
  dialogContentText: {
    padding: '0 0.5em'
  },
  dialogTitle: {
    padding: '0.75em 1em !important',
    fontWeight: '600 !important',
    display: 'flex'
  },
  dialogTitleText: {
    paddingRight: '1em'
  },
  closeButton: {
    marginLeft: 'auto !important',
    marginTop: '-4px !important'
  }
}));

export const expandCollapseStyles = makeStyles((theme) => ({
  paper: {
    margin: '1em',
    height: 'auto',
    boxShadow: 'none'
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5em'
  },
  widgetContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0.5em 0.5em 0.5em'
  },
  collapseSection: {
    marginLeft: 'auto',
    fontSize: '0.7em',
    display: 'flex'
  },
  iconColor: {
    marginLeft: '0.7em',
    cursor: 'pointer',
    height: '1em',
    width: '1em',
  },
  titleIcon: {
    fontSize: '1.3em',
    marginRight: '1em'
  },
  titleTypo: {
    fontSize: 14,
    marginLeft: '1em'
  },
  subTitle: {
    fontSize: 14,
  }
}));

export const PlacesAutocompleteStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    zIndex: 20,
    border: `1px solid grey`,
    width: '100%'
  },
  option: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `grey !important`
    }
  },
  optionHover: {
   backgroundColor: `grey !important`
  },
  optionTypo: {
   // fontSize: theme.typography.fontSize,
    padding: '0.3em 0.6em'
  },
  poweredBy: {
    fontSize: '0.9rem',
    fontWeight: 600,
    padding: '0.3rem 0.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    }
  },
  googleLogo: {
    height: '20px',
    width: '55px',
    margin: '0.4rem 0.5rem 0 0',
    [theme.breakpoints.down('sm')]: {
      height: '17px',
      width: '51px',
      margin: '0.3rem 0.5rem 0 0'
    }
  }
}));