import { Paper } from '@material-ui/core';
import {  Chip, Divider, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import useStyle from '../styles.js';
import PercentIcon from '@mui/icons-material/Percent';
import { CircularLoader } from '../../../shared/CircularLoader.js';
import apiservice from '../../../Helpers/apiservices.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { I18nContext } from '../../../contexts/i18n.js';

export const Coupon = () => {

  const classes = useStyle();
  const [coupons, setCoupons] = useState([]);
  const [loader, setLoader] = useState(false);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    getCoupons();
  }, [])

  const getCoupons = async () => {
    setLoader(true);
    const res = await apiservice.getCoupons();
    if (res.success) {
      setCoupons([...res?.data]);
    } else {
      console.error(res.error);
      setCoupons([]);
    }
    setLoader(false);
  };

  return (
    <Paper className={classes.paper}>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      {
        coupons?.length && coupons?.map((item, index) => {
          return <Grid key={`item-${index}`}>
            <Grid container alignItems={'center'} >
              <Grid item className={classes.couponGrid}>
                <PercentIcon className={classes.percentIcon} />
              </Grid>
              <Grid item display={'flex'} flexDirection={'column'} className={classes.chipGrid}>
                <Typography sx={{ fontWeight: 600, textAlign: 'left', fontSize: '12px', mb: '0.5em' }}>{item.couponName}</Typography>
                <Typography className={classes.codeBox}>{item.couponCode}</Typography>
                <Chip className={classes.chip} label={`${translate("You will save")} ${item.discountPercentage}% ${translate("with this code")}`} />
              </Grid>
              {/* <Grid item display={'flex'} justifyContent={'flex-end'} sx={{ flex: '1 1 50%' }}>
                <Button className={classes.button}>{translate("Apply")}</Button>
              </Grid> */}
            </Grid>
            <Divider sx={{ margin: '10px 0' }} />
          </Grid>
        })
      }
    </Paper>
  )
}
