
import { makeStyles } from '@material-ui/core/styles';
import { THEME_COLORS } from '../../theme';

const useStyles = makeStyles((theme) => ({
  bannerImg: {
    width: '100%',
    height: '400px'
  },
  paper: {
    margin: '2em',
    display: 'flex'
  },
  card: {
    margin: '1em',
    height: '100px',
    width: '100%',
    display: 'flex',
    padding: '1em',
    alignItems: 'center',
    cursor: 'pointer',
    background: `${THEME_COLORS.cardBgColor} !important`
  },
  proceedBtn: {
    backgroundColor: `${THEME_COLORS.primaryColor} !important`,
    color: `${THEME_COLORS.textWhite} !important`,
    textTransform: 'none !important',
    margin: '1em'
  },
  issuesPaper: {
    width: '100%',
    margin: '1em',
    cursor: 'pointer',
    display: 'flex'
  },
  radioBtn: {
    '&.Mui-checked': {
      color: `${THEME_COLORS.primaryColor} !important`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
  },
  subText: {
    marginTop: '2px !important',
    color: `${THEME_COLORS.textGrey} !important`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    textAlign: 'left'
  },
  addressText: {
    fontSize: '13px !important',
    color: `${THEME_COLORS.textGrey} !important`
  },
  addressBtn: {
    backgroundColor: `transparent !important`,
    color: `${THEME_COLORS.primaryColor} !important`,
    border: `1px solid ${THEME_COLORS.primaryColor} !important`,
    margin: '1em !important',
    textTransform: 'none !important'
  },
  addressGrid: {
    height: '400px',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'auto'
    }
  },
  formInputRoot: {
    margin: '5px 0px'
  },
  awalText: {
    marginLeft: 'auto',
    fontWeight: 600,
    fontSize: '12px',
    marginRight: '2em'
  },
  serviceDtlsPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
    height: 'auto',
    width: '100%',
    margin: '1em',
    cursor: 'pointer'
  },
  summaryPaper: {
    margin: '1em 0 0 0',
    height: 'auto !important',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer'
  },
  inputLabel:{
    display: 'none'
  }
}));

export default useStyles;
