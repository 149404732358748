import React, { useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormContext } from 'react-hook-form';

export function FormTextField(props) {
  const { textProps, clear = false, readOnly = false, styles = {}, inputProps = {}, nonEditable = false } = props;
  const [ clearIcon, setClearIcon ] = useState(clear);
  const { setValue, trigger } = useFormContext();

  useEffect(() => {
    setClearIcon(clear);
  }, [ clear ]);

  const handleClear = () => {
    if (!readOnly) {
      setValue(textProps.name, '');
      trigger();
    }
  };

  return <Box sx={{ display: 'flex', padding: '0.25em 0' }} >
    <TextField disabled={nonEditable} fullWidth variant="standard" {...textProps} sx={{ mr: '1em', width: '400px' }}
      value={textProps.value || ''}
      InputProps={{
        ...inputProps,
        readOnly: readOnly,
        classes: { root: styles.root ? styles.root : '' },
        endAdornment: clearIcon && !readOnly && <InputAdornment position="end" sx={{ cursor: 'pointer' }} >
          <IconButton disabled={nonEditable} disableRipple={true} size="small" onClick={handleClear}><CloseIcon /></IconButton>
        </InputAdornment>
      }}
      onMouseEnter={() => setClearIcon(true)}
      onMouseLeave={() => setClearIcon(false)} />
    {/* <Box component={'span'} sx={{ minWidth: '2em', cursor: 'pointer', mt: '0.75em' }}>{clearIcon && <CloseIcon />}</Box> */}
  </Box>;
}
