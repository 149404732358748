
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wholeContainer: {
        width: '90%',
        margin: '0px auto',
        //marginTop: '55px',
        "& .MuiSelect-outlined": {
            padding: "9.5px 14px"
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px'
        }
    },
    sortContainer: {
        '& .MuiSelect-outlined': {
            padding: '4.5px 14px',
            fontSize: '13px'
        },
        '& .MuiFormControlLabel-label': {
            color: '#646464',
            fontSize: '14px'
        },
        '& .MuiCheckbox-root': {
            padding: '6px'
        },
        '& .MuiFormGroup-root': {
            paddingLeft: '18px'
        }
    },
    productListSec: {
        width: '100%'
    },
    sortBy: {
        width: '100%',
        clear: 'both',
        float: 'left',
        margin: '1em 0'
    },
    card: {
        // width: '460px',
        backgroundColor: '#FFFFFF',
        borderRadius: '9px',
        padding: '23px',
        width: '100%',
        marginTop: '16px',
        //margin: '0 10px',
        border: '1px solid #e8e8e8',
        '&:hover': {
            boxShadow: '-4px 7px 19px #efefef',
            border: '1px solid #bbb',
        }
    },
    topCard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    heartImg: {
        width: '38px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease 0s;',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    ratingBtn: {
        backgroundColor: '#FBFBFB',
        border: 'none',
        boxShadow: '-1px 3px 9px #00000029',
        borderRadius: '5px',
        width: '57px',
        fontSize: '12px',
        textAlign: 'start',
        padding: '0px 8px',
        height: '32px',
    },
    star: {
        width: '15px',
        float: 'right'
    },
    cardImg: {
        width: '60%',
        marginTop: '27px',
        height: '105px',
        objectFit: 'contain'
    },
    productBrandName: {
        color: '#000000',
        fontSize: '11px',
        marginTop: '31px',
        textAlign: 'left',
        marginBottom: '5px'
    },
    productName: {
        color: '#000000',
        marginBottom: '3px',
        textAlign: 'left',
        fontSize: '17px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    oldPrice: {
        marginRight: '11px',
        textDecoration: 'line-through',
        textAlign: 'left'
    },
    originalPrice: {
        color: '#F0493E',
        textAlign: 'left'
    },
    stock: {
        color: '#16861F',
        textAlign: 'left',
    },

}));

export default useStyles;
