import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CommonDialog } from '../../../shared/CommonDialog';
import { PRODUCT_ISSUES } from '../technician-constant';
import useStyles from './../styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/i18n';

const BookingSummary = (props) => {
  // const { handleStepClick = () => { } } = props;
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { translate } = useContext(I18nContext);

  const handleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  const getDialogContent = () => {
    return <Grid container flexDirection={'column'} sx={{ padding: '3em' }}>
      <Grid display={'flex'} justifyContent={'center'} sx={{ mb: '1em' }}>
        <CheckCircleIcon sx={{ fontSize: '3em', fill: '#f0493e' }} />
      </Grid>
      <Typography sx={{ mb: '1em' }}>{translate("Your Booking Successfully")}</Typography>
      <Button className={classes.addressBtn}>
        {translate("View Booking")}
        <ArrowForwardIosIcon sx={{ fontSize: '1em', ml: '10px' }} />
      </Button>
    </Grid>
  }

  return <>
    <Grid container sx={{ paddingTop: '1em' }}>
      <Grid container justifyContent={'center'} sx={{ marginBottom: '1em' }}>
        <Typography sx={{ fontWeight: 600 }}>{translate('Service Detail')}</Typography>
      </Grid>
      <Grid item sx={{ flex: '1 1 50%', padding: '0.5em 1em 0 1em' }}>
        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{'AC Split'}</Typography>
          <Typography className={classes.awalText}>{translate('Awal Product')}</Typography>
          <Paper className={classes.serviceDtlsPaper} >
            {
              PRODUCT_ISSUES?.map((item, index) => {
                return <Grid key={`item-${index}`}>
                  <Grid container alignItems={'center'} >
                    <Grid item display={'flex'} flexDirection={'column'} >
                      <Typography sx={{ fontWeight: 600, textAlign: 'left', fontSize: '12px', mb: '1em' }}>{item.headerText}</Typography>
                      <Typography sx={{ fontSize: '12px' }}>{item.subText}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ margin: '10px 0' }} />
                </Grid>
              })
            }
            <Grid container >
              <Typography sx={{ fontWeight: 600 }}>{translate('Total Amount')}</Typography>
              <Typography sx={{ marginLeft: 'auto', color: '#f9b971', fontWeight: 600, fontSize: '12px' }}>{translate('Quotation after Visit')}</Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Notes to Technician')}</Typography>
          <Paper className={classes.serviceDtlsPaper} >
            <Grid container alignItems={'center'} >
              <Grid item display={'flex'} flexDirection={'column'} >
                <Typography sx={{ fontSize: '12px' }}>{'ede forieferf fiuerfhi friufiufi irrfirfiuerh'}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Select Address')}</Typography>
          <Paper className={classes.serviceDtlsPaper} >
            <Grid container alignItems={'center'} >
              <Grid item display={'flex'} flexDirection={'column'} >
                <Typography sx={{ fontSize: '12px' }}>{'ede forieferf fiuerfhi friufiufi irrfirfiuerh'}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Service Date')}</Typography>
          <Paper className={classes.serviceDtlsPaper} >
            <Grid container alignItems={'center'} >
              <Grid item display={'flex'} flexDirection={'column'} >
                <Typography sx={{ fontSize: '12px' }}>{'ede forieferf fiuerfhi friufiufi irrfirfiuerh'}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

      </Grid>

      <Grid sx={{ flex: '0 0 0%', background: '#dad1d1d4' }}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item sx={{ flex: '1 1 49%', padding: '0.5em 1em 0 1em' }}>
        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Price Detail')}</Typography>
          <Paper className={classes.summaryPaper}>
            <Grid container alignItems={'center'} sx={{ padding: '1em' }}>
              <Grid item display={'flex'} flexDirection={'column'} sx={{ marginLeft: '1em' }}>
                <Typography sx={{ textAlign: 'left' }}>{translate('Total Service Amount')}</Typography>
                <Typography sx={{ textAlign: 'left' }}>{translate('Service Advance')}</Typography>
                <Typography sx={{ textAlign: 'left' }}>{translate("VAT Amount")}</Typography>
                <Divider sx={{ margin: '10px 0' }} />
                <Typography sx={{ textAlign: 'left', fontWeight: 600 }}>{translate('Total Amount')}</Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: '0em 2em 1em 2em' }}>
              <Button className={classes.proceedBtn} onClick={() => setDialogOpen(true)}>{translate('PROCEED TO PAY')}</Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <CommonDialog options={{ maxWidth: 'md', dialogOpen: dialogOpen }} onDialogClose={handleDialog} >
        {getDialogContent()}
      </CommonDialog>

    </Grid>
  </>
}

export default BookingSummary;