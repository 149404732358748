import React, { useContext } from 'react';
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from '../Pages/home';
import Login from '../Pages/login';
import TechnicianLogin from '../Pages/login/technicianLogin';
import VerifyOtp from '../Pages/login/verifyOtp';
import ProductDetails from '../Pages/productDetails';
import ProductListing from '../Pages/productListing';
import Profile from '../Pages/profile';
import Technician from '../Pages/technician';
import Cart from '../Pages/cart';
import { useEffect } from 'react';
import { I18nContext } from '../contexts/i18n';

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>
};


const Router = () => {
  const {  locale } = useContext(I18nContext);
 
  return (
    <div style={{ direction: locale.code === 'ar' ? 'rtl' : 'ltr', fontFamily: locale.code === "ar" ? "DroidArabicKufiRegular" : "'Poppins', sans-serif", }}>
      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/technicianlogin" element={<TechnicianLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/profile/*" element={<Profile />} />
          <Route path="/login/verifyOtp" element={<VerifyOtp />} />
          <Route path="/productList" element={<ProductListing />} />
          <Route path="/productDetails/:id" element={<ProductDetails />} />
          <Route path="/technician" element={<Technician />} />
        </Routes>
      </HashRouter>
    </div>
  )
}

export default Router;
