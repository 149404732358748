/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import useStyle from './style.js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './style.css';
import { Grid } from '@mui/material';
import { I18nContext } from '../../contexts/i18n.js';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import apiCrud from '../../Helpers/apicrud.js';

const Filter = (props) => {
  const { state } = props;
  const classes = useStyle();
  const { translate } = useContext(I18nContext);
  const [filterValues, setFilterValues] = useState([]);
  const [selectedCategory, setCategory] = useState([]);
  const [expand, setExpand] = useState({
    type: null,
    open: false
  });

  useEffect(() => {
    getFilter();
  }, []);

  const getFilter = async () => {
    const apiBaseUrl = "http://15.185.136.78:8080/v1";
    const response = state ? await apiCrud.getData(apiBaseUrl + `/user/filter/?${state.type}=${state.id}`)
      : await apiCrud.getData(apiBaseUrl + `/user/filter`);
    if (response.success) {
      setFilterValues([...response?.data])
    }
    else {
      setFilterValues([])
    }
  }

  const FILTER_KEYS = {
    "brands": "filterBrandId",
    "category": "filterCategoryId",
    "subCategory": "filterSubCategoryId",
    "color": "color",
    "madeIn": "madeIn"
  }

  const applyFilter = async (arr) => {
    let obj = {};
    arr?.forEach((item) => {
      const category = `${FILTER_KEYS[item.category]}`;
      const option = item.option;
      obj = { ...obj, [category]: JSON.stringify(option) }
    })
    props.filteredData(obj);
  }

  const handleSelectedOptions = (e, selectedCat, option) => {
    props.emptySort();
    const updateCategory = selectedCategory.find(t => t.category === selectedCat);
    if (e.target.checked) {
      const Tasks = updateCategory ? { category: selectedCat, option: [...updateCategory.option, option] } : { category: selectedCat, option: [option] }
      setCategory([...selectedCategory.filter(ot => ot.category !== selectedCat), Tasks]);
      let tempArr = [...selectedCategory.filter(ot => ot.category !== selectedCat), Tasks];
      applyFilter(tempArr);
    } else {
      const array = [...updateCategory.option];
      const index = array.indexOf(option);
      if (index > -1) { // only splice array when item is found
        array.splice(index, 1); // 2nd parameter means remove one item only
      }
      const Tasks = { category: selectedCat, option: [...array] }
      setCategory([...selectedCategory.filter(ot => ot.category !== selectedCat), Tasks]);
      let tempArr = [...selectedCategory.filter(ot => ot.category !== selectedCat), Tasks];
      applyFilter(tempArr);
    }
  };

  const handleExpand = (value) => {
    setExpand({
      type: value.type,
      open: true
    })
  }

  const handleExpandClose = () => {
    setExpand({
      type: null,
      open: false
    })
  }

  return (
    <div className={classes.sortContainer}>
      <h4 className="filter">{translate("Filter")}</h4>
      <div className="shop-inner">

        {
          filterValues?.length ? filterValues.map((value, index) => {
            return <React.Fragment key={`value-${index}`}>
              <p className="shop_price brand" style={{ fontSize: '15px', fontWeight: 600 }}>{value?.title}
                {value.type !== expand.type ? <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} onClick={() => handleExpand(value)} />
                  : <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} onClick={() => handleExpandClose()} />}
              </p>
              {
                value?.type === 'color' && value.type === expand.type ? <FormGroup>
                  {
                    value?.data?.length ? value.data.map((item, ind) => {
                      return (
                        <Grid container key={`item-${ind}`}>
                          <FormControlLabel control={<Checkbox onChange={(e) => handleSelectedOptions(e, value.type, item.name)} />} label={item.name} />
                          <div className="box" style={{ background: `${item.name}` }}></div>
                        </Grid>
                      )
                    }) : null
                  }
                </FormGroup>
                  : value.type === expand.type && <FormGroup>
                    {
                      value?.data?.length ? value.data.map((item, ind) => {
                        return (
                          <FormControlLabel key={`item-${ind}`} control={<Checkbox onChange={(e) => handleSelectedOptions(e, value.type, item.id.toString())} />} label={item.name} />
                        )
                      }) : null
                    }
                  </FormGroup>
              }
            </React.Fragment>
          }) : null
        }

      </div>
    </div>
  )

}

export default Filter;