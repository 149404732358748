/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { I18nContext } from '../contexts/i18n';

export function FormSelect(props) {
  const { selectProps, options = [], icon, clear = false, styles = {}, showIcon = true } = props;
  const [ clearIcon, setClearIcon ] = useState(clear);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setClearIcon(clear);
  }, [ clear ]);

  return <Box sx={{ display: 'flex', padding: '0.25em 0' }} >
    <TextField select fullWidth variant="standard" { ...selectProps} sx={{ mr: '1em' }}>
      {
        options && options.length ? options.map((option) => {
        return <MenuItem  key={option.value} value={option.value}><Typography>{translate(option.label)}</Typography></MenuItem>;
         }) : <MenuItem key={''} value={''}>Select</MenuItem>
      }
    </TextField>
  </Box>;
}
