
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wholeContainer: {
        width: '100%',
        overflowX: 'auto',
        position: 'sticky',
        boxShadow: '8px 16px 32px #00000029',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        top: '65px',
        background: "#f8f8f8",
        zIndex: 1
    },
    productImg: {
        width: '73%',
        height: '83px'
    },
    productContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        paddingTop: '23px',
        paddingLeft: '20px'
    },
    product: {
        minWidth: '113px',
        maxWidth: '113px'
    },
    paraName: {
        fontSize: '12px',
        textAlign: 'center',
        width: '100%',
        color: '#000',
        fontWeight: '500'
    }
}));

export default useStyles;
