/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import apiservice from '../../../Helpers/apiservices.js';
import { CircularLoader } from '../../../shared/CircularLoader.js';
import { CommonDialog } from '../../../shared/CommonDialog.js';
import AddAddress from '../../technician/components/addAddress.js';
import useStyle from '../styles.js'
import { useNavigate } from 'react-router-dom';
import useSnackBar from '../../../hooks/useSnackBar.js';
import { I18nContext } from '../../../contexts/i18n.js';

export const Addresses = () => {

  const classes = useStyle();
  const navigate = useNavigate();
  const { showSnack } = useSnackBar();
  const { translate } = useContext(I18nContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [savedAddress, setSavedAddress] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);
  const [address, setAddress] = useState({});

  useEffect(() => {
    getAddresses();
  }, [])

  const getAddresses = async () => {
    if (localStorage.getItem('authToken')) {
      setAddressLoader(true);
      const res = await apiservice.getAddress(1);
      if (res.success) {
        setSavedAddress([...res?.data]);
      } else {
        console.error(res.error);
        setSavedAddress([]);
      }
      setAddressLoader(false);
    } else {
      navigate("/login", { state: { from: '/' } })
    }
  };

  const handleAddressBtnClick = () => {
    setAddress({});
    setDialogOpen(!dialogOpen)
  }

  const addressUpdate = (data) => {
    setDialogOpen(true);
    setAddress(data);
  }

  const deleteAddress = async (data)=>{
    setAddressLoader(true)
    const res = await apiservice.removeAddress(data.id);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: res.massage });
    } else {
      showSnack({ open: true, type: 'error', message: <>{translate("Something went wrong")}</> });
      console.error(res.error);
    }
    setAddressLoader(false);
    getAddresses()
  }

  return (
    <Paper className={classes.paper}>
      {addressLoader && <Grid><CircularLoader overlay={true} /></Grid>}
      <Grid className={classes.addressPaper}>
        {
          savedAddress?.length ? savedAddress?.map((item, index) => {
            return <Grid key={`item-${index}`}>
              <Grid className={classes.addressGrid}>
                <Typography sx={{ fontWeight: 600 }}>{item.addressName}</Typography>
                <Typography className={classes.addressText}>{`${item.buildingName}`}</Typography>
                <Typography className={classes.addressText}>{`${item.address}`}</Typography>
                <Typography className={classes.addressText}>{item.landmark}</Typography>
              </Grid>
              <Grid container sx={{ margin: '0.5em 0 0 -1em' }}>
                <Button className={classes.button} onClick={() => addressUpdate(item)}>{translate("Edit")}</Button>
                <Button className={classes.button} onClick={()=>deleteAddress(item)}>{translate("Delete")}</Button>
              </Grid>
            </Grid>
          })
            : <Paper sx={{ margin: '2em', padding: '5em', width: '100%', height: '400px', display: 'flex' }}>
              <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {translate("No data found")}
              </Grid>
            </Paper>
        }
      </Grid>
      {localStorage.getItem('authToken') &&
        <Grid display={'flex'} flexDirection={'column'} sx={{ mt: '1em' }}>
          <Button className={classes.addressBtn} onClick={handleAddressBtnClick}>{translate('Add Address')}</Button>
        </Grid>}

      <CommonDialog options={{ dialogOpen: dialogOpen }} onDialogClose={handleAddressBtnClick} title={<>{translate('Add Address')}</>}>
        <AddAddress onClose={handleAddressBtnClick} getAddresses={getAddresses} updateAddress={address} />
      </CommonDialog>

    </Paper>
  )
}
