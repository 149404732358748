import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyle from '../style.js';
import Carousel, { consts } from "react-elastic-carousel";
import { Button, Grid } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { I18nContext } from '../../../contexts/i18n.js';

const PopularBrands = ({ PopularBrands }) => {

  const classes = useStyle();
  const navigate = useNavigate();
  const { translate } = useContext(I18nContext);

  const renderArrows = ({ type, onClick, isEdge }) => {
    return type === consts.PREV ?
      <Button onClick={onClick} className={classes.arrowButton} sx={{ display: isEdge ? 'none' : 'block' }}>
        <KeyboardArrowLeftIcon className={classes.arrowIcon} sx={{ display: isEdge ? 'none' : 'block' }} />
      </Button>
      : <Button onClick={onClick} className={classes.arrowButton} >
        <KeyboardArrowRightIcon className={classes.arrowIcon} />
      </Button>
  }

  const CAROUSEL_BREAKPOINTS = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 8, itemsToScroll: 8 }
  ];

  return (
    <>
      <div className={classes.whyAwall}>
      {
            PopularBrands?.length ?
            <h5 className={classes.popularHeader}>{translate("Popular Brands")}</h5>
            : null 
      }
        <Carousel breakPoints={CAROUSEL_BREAKPOINTS} pagination={false}
          renderArrow={renderArrows}
        >
          {
            PopularBrands?.length ? PopularBrands.map((brand, index) => {
              return <Grid item key={`brand-${index}`} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/productList`, { state: { type: 'brand', id: brand?.id } });
              }
              }>
                <img alt='brandName' src={brand.image} className={classes.brandImg} />
              </Grid>
            }) : null
          }
        </Carousel>
      </div>
    </>
  )

}

export default PopularBrands;