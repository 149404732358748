import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THEME_COLORS } from '../theme'

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  margin: '5px 0px 10px 10px',
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '&.Mui-disabled': {
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 'none',
        backgroundColor: THEME_COLORS.primaryColor
      }
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      '& + .MuiSwitch-track': {
        opacity: 0.5,
        border: 'none',
        backgroundColor: '#d7bebe'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#d7bebe',
    boxSizing: 'border-box',
  }
}));
