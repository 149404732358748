/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import useStyle from '../style.js';
import { Button, Divider, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography } from '@mui/material'; import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import ProductListInCart from './productListInCart';
import CartTotal from './cartTotal.js';
import { I18nContext } from '../../../contexts/i18n.js';

const BookingSummary = ({ deliveryMethod, vatAmnt, addressInfo, setStepperValue, cartDetails, getCartList, cartTotal, step }) => {
  const classes = useStyle();
  const { translate } = useContext(I18nContext);

  const PaymentOptions = [
    { label: 'Wallet', value: 'wallet' },
    { label: 'COD', value: 'cod' },
    { label: 'Master Card', value: 'masterCard' },
    { label: 'Visa', value: 'visa' },
    { label: 'Apple Pay', value: 'applePay' }
  ]

  const handleDirections = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`http://maps.google.co.uk/maps?q=${cartDetails.latitude},${cartDetails.longitude}`)
  }

  const pickupData = () => {
    return <Grid container>
      <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Select Address')}</Typography>
      <Paper className={classes.summaryPaper}>
        <Grid container alignItems={'flex-start'} sx={{ padding: '1em' }} flexDirection={'column'}>
          <Grid item>
            {cartDetails.address}
          </Grid>
          <Grid item className={classes.couponGrid}>
            <Button className={classes.proceedBtn} sx={{ margin: '1em 0' }} onClick={(e) => handleDirections(e)}>{translate("GET DIRECTIONS")}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  }

  return (
    <>
      <Grid container sx={{ paddingTop: '1em' }}>
        <Grid container justifyContent={'center'} sx={{ marginBottom: '1em' }}>
          <Typography sx={{ fontWeight: 600 }}>{translate('Booking Summary')}</Typography>
        </Grid>
        <Grid item sx={{ flex: '1 1 50%', padding: '0.5em 1em 0 1em' }}>
          <ProductListInCart step={step} setStepperValue={setStepperValue} cartDetails={cartDetails} getCartList={getCartList} />
          <Grid container>
            <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Delivery Method')}</Typography>
            <Paper className={classes.summaryPaper}>
              <Grid container alignItems={'center'} sx={{ padding: '1em' }}>
                <Grid item className={classes.couponGrid}>
                  <DoorSlidingOutlinedIcon />
                </Grid>
                <Grid item>
                  <Typography>{translate(deliveryMethod)}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {
            deliveryMethod === 'Door Delivery' ?
              <Grid container>
                <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Select Address')}</Typography>
                <Paper className={classes.summaryPaper}>
                  <Grid container alignItems={'center'} sx={{ padding: '1em' }}>
                    <Grid item className={classes.couponGrid} sx={{ flex: '1 1 50%' }}>
                      <Grid className={classes.addressGrid}>
                        <Typography sx={{ fontWeight: 600 }}>{addressInfo?.address?.addressName}</Typography>
                        <Typography className={classes.addressText}>{`${addressInfo?.address?.buildingName}`}</Typography>
                        <Typography className={classes.addressText}>{addressInfo?.address?.address}</Typography>
                        <Typography className={classes.addressText}>{addressInfo?.address?.landmark}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid> : pickupData()
          }

          <Grid container>
            <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Payment Method')}</Typography>
            <FormControl className={classes.summaryPaper}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {
                  PaymentOptions?.map((pay, index) => {
                    return <Paper key={`pay-${index}`} sx={{ margin: '1em', p: '1em' }}><FormControlLabel sx={{ display: 'flex', ml: '2em' }} value={pay.value} control={<Radio />} label={pay.label} /></Paper>
                  })
                }
              </RadioGroup>
            </FormControl>
          </Grid>

        </Grid>

        <Grid sx={{ flex: '0 0 0%', background: '#dad1d1d4' }}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item sx={{ flex: '1 1 49%', padding: '0.5em 1em 0 1em' }}>
          <CartTotal deliveryMethod={deliveryMethod} cartDetails={cartDetails} vatAmnt={vatAmnt} addressInfo={addressInfo} setStepperValue={setStepperValue} cartTotal={cartTotal} step={step} />
        </Grid>

      </Grid>
    </>
  )

}

export default BookingSummary;