import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import * as Yup from 'yup';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

export const PROFILE_MENUS = [
    {
        value: 'updateProfile',
        label: 'Profile',
        icon: PersonOutlineOutlinedIcon
    },
    {
        value: 'bookings',
        label: 'My Bookings',
        icon: BookmarksIcon
    },
    {
        value: 'order',
        label: 'My Orders',
        icon: ListAltOutlinedIcon
    },
    {
        value: 'address',
        label: 'Manage Addresses',
        icon: PermContactCalendarOutlinedIcon
    },
    // {
    //     value: 'wallet',
    //     label: 'My Wallet',
    //     icon: AccountBalanceWalletOutlinedIcon
    // },
    {
        value: 'wishlist',
        label: 'My Wishlist',
        icon: FavoriteOutlinedIcon
    },
    {
        value: 'coupon',
        label: 'Coupon',
        icon: CardGiftcardOutlinedIcon
    },
    {
        value: 'settings',
        label: 'Settings',
        icon: SettingsOutlinedIcon
    },
    {
        value: 'helpSupport',
        label: 'Help & Support',
        icon: SupportAgentOutlinedIcon
    }
];

export const HEADER_TITLES = {
    profile: 'Profile',
    address: 'Manage Address',
    wishlist: 'My Wishlist',
    coupon: 'Coupons',
    settings: 'Settings'
}

export const ProfileSchema = Yup.object().shape({
    firstName: Yup.string().required('Firstname is required'),
    lastName: Yup.string().required('Lastname is required'),
    email: Yup.string().nullable().email('Email is invalid')
});