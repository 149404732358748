
import { makeStyles } from '@material-ui/core/styles';
import { THEME_COLORS } from '../../theme';

const useStyles = makeStyles((theme) => ({
  wholeContainer: {
    boxShadow: '8px 16px 32px #00000029',
  },
  productContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '100%',
    paddingLeft: '20px',
    height: '120px',
  },
  logo: {
    height: '70px',
    width: '120px',
    margin: '1em 4.5em'
  },
  logOutBtn: {
    backgroundColor: `${THEME_COLORS.primaryColor}`,
    color: `${THEME_COLORS.textWhite}`,
    textTransform: 'none',
    '&:hover':{
      backgroundColor: `${THEME_COLORS.primaryColor}`,
    }
  },
  logOutBtnGrid: {
    marginLeft: 'auto',
  },
  tabsRoot: {
    width: '100%',
    margin: '2em',
    background: '#fff'
  },
  activeTab: {
    borderBottom: `1px solid ${THEME_COLORS.primaryColor}`,
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'black !important',
    fontWeight: 600,
    fontFamily: `'Poppins', sans-serif !important`
  },
  inActiveTab: {
    borderBottom: '1px solid #adb4c1',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontFamily: `'Poppins', sans-serif !important`
  },
  activeIcon: {
    borderRadius: '20px',
    padding: '4px',
    background: `${THEME_COLORS.primaryColor}`,
    color: '#fff',
    margin: '0 1em'
  },
  inActiveIcon: {
    borderRadius: '20px',
    padding: '4px',
    background: '#e3e5e9',
    color: 'black',
    margin: '0 1em'
  },
  paper: {
    padding: '2em',
    margin: '1em 0 1em 2em'
  },
  addressPaper: {
    overflow: 'hidden',
    height: '428px',
    '&:hover': {
      overflow: 'auto'
    }
  },
  inputClass: {
    width: '100% !important',
    marginLeft: '1em',
    height: '4em !important',
    bottom: '9px'
  },
  disabledInputClass: {
    width: '100% !important',
    marginLeft: '1em',
    height: '4em !important',
    bottom: '9px',
    cursor: 'default !important',
  },
  buttonClass: {
    border: 'none !important ',
    background: 'transparent  !important',
    height: '35px !important',
    borderRadius: '0  !important'
  },
  containerClass: {
    marginTop: '1em'
  },
  updateButton: {
    border: `1px solid ${THEME_COLORS.primaryColor}`,
    color: `${THEME_COLORS.primaryColor}`,
    width: '100%',
    margin: '1em 1em 0 0'
  },
  addressText: {
    fontSize: '13px',
    color: `${THEME_COLORS.textGrey}`
  },
  addressBtn: {
    backgroundColor: `transparent`,
    color: `${THEME_COLORS.primaryColor}`,
    border: `1px solid ${THEME_COLORS.primaryColor}`,
    textTransform: 'none'
  },
  addressGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #adb4c1',
    padding: '1em 0'
  },
  button: {
    textTransform: 'none',
    color: `${THEME_COLORS.primaryColor}`,
  },
  card: {
    backgroundColor: '#FFFFFF',
    borderRadius: '9px',
    padding: '23px',
    width: '100%',
    margin: '0 1em 1em 0',
    border: '1px solid #e8e8e8',
    '&:hover': {
      boxShadow: '-4px 7px 19px #efefef',
      border: '1px solid #bbb',
    }
  },
  topCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  heartImg: {
    fill: 'red',
    cursor: 'pointer',
    background: '#fde8e7',
    borderRadius: '30px',
    padding: '4px',
    transition: 'transform 0.2s ease 0s;',
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  ratingBtn: {
    backgroundColor: '#FBFBFB',
    border: 'none',
    boxShadow: '-1px 3px 9px #00000029',
    borderRadius: '5px',
    width: '57px',
    fontSize: '12px',
    textAlign: 'start',
    padding: '0px 8px',
    height: '32px'
  },
  star: {
    width: '15px',
    float: 'right'
  },
  cardImg: {
    width: '60%',
    marginTop: '27px',
    height: '105px',
    objectFit: 'contain'
  },
  productBrandName: {
    color: '#000000',
    fontSize: '14px',
    marginTop: '31px',
    textAlign: 'left',
    marginBottom: '5px'
  },
  serviceName: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'left',
    marginBottom: '5px'
  },
  description:{
    fontSize: '12px',
    color: '#605656',
    fontWeight: '600',
    textAlign: 'left',
    marginBottom: '5px'
  },
  productName: {
    color: '#000000',
    marginBottom: '3px',
    textAlign: 'left',
    fontSize: '17px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  oldPrice: {
    marginRight: '11px',
    textDecoration: 'line-through'
  },
  originalPrice: {
    color: '#F0493E'
  },
  stock: {
    color: '#16861F',
    textAlign: 'right',
  },
  prices: {
    display: 'flex',
  },
  cartBtn: {
    backgroundColor: `transparent`,
    color: `${THEME_COLORS.primaryColor}`,
    border: `1px solid ${THEME_COLORS.primaryColor}`,
    textTransform: 'none ',
    width: '100%',
    marginBottom: '1em'
  },
  couponGrid: {
    margin: '1em 0 1em 0',
    paddingRight: '1em',
  },
  percentIcon: {
    borderRadius: '30px',
    background: '#fff',
    padding: '12px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    fontSize: '4em'
  },
  codeBox: {
    border: '1px dotted #4b31315c',
    fontSize: '10px',
    padding: '4px',
    textAlign: 'left',
    fontWeight: 600
  },
  chip: {
    color: '#55af55',
    background: '#eff8ef',
    borderRadius: 0,
    fontSize: '9px',
    marginTop: '1em'
  },
  chipGrid: {
    borderLeft: '1px solid #1111115e',
    paddingLeft: '1em'
  },
  searchFieldSection: {
    display: 'flex',
    borderRadius: '0.3em',
    backgroundColor: '#fff',
    border: '1px solid transparent',
    alignSelf: 'center',
    margin: '0 0 0 2em',
  },
  searchField: {
    flex: '1 1 300px'
  },
  searchFieldInput: {
    padding: '0.25em 0.25em 0.4em 0.4em',
    '&::placeholder': {
      fontSize: '0.8em !important',
      fontWeight: 600
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.4em',
    borderRadius: '0.25em',
    cursor: 'pointer',
    alignSelf: 'center'
  },
  icon: {
    fontSize: '1.2em'
  },
  serviceDtlsPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
    height: 'auto',
    width: '100%',
    margin: '1em',
    cursor: 'pointer',
    marginLeft: '2em'
  },
  quanchip: {
    // backgroundColor: theme.custom.icon.background,
    borderRadius: '0.4em',
    marginRight: '0.5em',
    margin: '0.3em 0.6em 0.3em 0em',
    height: 'auto',
    padding: '0.3em',
    '& .MuiChip-label': {
      whiteSpace: 'normal'
    }
  },
  cartPaper: {
    padding: '2em',
    width: '100%',
    margin: '1em'
  },
  mrpRate: {
    display: 'flex',
    flex: '1 1 50%',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
