import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THEME_COLORS } from '../theme';

export const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: THEME_COLORS.primaryColor,
    display: 'none'
  },
  '& label': {
    color: THEME_COLORS.primaryColor,
    display: 'none'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: THEME_COLORS.primaryColor,
    background: '#e8e9ea',
  },
  '& .MuiOutlinedInput-root': {
    background: '#e3e5e9',
    border: 'none',
    '& fieldset': {
      borderColor: THEME_COLORS.primaryColor,
      border: 'none'
    },
    '&:hover fieldset': {
      borderColor: THEME_COLORS.primaryColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: THEME_COLORS.primaryColor,
      border: `none`,
    },
  },
});