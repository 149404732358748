/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { formattedDate, getFormattedDateWithDay } from '../../../common-util';
import apiservice from '../../../Helpers/apiservices';
import useSnackBar from '../../../hooks/useSnackBar';
import { CircularLoader } from '../../../shared/CircularLoader';
import { CommonDialog } from '../../../shared/CommonDialog';
import useStyles from './../styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { I18nContext } from '../../../contexts/i18n';

const ServiceDetails = (props) => {
  const {  subServices, servicess, handleStepDownClick = () => { }, address = {}, goToSubService = () => { } } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { translate, locale} = useContext(I18nContext);
  const { showSnack } = useSnackBar();
  const [loader, setLoader] = useState(true);
  const [notes, setNotes] = useState('');
  const [serviceDetails, setServiceDetails] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  useEffect(() => {
    getMainServices();
  }, [])

  const getMainServices = async () => {
    setLoader(true)
    const res = await apiservice.getMainServicesWithoutId();
    if (res.success) {
      let arr = [];
      res?.data?.forEach((mainSer) => {
        subServices?.forEach((subSer) => {
          if (subSer?.mainServiceId === mainSer?.id) {
            const filteredResult = servicess.filter((ser) => ser.id == mainSer?.serviceId);
            arr.push({ service: { id: filteredResult[0]?.id, title: filteredResult[0].name, serviceId: subSer?.mainServiceId, serviceName: subSer?.name, isAwalProduct: true }, subServices: [{ id: subSer.id, price: 0, title: subSer.name, description: subSer.description, underWarranty: false }] })
          }
        })
      })
      const arrayHashmap = arr.reduce((obj, item) => {
        obj[item?.service?.id] ? obj[item?.service?.id].subServices.push(...item?.subServices) : (obj[item?.service?.id] = { ...item });
        return obj;
      }, {});

      const mergedArray = Object.values(arrayHashmap);
      let serviceDetails = [];
      mergedArray.forEach((item) => {
        serviceDetails = [...serviceDetails, { ...item.service, serviceList: item.subServices }]
      })
      setServiceDetails(serviceDetails);
    } else {
      console.error(res.error);
      setServiceDetails([]);
    }
    setLoader(false);
  };

  const handleSubmit = async () => {
    const payload = {
      advanceAmount: 50,
      notes: notes,
      serviceTime: getFormattedDateWithDay(address?.dateValue),
      deliveryAddress: JSON.stringify(address?.addressValue),
      paymentType: 1,
      vat: 0,
      serviceDetails: JSON.stringify(serviceDetails),
    }
    const res = await apiservice.addToBookings(payload);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: <>{translate("Your Booking Successfully")}</> });
      setDialogOpen(true);
    } else {
      showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
    }
  }

  const getDialogContent = () => {
    return <Grid container flexDirection={'column'} sx={{ padding: '3em' }}>
      <Grid display={'flex'} justifyContent={'center'} sx={{ mb: '1em' }}>
        <CheckCircleIcon sx={{ fontSize: '3em', fill: '#f0493e' }} />
      </Grid>
      <Typography sx={{ mb: '1em' }}>{translate("Your Booking Successfully")}</Typography>
      <Button className={classes.addressBtn} onClick={() => navigate('/profile/bookings')}>
        {translate("View Booking")}
        <ArrowForwardIosIcon sx={{ fontSize: '1em', ml: '10px' }} />
      </Button>
    </Grid>
  }

  return <>
    <Grid container sx={{ paddingTop: '1em' }}>
      <Grid container justifyContent={'center'} sx={{ marginBottom: '1em' }}>
        <Typography sx={{ fontWeight: 600 }}>{translate('Service Detail')}</Typography>
      </Grid>
      <Grid item sx={{ flex: '1 1 50%', padding: '0.5em 1em 0 1em' }}>
        {loader && <Grid><CircularLoader overlay={true} /></Grid>}
        {
          serviceDetails?.map((data, ind) => {
            return <Grid container key={`data-${ind}`}>
              <Typography sx={{ fontWeight: 600, ml: '1em' }}>{data.title}</Typography>
              <Typography className={classes.awalText}>{translate('Awal Product')}</Typography>
              <Paper className={classes.serviceDtlsPaper} >
                {
                  data?.serviceList?.map((item, index) => {
                    return <Grid key={`item-${index}`}>
                      <Grid>
                        <Grid container alignItems={'center'}>
                          <Grid item display={'flex'} flexDirection={'column'}>
                            <Typography sx={{ fontWeight: 600, textAlign: 'left', fontSize: '12px', mb: '1em' }}>{item.title}</Typography>
                            <Typography sx={{ fontSize: '12px', textAlign: 'left' }}>{item.description}</Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ margin: '10px 0' }} />
                      </Grid>
                      {(index === data?.serviceList?.length - 1) && <>
                        <Grid container>
                          <Typography sx={{ fontWeight: 600 }}>{translate('Total Amount')}</Typography>
                          <Typography sx={{ marginLeft: 'auto', color: '#f9b971', fontWeight: 600, fontSize: '12px' }}>{translate('Quotation after Visit')}</Typography>
                        </Grid>
                        <Grid container>
                          <Button className={classes.addressBtn} sx={{ flexGrow: 1 }} onClick={() => goToSubService({ step: 2, id: data?.serviceId })}>{translate("Add Service")}</Button>
                        </Grid>
                      </>
                      }
                    </Grid>
                  })
                }
              </Paper>
            </Grid>
          })
        }

        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Notes to Technician')}</Typography>
          <Paper className={classes.serviceDtlsPaper} >
            <Grid container alignItems={'center'} >
              <Grid item xs={12} >
                <TextField
                  id="standard-multiline-static"
                  label="Multiline"
                  multiline
                  autoFocus
                  rows={4}
                  value={notes}
                  onChange={(event) => {
                    setNotes(event.target.value);
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  sx={{
                    display: 'flex',
                    '& .MuiInputBase-root.MuiInput-root:before': {
                      borderBottom: 'none'
                    },
                    '& .MuiInputBase-root.MuiInput-root:after': {
                      borderBottom: 'none'
                    },
                    '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                      borderBottom: 'none'
                    },
                    '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):after': {
                      borderBottom: 'none'
                    },
                    '& .MuiInputBase-root.MuiInput-root': {
                      marginTop: 0
                    },
                  }}
                  variant={"standard"}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container>
          <Button className={classes.addressBtn} sx={{ flexGrow: 1 }} onClick={() => handleStepDownClick(0)} >{translate("Add Another Service")}</Button>
        </Grid>

        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Select Address')}</Typography>
          <Paper className={classes.serviceDtlsPaper} >
            <Grid container alignItems={'center'} >
              <Grid item display={'flex'} flexDirection={'column'} >
                <Grid sx={{ textAlign: 'left' }}>
                  <Typography sx={{ fontWeight: 600 }}>{address?.addressValue?.addressName}</Typography>
                  <Typography className={classes.addressText}>{`${address?.addressValue?.buildingName}`}</Typography>
                  <Typography className={classes.addressText}>{`${address?.addressValue?.address}`}</Typography>
                  <Typography className={classes.addressText}>{address?.addressValue?.landmark}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Service Date')}</Typography>
          <Paper className={classes.serviceDtlsPaper} >
            <Grid container alignItems={'center'} >
              <Grid item display={'flex'} flexDirection={'column'} >
                <Typography sx={{ fontSize: '12px' }}>{formattedDate(address?.dateValue)}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

      </Grid>

      <Grid sx={{ flex: '0 0 0%', background: '#dad1d1d4' }}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item sx={{ flex: '1 1 49%', padding: '0.5em 1em 0 1em' }}>
        <Paper className={classes.issuesPaper} sx={{ margin: '1em 0 0 0 !important' }}>
          <Grid container alignItems={'center'}>
            <Grid item display={'flex'} flexDirection={'column'} sx={{ margin: '0 1em' }}>
              <Typography sx={{ textAlign: 'left', fontWeight: 600 }}>{translate('Advance Amount')}</Typography>
              <Typography className={classes.subText}>{`${translate("SAR")} 50`}</Typography>
            </Grid>
            <Grid item sx={{ m: locale.code === 'ar' ? '0 auto 0 0': '0 0 0 auto' }}>
              <Button className={classes.proceedBtn} sx={{ mr: '1em !important' }} onClick={handleSubmit}>{translate('NEXT')}</Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <CommonDialog options={{ maxWidth: 'md', dialogOpen: dialogOpen }} onDialogClose={handleDialog} >
        {getDialogContent()}
      </CommonDialog>

    </Grid>
  </>
}

export default ServiceDetails;