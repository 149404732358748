/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import { Button, Chip, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from 'react';
import useStyle from '../styles.js';
import { useState } from 'react';
import { CircularLoader } from '../../../shared/CircularLoader.js';
import apiservice from '../../../Helpers/apiservices.js';
import { useEffect } from 'react';
import '../styles.css';
import { useParams } from 'react-router-dom';
import { I18nContext } from '../../../contexts/i18n.js';

export const BookingDetails = () => {
  const { id } = useParams();
  const classes = useStyle();
  const { translate } = useContext(I18nContext);
  const [loader, setLoader] = useState(false);
  const [bookingDetails, setBookingDetails] = useState([]);

  useEffect(() => {
    getBookingDetails();
  }, [])

  const getBookingDetails = async () => {
    setLoader(true);
    const res = await apiservice.getBookingById(id);
    if (res.success) {
      let data = res?.data;
      setBookingDetails(data);
    } else {
      console.error(res.error);
      setBookingDetails([]);
    }
    setLoader(false);
  };


  return (
    <Grid container>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      {
        bookingDetails?.length && bookingDetails.map((item, index) => {
          return <Paper key={`item-${index}`} className={classes.serviceDtlsPaper}>
            <Grid container >
              <Grid item >
                <p style={{ fontWeight: 600 }}>{translate("Booking Detail")}</p>
              </Grid>
            </Grid>
            <Grid container sx={{ mb: '1em', background: '#f5f6f8', padding: '2em' }}>
              {
                item?.serviceDetails?.map((service, ind) => {
                  return <Grid item key={`service-${ind}`} sx={{ width: '60%' }}>
                    <p className={classes.productName} style={{ marginTop: 0 }}>{service?.title}</p>
                    {/* <p className={classes.serviceName}>{service?.serviceName}</p> */}
                    {
                      service?.serviceList?.map((subService, i) => {
                        return <Grid item key={`subService-${i}`}>
                          <p className={classes.serviceName}>{subService?.title}</p>
                          <p className={classes.description}>{subService?.description}</p>
                        </Grid>
                      })
                    }
                  </Grid>
                })
              }
            </Grid>
            {item?.notes && <Grid container >
              <Grid item sx={{ marginTop: '1em' }} >
                <p style={{ fontWeight: 600 }}>{translate('Notes to Technician')}</p>
              </Grid>
              <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.9em' }}>
                <Grid item>
                  <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{item?.notes}</Typography>
                </Grid>
              </Grid>
            </Grid>}
            <Grid container >
              <Grid item sx={{ marginTop: '1em' }} >
                <p style={{ fontWeight: 600 }}>{translate("Service Date & Time")}</p>
              </Grid>
              <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.9em' }}>
                <Grid item>
                  <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{item?.serviceTime}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '1em' }} >
              <Grid item >
                <p style={{ fontWeight: 600 }}>{translate("Booking Address")}</p>
              </Grid>
              <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.9em' }}>
                <Grid item sx={{ textAlign: 'left' }}>
                  <Typography sx={{ fontWeight: 600 }}>{item?.deliveryAddress?.addressName}</Typography>
                  <Typography className={classes.addressText}>{`${item?.deliveryAddress?.buildingName}`}</Typography>
                  <Typography className={classes.addressText}>{`${item?.deliveryAddress?.address}`}</Typography>
                  <Typography className={classes.addressText}>{item?.deliveryAddress?.landmark}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {
              item?.bookingStatus === 'COMPLETED' &&
              <Grid container sx={{ marginTop: '1em' }} >
                <Grid item >
                  <p style={{ fontWeight: 600 }}>{translate("Price Details")}</p>
                </Grid>
                <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.9em' }}>
                  <Grid item xs={12} sx={{ textAlign: 'left' }}>
                    {
                      item?.bookingDetails?.length ? item?.bookingDetails.map((price, i) => {
                        return price?.title==="Total Amount" ? <>
                        <Divider sx={{ margin: '10px 0' }} />
                         <Typography sx={{ textAlign: 'left', fontWeight: 600, display: 'flex' }}>{price?.title}<span className={classes.mrpRate}>{price?.value}</span></Typography>
                         </> : <Typography sx={{ textAlign: 'left', display: 'flex' }}>{price?.title}<span className={classes.mrpRate}>{price?.value}</span></Typography>
                      }): null
                    }
                  </Grid>
                </Grid>
              </Grid>
            }
          </Paper>
        })
      }
    </Grid>
  )
}
