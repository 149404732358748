/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import { Button, Chip, Divider, FormControl, Grid, IconButton, InputBase, InputLabel, Paper, Select, Typography } from '@mui/material';
import React from 'react';
import useStyle from '../styles.js';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { CircularLoader } from '../../../shared/CircularLoader.js';
import apiservice from '../../../Helpers/apiservices.js';
import { useEffect } from 'react';
import '../styles.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { CommonDialog } from '../../../shared/CommonDialog.js';
import { DeleteBookingDialog } from './DeleteBookingDialog.js';
import { getFormattedDateWithDay } from '../../../common-util.js';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/i18n.js';

export const Bookings = () => {

  const classes = useStyle();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const { translate, locale } = useContext(I18nContext);

  useEffect(() => {
    getBookingsDetails();
  }, [])

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  }

  const getDeleteDialogContent = () => {
    return <DeleteBookingDialog handleDeleteDialog={handleDeleteDialog} deleteId={deleteId} getBookingsDetails={getBookingsDetails} />
  }

  const getBookingsDetails = async () => {
    if (localStorage.getItem('authToken')) {
      setLoader(true)
      const res = await apiservice.getBookings();
      if (res.success) {
        setBookingDetails(res?.data);
      } else {
        console.error(res.error);
        setBookingDetails([]);
      }
      setLoader(false);
    } else {
      navigate("/login", { state: { from: '/' } })
    }
  };

  const handleCancelOrder = async (id) => {
    setDeleteDialogOpen(true);
    setDeleteId(id);
  }


  const orderTracker = (bookingStatus, item) => (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center align-content-center">
        <span className="d-flex justify-content-center align-items-center big-dot dot">
          <CheckCircleIcon sx={{ fill: item?.bookingDate || item?.bookingConfirmTime ? 'green' : 'grey' }} />
        </span>
        <hr className="flex-fill track-line" style={{ color: item?.bookingDate ? 'green' : 'grey', opacity: 1, border: 'none' }} />
        {
          bookingStatus !== 'CANCEL' ?
            <>
              <span className="d-flex justify-content-center align-items-center big-dot dot">
                <CheckCircleIcon sx={{ fill: (bookingStatus === 'ARRIVED' || bookingStatus === 'COMPLETED') ? 'green' : 'grey' }} />
              </span>
              <hr className="flex-fill track-line" style={{ color: (bookingStatus === 'ARRIVED' || bookingStatus === 'COMPLETED') ? 'green' : 'grey', opacity: 1, border: 'none' }} />
              <span className="d-flex justify-content-center align-items-center big-dot dot">
                <CheckCircleIcon sx={{ fill: bookingStatus === 'COMPLETED' ? 'green' : 'grey' }} />
              </span>
            </> : <>
              <span className="d-flex justify-content-center align-items-center big-dot dot">
                <CheckCircleIcon sx={{ fill: bookingStatus === 'CANCEL' ? 'green' : 'grey' }} />
              </span>
            </>
        }
      </div><div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-column align-items-start">
          <span style={{ fontSize: '14px' }}>{translate("Booking Confirmed")}</span>
          <span style={{ fontSize: '14px' }}>{item?.bookingConfirmTime ? getFormattedDateWithDay(item?.bookingConfirmTime) : null}</span>
        </div>
        {
          bookingStatus !== 'CANCEL' ?
            <>
              <div className="d-flex flex-column align-items-center">
                <span style={{ fontSize: '14px' }}>{translate("Technician Arrived")}</span>
                <span style={{ fontSize: '14px' }}>{item?.bookingArrivedTime ? getFormattedDateWithDay(item?.bookingArrivedTime) : null}</span>
              </div>
              <div className="d-flex flex-column align-items-end">
                <span style={{ fontSize: '14px' }}>{translate("Completed")}</span>
                <span style={{ fontSize: '14px' }}>{item?.bookingCompletedTime ? getFormattedDateWithDay(item?.bookingCompletedTime) : null}</span>
              </div>
            </> : <>
              <div className="d-flex flex-column align-items-end">
                <span style={{ fontSize: '14px' }}>{translate("Cancelled")}</span>
                <span style={{ fontSize: '14px' }}>{item?.bookingCancelTime ? getFormattedDateWithDay(item?.bookingCancelTime) : null}</span>
              </div>
            </>
        }
      </div>
    </>
  )

  return (
    <Grid container>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      <Grid container>
        {
          bookingDetails?.length ? bookingDetails.map((item, index) => {
            return <Paper key={`item-${index}`} className={classes.serviceDtlsPaper}>
              <Grid container sx={{ borderBottom: '1px dotted #777b7757', mb: '1em' }}>
                <Grid item >
                  <p style={{ fontWeight: 600 }}>{translate("Booking ID :")}{item.orderId}</p>
                </Grid>
                <Grid item sx={{ m: locale.code === 'ar' ? '0 auto 0 0' : '0 0 0 auto' }}>
                  {
                    item?.bookingStatus !== 'CANCEL' ? item?.bookingStatus === 'COMPLETED' ?
                      <p style={{ fontWeight: 600, color: 'green' }}>{`${translate("Completed on")} ${getFormattedDateWithDay(item.bookingCompletedTime)}`}</p>
                      : null
                      : <p style={{ fontWeight: 600, color: 'grey' }}>{`${translate("Cancelled on")} ${getFormattedDateWithDay(item.bookingCancelTime)}`}</p>
                  }
                </Grid>
              </Grid>
              <Grid container sx={{ borderBottom: '1px dotted #777b7757', mb: '1em', pb: '1em' }}>
                {
                  item?.serviceDetails?.map((service, ind) => {
                    return <Grid item key={`service-${ind}`} sx={{ width: '60%' }}>
                      <p className={classes.productName} style={{ marginTop: 0 }}>{service?.title}</p>
                      {/* <p className={classes.serviceName}>{service?.serviceName}</p> */}
                      {
                        service?.serviceList?.map((subService, i) => {
                          return <Grid item key={`subService-${i}`}>
                            <p className={classes.serviceName}>{subService?.title}</p>
                            <p className={classes.description}>{subService?.description}</p>
                          </Grid>
                        })
                      }
                    </Grid>
                  })
                }
                {item?.bookingStatus !== 'CANCEL' && <Grid item sx={{ m: locale.code === 'ar' ? '0 auto 0 0' : '0 0 0 auto', background: '#b7acac3b', p: '2em', width: '35%' }}>
                  {orderTracker(item?.bookingStatus, item)}
                </Grid>}
              </Grid>
              <Grid container>
                <Grid item >
                  {
                    item.bookingStatus === 'PLACED' || item.bookingStatus === 'CONFIRM' ? <Button className={classes.logOutBtn} onClick={() => handleCancelOrder(item?.id)}>{translate('CANCEL')}</Button>
                      : item.bookingStatus === 'CANCEL' ? <Button className={classes.logOutBtn} >{translate("Cancelled")}</Button>
                        : item?.bookingStatus === 'COMPLETED' ? <Button className={classes.logOutBtn} >{translate("BOOKING COMPLETED")}</Button>
                          : null
                  }
                </Grid>
                {item?.bookingStatus !== 'CANCEL' &&
                  <Grid item sx={{ m: locale.code === 'ar' ? '0 auto 0 0' : '0 0 0 auto' }}>
                    <p style={{ fontWeight: 600 }} onClick={() => navigate(`/profile/bookings/bookingDetails/${item.id}`)}>{translate('More Details')}<ArrowForwardIosIcon sx={{ fontSize: '1em', ml: '1em' }} /></p>
                  </Grid>
                }
              </Grid>
            </Paper>
          })
            : <Paper sx={{ margin: '2em', padding: '5em', width: '100%', height: '400px', display: 'flex' }}>
              <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {translate("You have not created any Bookings.")}
              </Grid>
            </Paper>
        }
      </Grid>
      <CommonDialog options={{ maxWidth: 'sm', dialogOpen: deleteDialogOpen }} onDialogClose={handleDeleteDialog} >
        {deleteDialogOpen && getDeleteDialogContent()}
      </CommonDialog>
    </Grid>
  )
}
