/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Footer from '../../Components/footer';
import Navbar from '../../Components/navbar';
import Products from '../../Components/products.js';
import useStyle from './style.js';
import PopularBrands from './components/PopularBrands';
import apiservice from '../../Helpers/apiservices';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Grid } from '@mui/material';
import OtherProducts from './components/otherProducts';
import Carousel, { consts } from 'react-elastic-carousel'
import { Circle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { I18nContext } from '../../contexts/i18n';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ShimmerCard from '../../shared/ShimmerCard';

const Home = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { translate } = useContext(I18nContext);
  const [homeData, setHomeData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getHomePageData();
    getBanners();
  }, [])

  const getHomePageData = async () => {
    const res = await apiservice.getHomePageData();
    if (res.success) {
      setHomeData([...res.data]);
      localStorage.setItem('cartCount', res?.cartCount || 0);
      localStorage.setItem('deliveryTime', res?.deliveryTime || 0);
    } else {
      console.error(res.error);
      setHomeData([...res.data]);
    }
    setLoader(false);
  };

  const whyAwalBanners = () => {
    let awalBanners = homeData?.filter((item) =>
      item.type === 'whyawal'
    )
    return awalBanners?.[0]?.data;
  }

  const getCategories = () => {
    let categories = homeData?.filter((item) =>
      item.type === 'ourcategories'
    )
    return categories;
  }

  const getPopularBrands = () => {
    let popularBrands = homeData?.filter((item) =>
      item.type === 'popularbrands'
    )
    return popularBrands?.[0]?.data;
  }

  const getBanners = async () => {
    const res = await apiservice.getBanners();
    if (res.success) {
      setBannerData([...res?.data])
    } else {
      console.error(res.error);
      setBannerData([])
    }
    setLoader(false);
  }

  const handleNavigateList = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/productList`, { state: { type: 'categoryId', id: item?.id } })
  }

  const getOtherProducts = () => {
    let otherProducts = homeData?.filter((item) =>
      item.type === 'product'
    )
    return otherProducts;
  }

  const renderArrows = ({ type, onClick, isEdge }) => {
    return type === consts.PREV ?
      <Button onClick={onClick} className={classes.arrowButton} sx={{ display: isEdge ? 'none' : 'block' }}>
        <KeyboardArrowLeftIcon className={classes.arrowIcon} sx={{ display: isEdge ? 'none' : 'block' }} />
      </Button>
      : <Button onClick={onClick} className={classes.arrowButton} >
        <KeyboardArrowRightIcon className={classes.arrowIcon} />
      </Button>
  }

  const CAROUSEL_BREAKPOINTS = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 8, itemsToScroll: 8 }
  ];

  return (
    <>
      <Navbar />
      {
        homeData?.length && bannerData?.length ?
          <>
            <Products data={getCategories()} />
            <Carousel itemsToShow={1} pagination={false} showArrows={false} enableAutoPlay autoPlaySpeed={3000}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <Grid container justifyContent={"center"} sx={{ margin: "1em 1em 0 1em" }}>
                    {pages.map(page => {
                      const isActivePage = activePage === page
                      return (
                        <Circle
                          key={page}
                          sx={{ fill: isActivePage ? "#f0493e" : "#aba6a6", fontSize: "1.3rem" }}
                          onClick={() => onClick(page)}
                          active={isActivePage}
                        />
                      )
                    })}
                  </Grid>
                )
              }}
            >
              {
                bannerData?.length ? bannerData.map((item, index) => {
                  return <img src={item?.image} key={`item-${index}`} className={classes.bannerImg} />
                }) : null
              }
            </Carousel>

            <div className={classes.centerContainer}>

              <div className={classes.whyAwall}>
                {
                  whyAwalBanners()?.length ? <h5 className={classes.awallHeader}>{translate("Why Awal")}</h5>
                    : null
                }

                <Carousel breakPoints={CAROUSEL_BREAKPOINTS} pagination={false}
                  renderArrow={renderArrows}
                >
                  {
                    whyAwalBanners()?.length ? whyAwalBanners().map((item, index) => {
                      return <Grid item key={`item-${index}`} onClick={(e) => handleNavigateList(e, item)}>
                        <img alt='brandName' src={item.image} className={classes.awalImg} />
                      </Grid>
                    }) : null
                  }
                </Carousel>

              </div>

              <PopularBrands PopularBrands={getPopularBrands()} />
              <OtherProducts relatedProducts={getOtherProducts()} />

            </div>
          </>
          : <ShimmerCard />
      }

      <Footer />
    </>
  )

}

export default Home;