/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Footer from '../../Components/footer';
import Navbar from '../../Components/navbar';
import CartList from './components/cartList';
import { useState } from 'react';
import DeliveryDetails from './components/deliveryDetail';
import BookingSummary from './components/bookingSummary';
import apiservice from '../../Helpers/apiservices';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { CircularLoader } from '../../shared/CircularLoader';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  //for updating the app
  const [counter, setCounter] = useState(0);
  const [loader, setLoader] = useState(true);
  const [cartDetails, setCartDetails] = useState({});
  const [vatAmnt, setVatAmnt] = useState(0);
  const [cartTotal, setCartTotal] = useState({
    productTotal: 0,
    couponDiscount: 0,
    grandTotal: 0
  });
  const [addressInfo, setAddressInfo] = useState({});
  const [deliveryMethod, setDeliveryMethod] = useState('Door Delivery');

  const getAddress = (data) => {
    setAddressInfo({ ...data });
  }

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    getCartDetails();
  }, [])

  const getCartDetails = async () => {
    if (localStorage.getItem('authToken')) {
      setLoader(true)
      const res = await apiservice.getCartDetails();
      if (res.success) {
        setVatAmnt(res?.vat);
        setCartDetails(res);
        setCartTotal({
          data: res?.data
        })
      } else {
        console.error(res.error);
        setVatAmnt(0);
        setCartDetails({});
      }
      setLoader(false);
    } else {
      navigate("/login", { state: { from: '/' } })
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  const setStepperValue = () => {
    setStep(step + 1);
  }

  const getDeliveryMethod = (event) => {
    setDeliveryMethod(event);
  }

  const setCartDetailsArray = (arr) => {
    setCartDetails(arr);
    setCounter(counter+1);
  }

  return (
    <>
      <Navbar />
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      {step === 0 && <CartList counter={counter} setCartDetailsArray={setCartDetailsArray} step={step} vatAmnt={vatAmnt} setStepperValue={setStepperValue} cartDetails={cartDetails} getCartList={getCartDetails} cartTotal={cartTotal}  />}
      {step === 1 && <DeliveryDetails getDeliveryMethod={getDeliveryMethod} vatAmnt={vatAmnt} getAddress={getAddress} step={step} setStepperValue={setStepperValue} cartDetails={cartDetails} getCartList={getCartDetails} cartTotal={cartTotal}  />}
      {step === 2 && <BookingSummary deliveryMethod={deliveryMethod} vatAmnt={vatAmnt} addressInfo={addressInfo} step={step} setStepperValue={setStepperValue} cartDetails={cartDetails} getCartList={getCartDetails} cartTotal={cartTotal}/>}
      <Footer />
    </>
  )

}

export default Cart;