/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Images from '../../Helpers/images';
import useStyle from './style.js';
import './style.css';
import { useState } from 'react';
import { useEffect } from 'react';
import apiservice from '../../Helpers/apiservices';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { I18nContext } from '../../contexts/i18n';
import ShimmerCard from '../../shared/ShimmerCard';

const Product = ({ state, productList: data = [] }) => {
    const { type = 'brand', id = 1 } = state || {};
    const classes = useStyle();
    const navigate = useNavigate();
    const { translate } = useContext(I18nContext);
    const [productList, setProductList] = useState([]);
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (data) {
            setProductList(data);
        }
    }, [data])

    const getData = async () => {
        setLoader(true)
        const res = await apiservice.getProductList(type, id);
        if (res.success) {
            setProductList([...res.data])
        } else {
            console.error(res.error);
        }
        setLoader(false);
    };

    return (
        <>
            <div className="row">
                {loader && <ShimmerCard />}
                {
                    productList?.length ? productList.map((item, index) => {
                        return <div className="col-md-4" key={`item-${index}`}>
                            <div className={classes.card} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate(`/productDetails/${item?.id}`)
                            }}>
                                <div className={classes.topCard}>
                                    <button className={classes.ratingBtn}>{item?.rating ? `${item.rating}.0` : '0.0'} <img src={Images.star} className={classes.star} /></button>
                                    {item?.isFavorites ? <FavoriteIcon sx={{ fill: '#da1739', cursor: 'pointer' }} />
                                        : <img src={Images.heart} className={classes.heartImg} />
                                    }
                                </div>

                                <img src={item?.image?.[0]?.url} className={classes.cardImg} />

                                <p className={classes.productBrandName}>{item.brand}</p>
                                {
                                    item?.modelNumber ? <p className="modelNumber">{item.modelNumber}</p>
                                        : null
                                }
                                <p className={classes.productName}>{item.name}</p>
                                <div className="prices">
                                    {item?.originalPrice && <p className={classes.oldPrice}>{`${translate("SAR")} ${item.originalPrice}`}</p>}
                                    <p className={classes.originalPrice}>{`${translate("SAR")} ${item.price}`}</p>

                                </div>
                                <p className={classes.stock}>{translate("In Stock")}</p>

                            </div>

                        </div>
                    }) : <div style={{ padding: '5em' }}>
                        {translate("No data found")}
                    </div>
                }
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
            </div>

        </>
    )

}

export default Product;