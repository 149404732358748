import { Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { THEME_COLORS } from "../../../theme";
import apiservice from "../../../Helpers/apiservices";
import useSnackBar from "../../../hooks/useSnackBar";
import { I18nContext } from "../../../contexts/i18n";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    backgroundColor: `transparent !important`,
    color: `${THEME_COLORS.primaryColor} !important`,
    border: `1px solid ${THEME_COLORS.primaryColor} !important`,
    textTransform: 'none !important'
  },
  ConfirmBtn: {
    backgroundColor: `${THEME_COLORS.primaryColor}`,
    color: `${THEME_COLORS.textWhite}`,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: `${THEME_COLORS.primaryColor}`,
    }
  },
}))

export const DeleteBookingDialog = (props) => {
  const { handleDeleteDialog = () => { }, deleteId, getBookingsDetails = () => { } } = props;
  const classes = useStyles();
  const { showSnack } = useSnackBar();
  const { translate } = useContext(I18nContext);

  const handleConfirmCancelOrder = async () => {
    const data = {
      "bookingStatus": "CANCEL"
    }
    const res = await apiservice.updateBooking(data, deleteId);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: res.massage });
    } else {
      showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
    }
    getBookingsDetails();
    handleDeleteDialog();
  }

  return (<>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {translate("Are you sure you want to cancel the Booking ?")}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button className={classes.cancelBtn} onClick={handleDeleteDialog}>{translate("No")}</Button>
      <Button className={classes.ConfirmBtn} onClick={handleConfirmCancelOrder}>
        {translate("Yes")}
      </Button>
    </DialogActions>
  </>)
}