import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import useStyles from './styles';
import Images from '../../Helpers/images';
import 'react-phone-input-2/lib/style.css'
import './style.css';
import { CustomTextField } from '../../shared/CustomTextField';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function TechnicianLogin() {

  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const EMAIL_PATTERN = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/;

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .matches(EMAIL_PATTERN, 'Invalid email address'),
    password: Yup.string()
      .required('Password is required')
  });

  const { handleSubmit, formState: { errors, isValid }, control } = useForm({
    //mode: 'onBlur',
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(loginValidationSchema)
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    console.info(data)
  };

  return <>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <img alt='profile' src={Images.profile} />

        <Typography component="h1" variant="h5" sx={{ mt: 1, fontWeight: 600 }}>
          Account
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginBottom: '1em' }}>
              <Controller
                render={({ field }) => {
                  return <CustomTextField {...field} label="Email" fullWidth
                    autoFocus
                    inputProps={{ className: classes.inputCls }}
                    error={!!errors?.email}
                    helperText={errors?.email?.message} />;
                }}
                name="email"
                control={control} />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '1em' }}>
              <Controller
                render={({ field }) => {
                  return <CustomTextField {...field} type={showPassword ? 'text' : 'password'}
                    label="Password" fullWidth
                    inputProps={{ className: classes.inputCls }}
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }} />;
                }}
                name="password"
                control={control} />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            className={classes.loginBtn}
          >
            LOGIN
          </Button>
          <Grid item xs={12} sx={{ margin: '1em' }}>
            <Typography className={classes.acceptanceTypo}>By clicking, I accept the <span className={classes.acceptanceSpan}>Terms & Condition & Privacy Policy</span></Typography>
          </Grid>
        </Box>
      </Box>
    </Container>
  </>
}