import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, DialogContentText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { dialogSyles } from './styles';

export function CommonDialog(props) {
  const { title = '', options = {}, onDialogClose = () => { } } = props;
  const { dialogOpen, maxWidth = 'sm', styles = {}, contentText = false,
    fullWidth = false, fullScreen = false } = options;

  const classes = dialogSyles();

  const handleClose = () => {
    onDialogClose();
  };

  return <Dialog className={classes.dialog} open={dialogOpen} fullWidth={fullWidth} fullScreen={fullScreen} maxWidth={maxWidth} style={{ ...styles }} onClose={handleClose}>
    {title && <DialogTitle id="common-dialog-title" className={classes.dialogTitle}>
      <span className={classes.dialogTitleText}>{title}</span>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>}
    <DialogContent>
      {
        contentText ? <DialogContentText classes={{ root: classes.dialogContentText }}> {props.children}  </DialogContentText> :
          <div className={classes.nonTextContent}>{props.children}</div>
      }
    </DialogContent>
  </Dialog>;
}
