import React, { createContext, useCallback, useMemo, useState } from 'react';
import { Alert, Snackbar, Typography } from '@mui/material';

export const SnackBarContext = createContext({
  message: '',
  type: 'success',
  open: false
});

const config = {
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'top'
  },
  autoHideDuration: 3000
};


export function SnackBarProvider({ children }) {
  const [snack, setSnack] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const showSnack = useCallback((newValue) => setSnack({ ...newValue }), []);
  const value = useMemo(() => ({ showSnack }), [showSnack]);

  const handleClose = () => {
    setSnack((oldvalue) => ({ ...oldvalue, open: false }));
  };

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      <Snackbar sx={{ top: '2px' }} open={snack.open} {...config} onClose={handleClose}>
        <Alert sx={{
          '& .MuiAlert-icon': {
            fontSize: '1.2em'
          },
          alignItems: 'center',
          width: '100%'
        }} onClose={handleClose} severity={snack.type}>
          <Typography sx={{
            fontSize: '1.2em',
            fontWeight: 600
          }}>{snack.message}</Typography>
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
}
