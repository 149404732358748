/* eslint-disable no-useless-concat */
import apiCrud from './apicrud';
const apiurl = "https://api.awal1.sa/v1";//dev
const apiBaseUrl = "https://api.awal1.sa/v1"
// const apiurl = "http://188.166.228.50:3009/"; //local
const apiservice = {

	getProfileDetail: async function (profileobj) {
		var response = await apiCrud.postFormData(apiurl + "admin" + "/" + "v1" + "/" + "profile", profileobj)

		return response;
	},
	getDashboardDetails: async function (homeobj) {
		var response = await apiCrud.postFormData(apiurl + "admin" + "/" + "v1" + "/" + "dashboard", homeobj)

		return response;
	},
	getMiniStatement: async function (ministateobj) {
		var response = await apiCrud.postFormData(apiurl + "admin" + "/" + "v1" + "/" + "ministatement", ministateobj)

		return response;
	},
	getTransactionByDate: async function (transobj) {
		var response = await apiCrud.postFormData(apiurl + "admin" + "/" + "v1" + "/" + "getTransactionbydate", transobj)
		return response;
	},
	sendOtp: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/auth/sendOtp`, dataObj)
		return response;
	},
	verifyOtp: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/auth/verifyOtp`, dataObj)
		return response;
	},
	getHomePageData: async function (dataObj) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user/home`, dataObj)
		return response;
	},
	getProductDetails: async function (id) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user/product/${id}`)
		return response;
	},
	getCartDetails: async function (id) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user/product/cart/view`)
		return response;
	},
	addAddress: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/address`, dataObj)
		return response;
	},
	updateAddress: async function (dataObj, id) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/address/${id}`, dataObj)
		return response;
	},
	getAddress: async function (cId) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user/address/list`)
		return response;
	},
	getFavorites: async function (cId) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user/product/favourites/list`)
		return response;
	},
	getCoupons: async function (cId) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/admin/coupon`)
		return response;
	},
	addToCart: async function (dataObj, id) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product/cart`, dataObj)
		return response;
	},
	updateCart: async function (dataObj, id) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product/cart/${id}`, dataObj)
		return response;
	},
	removeFromCart: async function (id) {
		var response = await apiCrud.deleteData(`https://api.awal1.sa/v1/user/product/cart/${id}`)
		return response;
	},
	addToFavorites: async function (dataObj, id) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product/favourites`, dataObj)
		return response;
	},
	getOrderDetails: async function () {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user/product/order/get`)
		return response;
	},
	getProductList: async function (type, id) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product?${type}=${id}`)
		return response;
	},
	addToOrder: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product/order`, dataObj)
		return response;
	},
	updateProfile: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user`, dataObj)
		return response;
	},
	getProfileDetails: async function (dataObj) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user`, dataObj)
		return response;
	},
	getfilterData: async function () {
		var response = await apiCrud.getData(apiBaseUrl + "/user/filter");
		return response;
	},
	getBanners: async function () {
		var response = await apiCrud.getData(apiBaseUrl + "/user/banner");
		return response;
	},
	getServices: async function () {
		var response = await apiCrud.getData(apiBaseUrl + "/technician/service");
		return response;
	},
	getMainServices: async function (id) {
		var response = await apiCrud.getData(apiBaseUrl + `/technician/mainService?serviceId=${id}`);
		return response;
	},
	getMainServicesWithoutId: async function (id) {
		var response = await apiCrud.getData(apiBaseUrl + `/technician/mainService`);
		return response;
	},
	getSubServices: async function (id) {
		var response = await apiCrud.getData(apiBaseUrl + `/technician/subService?mainServiceId=${id}`);
		return response;
	},
	addToBookings: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/technician/booking`, dataObj)
		return response;
	},
	updateOrder: async function (dataObj, id) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product/order/${id}`, dataObj)
		return response;
	},
	getCategories: async function () {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/admin/category`)
		return response;
	},
	getBookings: async function (id) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/technician/booking`)
		return response;
	},
	updateBooking: async function (dataObj, id) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/technician/booking/cancel/${id}`, dataObj)
		return response;
	},
	getOrderById: async function (id) {
		var response = await apiCrud.getData(apiBaseUrl + `/user/product/order/getOrderDetails?orderId=${id}`);
		return response;
	},
	getBookingById: async function (id) {
		var response = await apiCrud.getData(apiBaseUrl + `/technician/technicianPerson/bookingDetails/${id}`);
		return response;
	},
	getFilteredData: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/productFilter`, dataObj)
		return response;
	},
	getSortedData: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/productSort`, dataObj)
		return response;
	},
	getSearchData: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product`, dataObj)
		return response;
	},
	getReturnReasons: async function () {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/admin/returnReasons`)
		return response;
	},
	confirmReturn: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product/return`, dataObj)
		return response;
	},
	submitReview: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/review`, dataObj)
		return response;
	},
	getReview: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/review/get`, dataObj)
		return response;
	},
	removeAddress: async function (id) {
		var response = await apiCrud.deleteData(`https://api.awal1.sa/v1/user/address/${id}`)
		return response;
	},
	getURLS: async function (dataObj) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user/url`, dataObj)
		return response;
	},
	updateReview: async function (dataObj, id) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/review/${id}`, dataObj)
		return response;
	},
	addRecentViewedProduct: async function (dataObj) {
		var response = await apiCrud.postFormData(`https://api.awal1.sa/v1/user/product/recentViewedProduct`, dataObj)
		return response;
	},
	getDownloadInvoice: async function (id) {
		var response = await apiCrud.getData(`https://api.awal1.sa/v1/user/getInvoice/${id}`, id)
		return response;
	},
}
export default apiservice;