/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import useStyle from '../style.js';
import { Button, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { CommonDialog } from '../../../shared/CommonDialog';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddAddress from '../../technician/components/addAddress';
import apiservice from '../../../Helpers/apiservices';
import { CircularLoader } from '../../../shared/CircularLoader';
import { useEffect } from 'react';
import { I18nContext } from '../../../contexts/i18n.js';

export const CartAddress = ({ getAddress = () => { } }) => {

  const classes = useStyle();
  const { translate } = useContext(I18nContext);
  const [savedAddress, setSavedAddress] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);
  const [addressValue, setAddressValue] = useState(0);
  const [address, setAddress] = useState(0);
  const [dialogOpen, setDialogOpen] = useState({
    selectAddressDialog: false,
    addAddressDialog: false,
  })
  const { selectAddressDialog, addAddressDialog } = dialogOpen;

  useEffect(() => {
    getAddress({ addressId: address, address: savedAddress?.[address] })
  }, [address, savedAddress])

  const handleSelectAddressDialog = () => {
    setDialogOpen({ ...dialogOpen, selectAddressDialog: !selectAddressDialog })
  }

  const handleAddAddressDialog = () => {
    setDialogOpen({ ...dialogOpen, selectAddressDialog: !selectAddressDialog, addAddressDialog: !addAddressDialog })
  }

  const handleAddressBtnClick = () => {
    setDialogOpen({ ...dialogOpen, selectAddressDialog: !selectAddressDialog, addAddressDialog: !addAddressDialog })
  }

  const handleProceedBtnClick = () => {
    setAddress(addressValue);
    setDialogOpen({ ...dialogOpen, selectAddressDialog: !selectAddressDialog })
  }

  useEffect(() => {
    getAddresses();
  }, [])

  const getAddresses = async () => {
    setAddressLoader(true);
    const res = await apiservice.getAddress(1);
    if (res.success) {
      setSavedAddress([...res?.data]);
    } else {
      console.error(res.error);
      setSavedAddress([]);
    }
    setAddressLoader(false);
  };

  const getDialogContent = () => {
    return <>
      {
        addressLoader ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularLoader /></div>
          : <><div className={classes.addressContainer}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              >
                {savedAddress?.length && savedAddress?.map((item, index) => {
                  return <Grid key={`item-${index}`}>
                    <FormControlLabel value={index} control={<Radio className={classes.radioBtn} />} label={<Typography sx={{ fontWeight: 600 }}>{item.addressName}</Typography>} />
                    <Typography className={classes.addressText}>{`${item.buildingName}`}</Typography>
                    <Typography className={classes.addressText}>{`${item.address}`}</Typography>
                    <Typography className={classes.addressText}>{item.landmark}</Typography>
                  </Grid>;
                })}
              </RadioGroup>
            </FormControl>
          </div><Grid display={'flex'} flexDirection={'column'} sx={{ mt: '1em' }}>
              <Button className={classes.addressBtn} onClick={handleAddressBtnClick}>{translate('Add Address')}</Button>
              <Button className={classes.proceedBtn} onClick={handleProceedBtnClick}>{translate("PROCEED")}  </Button>
            </Grid></>
      }
    </>
  }


  return (
    <>
      <Grid container>
        <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Select Address')}</Typography>
        <Paper className={classes.summaryPaper}>
          <Grid container alignItems={'center'} sx={{ padding: '1em' }}>
            <Grid item className={classes.couponGrid} sx={{ flex: '1 1 50%' }}>
              {
                <Grid className={classes.addressGrid}>
                  <Typography className={classes.addressText}>{`${savedAddress?.[address]?.buildingName}`}</Typography>
                  <Typography className={classes.addressText}>{savedAddress?.[address]?.address}</Typography>
                  <Typography className={classes.addressText}>{savedAddress?.[address]?.landmark}</Typography>
                </Grid>
              }
            </Grid>
            <Grid item justifyContent={'flex-end'}>
              <EditOutlinedIcon onClick={handleSelectAddressDialog} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <CommonDialog options={{ maxWidth: 'lg', dialogOpen: selectAddressDialog }} onDialogClose={handleSelectAddressDialog} title={<>{translate('Select Address')}</>}>
        {getDialogContent()}
      </CommonDialog>

      <CommonDialog options={{ maxWidth: 'lg', dialogOpen: addAddressDialog }} onDialogClose={handleAddAddressDialog} title={<>{translate('Add Address')}</>}>
        <AddAddress onClose={handleAddAddressDialog} onRefresh={getAddresses} />
      </CommonDialog>
    </>
  )
}
