/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import { Button, Chip, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from 'react';
import useStyle from '../styles.js';
import { useState } from 'react';
import { CircularLoader } from '../../../shared/CircularLoader.js';
import apiservice from '../../../Helpers/apiservices.js';
import { useEffect } from 'react';
import '../styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { CommonDialog } from '../../../shared/CommonDialog.js';
import { Rating } from './rating.js';
import { differenceInDays } from 'date-fns';
import { DeleteDialog } from './DeleteDialog.js';
import { I18nContext } from '../../../contexts/i18n.js';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyle();
  const { translate } = useContext(I18nContext);
  const [loader, setLoader] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reviewEdit, setReviewEdit] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  }

  const handleCancelOrder = async (id) => {
    setDeleteDialogOpen(true);
    setDeleteId(id);
  }

  const handleDialog = () => {
    setDialogOpen(!dialogOpen);
  }

  useEffect(() => {
    getOrderDetails();
  }, [])

  const getOrderDetails = async () => {
    setLoader(true)
    const res = await apiservice.getOrderById(id);
    if (res.success) {
      let data = res?.data;
      setOrderDetails(data);
    } else {
      console.error(res.error);
      setOrderDetails([]);
    }
    setLoader(false);
  };

  const getDialogContent = () => {
    return <Rating isReviewEdit={reviewEdit} handleDialog={handleDialog} orderDetails={orderDetails[0]} handleLoader={handleLoader} getOrderDetails={getOrderDetails} />
  }

  const handleLoader = (load) => {
    setLoader(load);
  }

  const handleDirections = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`http://maps.google.co.uk/maps?q=${item.latitude},${item.longitude}`)
  }

  const getDeleteDialogContent = () => {
    return <DeleteDialog handleDeleteDialog={handleDeleteDialog} deleteId={deleteId} getOrderDetails={getOrderDetails} />
  }

  const getButtons = (item, index) => {
    const { orderStatus, addressType, orderPlaceTime, outForDeliveryTime, deliveredTime, cartDetails, isReviewed, returnStatus } = item || {};
    const difference = differenceInDays(new Date(), new Date(deliveredTime));
    if (orderStatus === 'CANCELLED') {
      return <Button className={classes.logOutBtn}>{translate("Cancelled")}</Button>
    } else {
      if (addressType == 1) {
        if (orderPlaceTime) {
          if (deliveredTime) {
            return <Grid container justifyContent={'space-between'}>
              {isReviewed ? <Button className={classes.addressBtn} sx={{ width: '45%' }} onClick={() => { setDialogOpen(true); setReviewEdit(true) }}>{translate("EDIT REVIEW")}</Button> : <Button className={classes.addressBtn} sx={{ width: '45%' }} onClick={() => { setDialogOpen(true) }}>{translate("WRITE REVIEW")}</Button>}
              {cartDetails?.[0]?.returnpolicy > difference ? <Button className={classes.logOutBtn} sx={{ width: '45%' }} onClick={() => navigate(`/profile/order/return/${item.id}`)}>{translate("RETURN")}</Button> : null}
            </Grid>
          } else {
            return <Grid container justifyContent={'space-between'}>
              <Button className={classes.logOutBtn} sx={{ width: '45%' }} onClick={() => handleCancelOrder(item?.id)}>{translate("CANCEL")}</Button>
              <Button className={classes.logOutBtn} sx={{ width: '45%' }} onClick={(e) => handleDirections(e, item)}>{translate("GET DIRECTIONS")}</Button>
            </Grid>
          }
        }
      } else {
        if (orderPlaceTime) {
          if (deliveredTime) {
            return <Grid container justifyContent={'space-between'}>
              {isReviewed ? <Button className={classes.addressBtn} sx={{ width: '45%' }} onClick={() => { setDialogOpen(true); setReviewEdit(true) }}>{translate("EDIT REVIEW")}</Button> : <Button className={classes.addressBtn} sx={{ width: '45%' }} onClick={() => { setDialogOpen(true); }}>{translate("WRITE REVIEW")}</Button>}
              {cartDetails?.[0]?.returnpolicy > difference ? <Button className={classes.logOutBtn} sx={{ width: '45%' }} onClick={() => navigate(`/profile/order/return/${item.id}`)}>{translate("RETURN")}</Button> : null}
            </Grid>
          } if (outForDeliveryTime) {
            return <Button className={classes.logOutBtn} sx={{ width: '100%' }}>{translate("OUT FOR DELIVERY")}</Button>
          } if (returnStatus === 'INITIATED') {
            return <Button className={classes.logOutBtn} sx={{ width: '100%' }}>{translate("RETURN INITIATED")}</Button>
          } if (returnStatus === 'COMPLETED') {
            return <Button className={classes.logOutBtn} sx={{ width: '100%' }}>{translate("RETURN COMPLETED")}</Button>
          } if (orderStatus === 'COMPLETED') {
            return <Grid container justifyContent={'space-between'}>
              {isReviewed ? <Button className={classes.addressBtn} sx={{ width: '45%' }} onClick={() => { setDialogOpen(true); setReviewEdit(true) }}>{translate("EDIT REVIEW")}</Button> : <Button className={classes.addressBtn} sx={{ width: '45%' }} onClick={() => { setDialogOpen(true); }}>{translate("WRITE REVIEW")}</Button>}
              {cartDetails?.[0]?.returnpolicy > difference ? <Button className={classes.logOutBtn} sx={{ width: '45%' }} onClick={() => navigate(`/profile/order/return/${item.id}`)}>{translate("RETURN")}</Button> : null}
            </Grid>
          }
          else {
            return <Button className={classes.logOutBtn} onClick={() => handleCancelOrder(item?.id)}>{translate("CANCEL")}</Button>
          }
        } else if (!orderPlaceTime) {
          return <Grid container justifyContent={'space-between'}>
            <Button className={classes.logOutBtn} sx={{ width: '45%' }}>{translate("ORDER PLACED")}</Button>
            <Button className={classes.logOutBtn} sx={{ width: '45%' }} onClick={() => handleCancelOrder(item?.id)}>{translate("CANCEL")}</Button>
          </Grid>
        }
      }
    }
  }

  const handleDownloadInvoice = async (id) => {
    setLoader(true)
    try {
      const link = document.createElement('a');
      link.href = `http://15.185.136.78:8080/v1/user/getInvoice/${id}`;
      link.download = 'invoice.pdf';
      link.click();
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
    setLoader(false)
  }

  return (
    <Grid container>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      {
        orderDetails?.length && orderDetails.map((item, index) => {
          return <Paper key={`item-${index}`} className={classes.serviceDtlsPaper}>
            <Grid container >
              <Grid item >
                <p style={{ fontWeight: 600 }}>{translate("Product Detail")}</p>
              </Grid>
              {/* <Grid item sx={{ ml: 'auto' }} >
                <p style={{ fontWeight: 600, color: '#40af40' }}>{'Delivery  30,Apr'}</p>
              </Grid> */}
            </Grid>
            <Grid container sx={{ mb: '1em', background: '#f5f6f8', padding: '2em' }}>
              <Grid item >
                <img src={item?.cartDetails?.[0]?.image?.[0]?.url} style={{ height: '160px', width: '140px' }} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/productDetails/${item?.order?.productId}`)
                }} alt='order-img' />
              </Grid>
              <Grid item sx={{ m: '0 2em' }}>
                <p className={classes.productBrandName} style={{ marginTop: 0 }}>{item?.order?.brandName}</p>
                <p className={classes.productName}>{item?.order?.productName}</p>
                <div className={classes.prices}>
                  <Chip className={classes.quanchip} label={`${translate("Quantity")}: ${item?.cartDetails?.[0]?.quantity}`} />
                  {
                    item?.cartDetails?.[0]?.attributes?.length ? item?.cartDetails?.[0]?.attributes?.map((val, ind) => {
                      if (val?.type === 'text') {
                        return <Chip className={classes.quanchip} label={`${val?.title}: ${val?.value}`} />
                      } return null
                    }) : null
                  }
                </div>
              </Grid>
            </Grid>
            {getButtons(item, index)}
            {item?.orderStatus === 'COMPLETED' &&
              <Grid container>
                <Button className='invoiceBtn' onClick={() => handleDownloadInvoice(item.id)}>
                <CloudDownloadIcon sx={{mr: '0.6em'}}/>
                  {translate("DOWNLOAD INVOICE")}</Button>
              </Grid>
            }
            <Grid container >
              <Grid item sx={{ marginTop: '1em' }} >
                <p style={{ fontWeight: 600 }}>{translate('Delivery Method')}</p>
              </Grid>
              <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.5em' }}>
                <Grid item className={classes.couponGrid}>
                  <DoorSlidingOutlinedIcon />
                </Grid>
                <Grid item>
                  {
                    item?.addressType == 0 ? <Typography>{translate("Door Delivery")}</Typography> : <Typography>{translate("Pick Up")}</Typography>
                  }
                </Grid>
              </Grid>
            </Grid>
            {
              item?.addressType == 0 ?
                <Grid container sx={{ marginTop: '1em' }} >
                  <Grid item >
                    <p style={{ fontWeight: 600 }}>Delivery Address</p>
                  </Grid>
                  <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.5em' }}>
                    <Grid item sx={{ textAlign: 'left' }}>
                      <Typography sx={{ fontWeight: 600 }}>{item?.deliveryAddress?.addressName}</Typography>
                      <Typography className={classes.addressText}>{`${item?.deliveryAddress?.buildingName}`}</Typography>
                      <Typography className={classes.addressText}>{`${item?.deliveryAddress?.address}`}</Typography>
                      <Typography className={classes.addressText}>{item?.deliveryAddress?.landmark}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                : <Grid container sx={{ marginTop: '1em' }} >
                  <Grid item >
                    <p style={{ fontWeight: 600 }}>{translate("Self Pickup")}</p>
                  </Grid>
                  <Grid container sx={{ background: '#f5f6f8', padding: '0.5em' }} flexDirection={'column'}>
                    <Grid item sx={{ textAlign: 'left' }}>
                      <Typography >{item?.pickupAddress}</Typography>
                    </Grid>
                    <Grid item >
                      <Button className={classes.logOutBtn} sx={{ margin: '1em 0', display: 'flex', alignItems: 'flex-start' }} onClick={(e) => handleDirections(e, item)}>{translate("GET DIRECTIONS")}</Button>
                    </Grid>
                  </Grid>
                </Grid>
            }
            <Grid container sx={{ marginTop: '1em' }}>
              <Grid item >
                <p style={{ fontWeight: 600 }}>{translate("Payment Method")}</p>
              </Grid>
              <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.5em' }}>
                <Grid item>
                  <Typography>Cash on Delivery</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '1em' }} >
              <Grid item >
                <p style={{ fontWeight: 600 }}>{translate("Total Product Amount")}</p>
              </Grid>
              <Grid container alignItems={'center'} sx={{ background: '#f5f6f8', padding: '0.5em' }}>
                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                  <Typography sx={{ textAlign: 'left', display: 'flex' }}>{translate('Product Amount')}<span className={classes.mrpRate}>{`${translate("SAR")} ${item?.order?.netAmount}`}</span></Typography>
                  <Typography sx={{ textAlign: 'left', display: 'flex' }}>{translate('Coupon')}<span className={classes.mrpRate}>{`- ${translate("SAR")} ${item?.order?.productPrice - item?.order?.total}`}</span></Typography>
                  <Typography sx={{ textAlign: 'left', display: 'flex' }}>{translate("VAT Amount")}<span className={classes.mrpRate}>{`${translate("SAR")} ${Number(item?.order?.vat)?.toFixed(2)}`}</span></Typography>
                  <Divider sx={{ margin: '10px 0' }} />
                  <Typography sx={{ textAlign: 'left', fontWeight: 600, display: 'flex' }}>{translate('Total Amount')}<span className={classes.mrpRate}>{`${translate("SAR")} ${Number(item?.order?.total)?.toFixed(2)}`}</span></Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        })
      }
      <CommonDialog options={{ maxWidth: 'sm', dialogOpen: dialogOpen }} onDialogClose={handleDialog} >
        {getDialogContent()}
      </CommonDialog>
      <CommonDialog options={{ maxWidth: 'sm', dialogOpen: deleteDialogOpen }} onDialogClose={handleDeleteDialog} >
        {deleteDialogOpen && getDeleteDialogContent()}
      </CommonDialog>
    </Grid>
  )
}
