/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Images from '../../Helpers/images';
import './style.css';
import { useEffect } from 'react';
import apiservice from '../../Helpers/apiservices';
import { I18nContext } from '../../contexts/i18n';

const Footer = () => {
    const navigate = useNavigate();
    const { translate } = useContext(I18nContext);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getHomePageData();
    }, [])

    const getHomePageData = async () => {
        const res = await apiservice.getCategories();
        if (res.success) {
            setCategories([...res.data])
        } else {
            console.error(res.error);
            setCategories([]);
        }
    };

    const handleNavigateList = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/productList`, { state: { type: 'categoryId', id: item?.id } })
    }

    return (
        <div className="footerBorder">
            <div className="container-fluid border-footer">
                <div className="row p-3">
                    <div className="col-md-2 text-start">
                        <h6 className="hFooter">{translate("Categories")}</h6>
                        {
                            categories?.length ? categories.map((item, index) => {
                                return index < 5 ? <ul className="categoryList" key={`item-${index}`}>
                                    <li onClick={(e) => handleNavigateList(e, item)}>{item?.name}</li>
                                </ul> : null
                            }) : null
                        }
                    </div>
                    <div className="col-md-2 text-start">
                        <h6 className="hFooter">{translate("Our Services")}</h6>
                        <ul className="categoryList">
                            <li>Ac Split</li>
                            <li>Central AC</li>
                        </ul>
                    </div>
                    <div className="col-md-2 text-start">
                        <h6 className="hFooter">{translate("Help & Support")}</h6>
                        <ul className="categoryList">
                            <li>Chat {translate("Chat")}</li>
                        </ul>
                    </div>
                    <div className="col-md-2 text-start">
                        <h6 className="hFooter">{translate("Policy")}</h6>
                        <ul className="categoryList">
                            <li>{translate("Privacy Policy")}</li>
                            <li>{translate("Terms & Condition")}</li>
                        </ul>
                    </div>
                    <div className="col-md-2 text-start">
                        <h6 className="hFooter">{translate("Contact with us")}</h6>
                        <div className="socialSec">
                            <img src={Images.facebook} className="socialIcon" />
                            <img src={Images.twitter} className="socialIcon" />
                            <img src={Images.youtube} className="socialIcon" />
                        </div>
                    </div>
                    <div className="col-md-2 text-start">
                        <h6 className="hFooter">{translate("Download In")}</h6>
                        <div className="socialSec1">
                            <img src={Images.istore} className="socialIconPlay" />
                            <img src={Images.gstore} className="socialIconPlay" />
                        </div>
                    </div>

                </div>

                <h5 className="copyrights">{translate("Awal . All rights reserved")}</h5>
            </div>
        </div>
    )

}

export default Footer;