/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CustomTextField } from '../../../shared/CustomTextField.js';
import useStyle from '../styles.js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import '../styles.css'
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileSchema } from '../constants.js';
import apiservice from '../../../Helpers/apiservices.js';
import useSnackBar from '../../../hooks/useSnackBar.js';
import { useState } from 'react';
import { CircularLoader } from '../../../shared/CircularLoader.js';
import { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import { I18nContext } from '../../../contexts/i18n.js';

export const UpdateProfile = () => {

  const classes = useStyle();
  const { showSnack } = useSnackBar();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { translate } = useContext(I18nContext);

  const { handleSubmit, formState: { errors }, control, reset } = useForm({
    //mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      // phoneNumber: ''
    },
    resolver: yupResolver(ProfileSchema)
  });

  useEffect(() => {
    getProfileDetails();
  }, [])

  const getProfileDetails = async () => {
    if (localStorage.getItem('authToken')) {
      setLoader(true)
      const res = await apiservice.getProfileDetails();
      if (res.success) {
        reset(res?.data?.[0]);
      } else {
        console.error(res.error);
        reset({});
      }
      setLoader(false);
    } else {
      navigate("/login", { state: { from: '/' } })
    }
  }

  const onSubmit = async (data) => {
    const res = await apiservice.updateProfile(data);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: <>{translate("User updated successfully")}</> });
    } else {
      showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
    }
    getProfileDetails();
  };

  return (
    <Paper className={classes.paper}>

      <Grid container>
        <Typography sx={{ fontWeight: 600 }}>{translate("Update Profile")}</Typography>
      </Grid>

      {loader && <Grid><CircularLoader overlay={true} /></Grid>}

      <Grid container>
        <Grid item xs={12} lg={6} md={6} sx={{ padding: '1em 1em 0 0' }}>
          <Controller
            render={({ field }) => {
              return <CustomTextField {...field}
                placeholder={translate("First Name")}
                fullWidth
                value={field?.value || ''}
                inputProps={{ className: classes.inputCls }}
                error={!!errors?.firstName}
                helperText={errors?.firstName? translate("First Name") : ''} 
                />;
            }}
            name="firstName"
            control={control} />
        </Grid>
        <Grid item xs={12} lg={6} md={6} sx={{ padding: '1em 1em 0 0' }}>
          <Controller
            render={({ field }) => {
              return <CustomTextField {...field}
                placeholder={translate("Last Name")}
                fullWidth
                value={field?.value || ''}
                inputProps={{ className: classes.inputCls }}
                error={!!errors?.lastName}
                helperText={errors?.lastName? translate("Last Name") : ''} 
                />;
            }}
            name="lastName"
            control={control} />
        </Grid>
        <Grid item xs={12} lg={6} md={6} sx={{ padding: '1em 1em 0 0' }}>
          <Controller
            control={control}
            name={`mobileNumber`}
            render={({ field }) => {
              return <PhoneInput
                inputClass={classes.inputClass}
                buttonClass={classes.buttonClass}
                containerClass={classes.containerClass}
                {...field}
                disabled
                disableCountryCode
                disableDropdown
                value={field?.value || ''}
                specialLabel={translate("Enter Mobile Number")}
                 />;
            }} />
        </Grid>
        <Grid item xs={12} lg={6} md={6} sx={{ padding: '1em 1em 0 0' }}>
          <Controller
            render={({ field }) => {
              return <CustomTextField {...field}
                placeholder={translate("Email")}
                fullWidth
                value={field?.value || ''}
                inputProps={{ className: classes.inputCls }}
                error={!!errors?.email}
                helperText={errors?.email? translate("Email"): ''} />;
            }}
            name="email"
            control={control} />
        </Grid>
      </Grid><Grid container>
        <Button className={classes.updateButton} onClick={handleSubmit(onSubmit)}>{translate("UPDATE")}</Button>
      </Grid>

    </Paper>
  )
}
