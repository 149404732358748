/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@mui/material';
import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { FormTextField } from '../../../shared/FormTextField';
import useStyles from './../styles';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import apiservice from '../../../Helpers/apiservices';
import useSnackBar from '../../../hooks/useSnackBar';
import { useEffect } from 'react';
import PlacesAutocomplete from '../../../shared/PlacesAutocomplete';
import { FormSelect } from '../../../shared/FormSelect';
import { SAVED_ADDRESSES } from '../technician-constant';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/i18n';

export default function AddAddress(props) {
  const { onClose = () => { }, onRefresh = () => { }, getAddresses = () => { }, updateAddress = {} } = props;
  const classes = useStyles();
  const { showSnack } = useSnackBar();
  const { translate } = useContext(I18nContext);

  const AddressFormSchema = Yup.object().shape({
    buildingName: Yup.string().required('Building Name is required').nullable(true),
    landmark: Yup.string().required('Landmark is required').nullable(true),
    address: Yup.string().required('Address is required').nullable(true),
    saveAs: Yup.string().required('Save As type is required').nullable(true),
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      userId: 1,
      flatNo: null,
      addressName: null,
      buildingName: null,
      landmark: null,
      address: null,
      saveAs: null
    },
    resolver: yupResolver(AddressFormSchema)
  });
  const { control, formState: { errors }, handleSubmit, reset, setValue } = methods;

  const SAVE_AS = [
    { label: 'Home', value: 'Home' },
    { label: 'Other', value: 'Other' }
  ]

  useEffect(() => {
    if (Object.keys(updateAddress).length) {
      reset(updateAddress)
    }
  }, [updateAddress])

  const onSubmit = async (data) => {
    const res = data.id ? await apiservice.updateAddress(data, data.id) : await apiservice.addAddress(data);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: res.massage });
    } else {
      showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
    }
    onClose();
    onRefresh();
    getAddresses()
  };

  const handleAddressData = (data, addressType) => {
    const { streetAddress1, city, state, country, zipcode, county } = data;
    setValue('address', `${streetAddress1 ? `${streetAddress1},` : ''} ${city ? `${city},` : ''} ${state ? `${state},` : ''} ${country ? `${country}` : ''}`)
  };


  return <Grid sx={{ height: '350px' }}>
    <FormProvider {...methods} >
      <Grid item xs={12} >
        <Controller
          render={({ field }) => {
            return <PlacesAutocomplete
              fieldProps={{ ...field }}
              needAddressComponent={true}
              onPlaceSelect={(data) => handleAddressData(data)}
              textProps={{
                variant: 'standard',
                label: `${translate('Address')}*`,
                error: !!errors?.address,
                helperText: errors?.address ? `${translate('Address')}` : ''
              }} />;
          }}
          name={'address'}
          control={control} />
      </Grid>
      <Grid item xs={12} >
        <Controller
          render={({ field }) => {
            return <FormTextField
              textProps={{
                ...field,
                required: true,
                error: !!errors?.buildingName,
                helperText: errors?.buildingName ? `${translate('Building Name')}*` : '',
                placeholder: `${translate('Building Name')}`
              }}
              styles={{ root: classes.formInputRoot }}
              showIcon={false} />;
          }}
          name="buildingName"
          control={control} />
      </Grid>
      <Grid item xs={12} >
        <Controller
          render={({ field }) => {
            return <FormTextField
              textProps={{
                ...field,
                required: true,
                placeholder: `${translate('Landmark')}*`,
                error: !!errors?.landmark,
                helperText: errors?.landmark ? `${translate('Landmark')}` : ''
              }}
              styles={{ root: classes.formInputRoot }}
              showIcon={false} />
          }}
          name="landmark"
          control={control} />
      </Grid>
      <Grid item xs={12} >
        <Controller
          render={({ field }) => {
            return <FormSelect
              options={SAVE_AS}
              selectProps={{
                ...field,
                value: field?.value || '',
                label: `${translate('Save As')}*`,
                required: true,
                error: !!errors?.saveAs,
                helperText: errors?.saveAs ? `${translate('Save As')}` : ''
              }} />;
          }}
          name={`saveAs`}
          control={control} />
      </Grid>
    </FormProvider>
    <Grid container justifyContent={'flex-end'} sx={{ marginTop: '1em' }}>
      <Button className={classes.addressBtn} sx={{ margin: '0 1em !important' }} onClick={onClose}>{translate("CANCEL")}</Button>
      <Button className={classes.proceedBtn} onClick={handleSubmit(onSubmit)}>{translate("Save")}</Button>
    </Grid>
  </Grid>
}
