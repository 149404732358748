/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useStyles from '../styles';
import { Grid, Paper, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import apiservice from '../../../Helpers/apiservices';
import { CircularLoader } from '../../../shared/CircularLoader';

const ProductMainServices = (props) => {
  const classes = useStyles();
  const { handleStepClick = () => { }, serviceId, getMainService = () => { } } = props;
  const [loader, setLoader] = useState(true);
  const [mainServices, setMainServices] = useState([]);

  useEffect(() => {
    getMainServices();
  }, [])

  const getMainServices = async () => {
    setLoader(true)
    const res = await apiservice.getMainServices(serviceId);
    if (res.success) {
      setMainServices(res?.data);
    } else {
      console.error(res.error);
      setMainServices([]);
    }
    setLoader(false);
  };

  const handleOnClick = (data) => {
    getMainService(data);
    handleStepClick();
  }

  return (
    <Grid container display={'flex'} flexDirection={'column'} sx={{ padding: '2em 6em' }}>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      {
        mainServices?.map((item, index) => {
          return <Paper key={`item-${index}`} className={classes.issuesPaper} onClick={() => handleOnClick(item)}>
            <Grid container alignItems={'center'}>
              <Grid item display={'flex'} flexDirection={'column'} sx={{ margin: '1em' }} lg={10} md={10}>
                <Typography sx={{ textAlign: 'left', fontWeight: 600 }}>{item.name}</Typography>
                <Typography sx={{ textAlign: 'left', fontWeight: 500 }}>{item.enDescription}</Typography>
              </Grid>
              <Grid item sx={{ marginLeft: 'auto' }}>
                <ArrowForwardIosIcon sx={{ marginRight: '1em' }} />
              </Grid>
            </Grid>
          </Paper>
        })
      }
    </Grid>
  )

}

export default ProductMainServices;