/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import { Button, Chip, Grid, Paper, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { CommonDialog } from '../../../shared/CommonDialog';
import { CircularLoader } from '../../../shared/CircularLoader';
import apiservice from '../../../Helpers/apiservices';
import useSnackBar from '../../../hooks/useSnackBar';
import useStyles from '../style.js';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { I18nContext } from '../../../contexts/i18n';

const ProductListInCart = ({ cartDetails, getCartList, step = 0, setCartDetailsArray = () => { } }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { translate } = useContext(I18nContext);
  const [productDetails, setProductDetails] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [coupon, setCoupon] = useState({
    value: '',
    index: 0
  });
  const { showSnack } = useSnackBar();
  const [coupons, setCoupons] = useState([]);
  const [couponApplied, setCouponApplied] = useState({
    index: 0,
    status: false
  });

  useEffect(() => {
    getCoupons();
  }, [])

  const handleDialog = () => {
    setDialogOpen(!dialogOpen);
  }

  const handleDelete = (data) => {
    setProductDetails({ ...data })
    setDialogOpen(!dialogOpen)
  }

  const deleteCartItem = async (cartId) => {
    setDialogOpen(!dialogOpen)
    setLoader(true)
    const res = await apiservice.removeFromCart(cartId);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: res.massage });
    } else {
      showSnack({ open: true, type: 'error', message: <>{translate("Something went wrong")}</> });
      console.error(res.error);
    }
    setLoader(false);
    getCartList();
    setCoupon({ index: 0, value: '' });
  };

  const moveToWishlist = async (id) => {
    setLoader(true);
    const data = {
      "productId": id,
      "isFavourites": 1
    }
    const res = await apiservice.addToFavorites(data);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: res.massage });
    } else {
      showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
    }
    setLoader(false);
    setDialogOpen(false);
    getCartList();
    setCoupon({ index: 0, value: '' });
    deleteCartItem(id);
  }

  const getDialogContent = () => {
    return <Grid container>
      <Grid container justifyContent={'flex-end'}>
        <CloseIcon onClick={handleDialog} sx={{ cursor: 'pointer' }} />
      </Grid>
      <Grid container>
        <Grid item >
          <img src={productDetails?.image?.[0]?.url} style={{ height: '160px', width: '140px' }} />
        </Grid>
        <Grid item sx={{ m: '0 2em', flex: '1 1 50%' }}>
          <Typography sx={{ fontWeight: 600 }}>{translate("Remove From Cart ?")}</Typography>
          <Grid container>
            <Button className={classes.removeBtn} onClick={() => deleteCartItem(productDetails.id)}>{translate("REMOVE")}</Button>
            <Button className={classes.proceedBtn} onClick={() => moveToWishlist(productDetails.id)}>{translate("MOVE TO WISHLIST")}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  const getCoupons = async () => {
    setLoader(true);
    const res = await apiservice.getCoupons();
    if (res.success) {
      setCoupons([...res?.data]);
    } else {
      console.error(res.error);
      setCoupons([]);
    }
    setLoader(false);
  };

  const handleCouponApply = (ind, item) => {
    const { price } = item;
    let couponDetails = {};
    const isPresent = coupons.some(obj => obj.couponCode === coupon?.value);
    if (isPresent) {
      coupons.forEach((coup, i) => {
        if (coup.couponCode === coupon?.value) {
          couponDetails = coup;
          setCouponApplied({ status: true, index: ind });
          showSnack({ open: true, type: 'success', message: <>{`${translate("Congratulations, You have saved the amount of")} ${coup.maximumDiscount}`}</> });
        }
      })
      const { discountPercentage, maximumDiscount } = couponDetails;
      const percDisc = (price * (discountPercentage / 100));
      const couponDiscount = percDisc > maximumDiscount ? maximumDiscount : percDisc;
      cartDetails.data.forEach((element, index) => {
        if (index === coupon.index) {
          element.total = price - couponDiscount
        }
      });
      setCartDetailsArray(cartDetails);
    } else {
      showSnack({ open: true, type: 'error', message: 'No coupon found.' });
    }
  }

  const handleRemoveCoupon = (e, i, item) => {
    e.preventDefault();
    e.stopPropagation();
    setCouponApplied({ status: false, index: i });
    setCoupon({ index: i, value: '' });
    cartDetails.data.forEach((element, index) => {
      if (index === coupon.index) {
        element.total = item.price
      }
    });
    setCartDetailsArray(cartDetails);
  }

  const couponPaper = (index, item) => {
    return <Paper className={classes.serviceDtlsPaper} sx={{ padding: '0.5em', margin: '0.5em' }}>
      <Grid container>
        <Grid item sx={{ background: '#f5f6f8', paddingLeft: '0.5em', width: '80%' }}>
          <TextField
            id="standard-multiline-static"
            autoFocus
            value={coupon.index === index ? coupon?.value : ''}
            onChange={(event) => {
              setCoupon({ value: event.target.value, index: index });
            }}
            InputLabelProps={{
              className: classes.inputLabel
            }}
            sx={{
              display: 'flex',
              width: '100%',
              '& .MuiInputBase-root.MuiInput-root:before': {
                borderBottom: 'none'
              },
              '& .MuiInputBase-root.MuiInput-root:after': {
                borderBottom: 'none'
              },
              '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottom: 'none'
              },
              '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):after': {
                borderBottom: 'none'
              },
              '& .MuiInputBase-root.MuiInput-root': {
                marginTop: 0
              },
            }}
            variant={"standard"}
          />
        </Grid>
        <Grid item display={'flex'} justifyContent={'flex-end'} sx={{ ml: 'auto' }}>
          {
            couponApplied.index === index && couponApplied?.status ? <Button className={classes.button} onClick={(e) => handleRemoveCoupon(e, index, item)}>{translate("Remove coupon")}</Button>
              : <Button className={classes.button} onClick={() => handleCouponApply(index, item)}>{translate("Apply")}</Button>
          }
        </Grid>
      </Grid>
    </Paper>
  }

  return (
    <>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}

      <Grid item sx={{ padding: '0.5em 1em 0 1em' }}>
        <Grid container>
          <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Products List')}</Typography>
          {/* <Typography className={classes.awalText}>{'Delivery by'} <span style={{ color: 'green' }}>29-30 Sep. Monday</span></Typography> */}
          {
            cartDetails?.data?.length ? cartDetails?.data?.map((item, index) => {
              return <React.Fragment key={`item-${index}`}><Paper className={classes.serviceDtlsPaper}>
                {(step === 0) && <Grid container justifyContent={'flex-end'}>
                  <CloseIcon onClick={() => handleDelete(item)} />
                </Grid>}
                <Grid container >
                  <Grid item onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/productDetails/${item?.productId}`)
                  }}>
                    <img src={item?.image?.[0]?.url} style={{ height: '160px', width: '140px' }} />
                  </Grid>
                  <Grid item sx={{ m: '0 2em' }}>
                    <p className={classes.productBrandName}>{item.brand}</p>
                    {
                      item?.modelNumber ? <p className="modelNumber">{item.modelNumber}</p>
                        : null
                    }
                    <p className={classes.productName}>{item.name}</p>
                    <div className="prices">
                     {item?.originalPrice ?  <p className={classes.oldPrice}>{`${translate("SAR")} ${item?.originalPrice}`}</p> : null}
                      <p className={classes.originalPrice}>{`${translate("SAR")} ${item?.price}`}</p>
                    </div>
                    {
                      item?.isStock ? <p className={classes.stock}>{translate("In Stock")}</p>
                        : <p className={classes.stock} style={{ color: 'red' }}>{translate("Out of stock")}</p>
                    }
                    <div className={classes.prices}>
                      <Chip className={classes.quanchip} label={`${translate("Quantity")}: ${item?.quantity}`} />
                      {
                        item?.attributes?.length ? item?.attributes?.map((val, ind) => {
                          if (val?.type === 'text') {
                            return <Chip className={classes.quanchip} key={`val-${ind}`} label={`${val?.title}: ${val?.value}`} />
                          } return null
                        }) : null
                      }
                    </div>
                  </Grid>
                </Grid>
              </Paper>
                {(couponApplied.status === false && step === 0) ? couponPaper(index, item) : (couponApplied.index === index && step === 0) ? couponPaper(index, item) : null}
              </React.Fragment>
            }) : <Paper className={classes.serviceDtlsPaper}>
              <Grid container>
                {translate("Cart is Empty.")}
              </Grid>
            </Paper>
          }
        </Grid>
      </Grid>
      <CommonDialog options={{ maxWidth: 'sm', dialogOpen: dialogOpen }} onDialogClose={handleDialog} >
        {getDialogContent()}
      </CommonDialog>
    </>
  )

}

export default ProductListInCart;