import React, { useState } from 'react';
import { Box, ClickAwayListener, Grid, IconButton, Paper, TextField, Typography, InputAdornment } from '@mui/material';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import CloseIcon from '@mui/icons-material/Close';
import { PlacesAutocompleteStyles } from './styles';

const PlacesAutocomplete = (props) => {
  const classes  = PlacesAutocompleteStyles();
  const { textProps = {}, fieldProps = {}, icon, clear = false, needAddressComponent = false, styles = {}, nonEditable = false } = props;
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({ apiKey: "AIzaSyCVa5ySWY9UIRLpAFQqbvJ8JTqCfMsKzUo", debounce: 300 });

  const [ showPlacesDropdown, setShowPlaces ] = useState(false);
  const [ activeDropdownIndex, setActiveDropdownIndex ] = useState(-1);
  const [ clearIcon, setClearIcon ] = useState(clear);

  const handleOptionClick = (data) => {
    if (needAddressComponent) {
      getPlaceDetails(data);
    } else {
      fieldProps.onChange(data.description);
    }
    if (showPlacesDropdown) {
      setShowPlaces(false);
    }
  };

  const getPlaceDetails = (data) => {
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId: data.place_id
    };

    service && service.getDetails(request, (place, status) => {
      if (status === 'OK') {
        getSelectedPlaces(place.address_components);
      }
    });
  };

  const getSelectedPlaces = (data) => {
    const addressObj = {};
    for (const component of data) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressObj.streetAddress1 = `${component.short_name}`;
          break;
        }
        case 'route': {
          addressObj.streetAddress1 = `${addressObj.streetAddress1 ? addressObj.streetAddress1 : ''} ${component.short_name}`;
          break;
        }
        case 'postal_code': {
          addressObj.zipcode = `${component.short_name}`;
          break;
        }
        case 'locality':
          addressObj.city = component.short_name;
          break;
        case 'administrative_area_level_1': {
          addressObj.state = component.short_name;
          break;
        }
        case 'administrative_area_level_2': {
          addressObj.county = component.short_name;
          break;
        }
        case 'country': {
          addressObj.country = component.short_name;
          break;
        }
        default: {
          break;
        }
      }
    }
    props.onPlaceSelect(addressObj);
  };

  const isIndexUpdatable = (keycode, index, length) => {
    if (keycode === 38) {
      return index > 0;
    }
    return index < length - 1;
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (showPlacesDropdown) {
      if (keyCode === 38 || keyCode === 40) {
        if (isIndexUpdatable(keyCode, activeDropdownIndex, placePredictions.length)) {
          let index = activeDropdownIndex;
          const updatedIndex = keyCode === 38 ? --index : ++index;
          setActiveDropdownIndex(updatedIndex);
        }
      } else if (e.keyCode === 13 || e.type === 'click') {
        if (activeDropdownIndex !== -1) {
          if (needAddressComponent) {
            getPlaceDetails(placePredictions[activeDropdownIndex]);
          } else {
            fieldProps.onChange(placePredictions[activeDropdownIndex].description);
          }
          setActiveDropdownIndex(-1);
        }
        setShowPlaces(false);
      }
    }
  };

  const handleClear = () => {
    fieldProps.onChange('');
  };

  const handleSearchInput = (event) => {
    getPlacePredictions({ input: event.target.value });
    fieldProps.onChange(event.target.value);
    if (!showPlacesDropdown) {
      setShowPlaces(true);
    }
  };

  const renderSuggestions = () => {
    return <ClickAwayListener onClickAway={() => { setShowPlaces(false); setActiveDropdownIndex(-1); }}>
      <Paper className={classes.paper}>
        {
          placePredictions.map((place, index) => {
            return <div key={place.place_id} onClick={() => handleOptionClick(place)} className={index === activeDropdownIndex ? classes.optionHover : classes.option}>
              <Typography className={classes.optionTypo} >{place.description}</Typography>
            </div>;
          })
        }
        {
          placePredictions?.length && <Grid display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
            <Typography className={classes.poweredBy} >{'Powered by'}</Typography>
            <img className={classes.googleLogo} src={'/images/google-logo.svg'} alt="google" />
          </Grid>
        }
      </Paper>
    </ClickAwayListener>;
  };

  return <Box sx={{ display: 'flex', padding: '0.25em 0' }} >
    {icon && <Grid sx={{ minWidth: '2em', mt: '1em', mr: '0.25em' }}>{icon}</Grid>}
    <Grid sx={{ position: 'relative', flexGrow: 1, mr: '1em', ...styles }} onKeyDown={(e) => handleKeyDown(e)}>
      <TextField disabled={nonEditable} fullWidth value={fieldProps?.value || ''} onChange={handleSearchInput} {...textProps}
        InputProps={{
          autoComplete: 'off',
          endAdornment: clearIcon && <InputAdornment position="end" sx={{ cursor: 'pointer' }} >
            <IconButton disabled={nonEditable} disableRipple={true} size="small" onClick={handleClear}><CloseIcon /></IconButton>
          </InputAdornment>
        }}
        onMouseEnter={() => setClearIcon(true)}
        onMouseLeave={() => setClearIcon(false)} />
      {showPlacesDropdown && !isPlacePredictionsLoading && placePredictions.length > 0 ? renderSuggestions() : null}
    </Grid>
  </Box>;
};

export default PlacesAutocomplete;
