import * as React from 'react';
import Footer from '../../Components/footer';
import Navbar from '../../Components/navbar';
import { TabSection } from './tabSection';

export default function Profile() {
   
    return <>
        <Navbar />
        <TabSection />
        <Footer />
    </>
}