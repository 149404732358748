import React, { useContext, useState } from "react";
import useStyles from "../style";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CommonDialog } from "../../../shared/CommonDialog";
import { useNavigate } from "react-router-dom";
import apiservice from "../../../Helpers/apiservices";
import useSnackBar from "../../../hooks/useSnackBar";
import { I18nContext } from "../../../contexts/i18n";

const CartTotal = ({ counter, cartDetails, deliveryMethod, setStepperValue = () => { }, cartTotal, step, addressInfo, vatAmnt }) => {
  const classes = useStyles();
  const { showSnack } = useSnackBar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { translate } = useContext(I18nContext);

  const handleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  const getTotalAmnt = () => {
    let productTotal = 0;
    cartDetails?.data?.forEach((item) => {
      productTotal += item.total ? item.total : item.price
    })
    return Number(productTotal).toFixed(2);
  }

  const getDialogContent = () => {
    return <Grid container flexDirection={'column'} sx={{ padding: '3em' }}>
      <Grid display={'flex'} justifyContent={'center'} sx={{ mb: '1em' }}>
        <CheckCircleIcon sx={{ fontSize: '3em', fill: '#f0493e' }} />
      </Grid>
      <Typography sx={{ mb: '1em' }}>{translate("You ordered Successfully")}</Typography>
      <Button className={classes.addressBtn} onClick={() => navigate('/profile/order')}>
        {translate("View order")}
        <ArrowForwardIosIcon sx={{ fontSize: '1em', ml: '10px' }} />
      </Button>
    </Grid>
  }

  const handleAddToOrder = async () => {
    let cartIdArr = [];
    cartTotal?.data?.forEach((item, index) => {
      let vat = item.price / (1 + (vatAmnt / 100))
      cartIdArr = [...cartIdArr, {
        cartId: item?.id, productId: item?.productId,
        originalPrice: item?.originalPrice, productName: item?.name, brandName: item?.brandName, total: item?.total ? item.total : item?.price, productPrice: item?.price,
        vat: Number(item?.price - vat).toFixed(2), netAmount: vat
      }]
    })
    const { latitude, address, longitude } = cartDetails || {};
    const doorDeliveryData = {
      "deliveryAddressId": addressInfo?.addressId,
      "deliveryAddress": JSON.stringify(addressInfo?.address),
      "deliveryAddressType": "home",
      "orderReferenceId": "12",
      "paymentTypeId": "1",
      "addressType": "0",
      order: JSON.stringify(cartIdArr)
    }
    const pickupData = {
      "orderReferenceId": "12",
      "paymentTypeId": "1",
      "addressType": "1",
      pickupAddress: address,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      order: JSON.stringify(cartIdArr)
    }
    const payload = deliveryMethod === "Door Delivery" ? doorDeliveryData : pickupData;
    const res = await apiservice.addToOrder(payload);
    if (res.success) {
      showSnack({ open: true, type: 'success', message: <>{translate("You ordered Successfully")}</> });
      setDialogOpen(true)
    } else {
      showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
    }
  }

  const handlePlaceOrder = () => {
    if (step !== 2) {
      setStepperValue();
    } else {
      handleAddToOrder();
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return <>
    <Grid container sx={{ mt: '1em' }}>
      <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Price Detail')}</Typography>
      <Paper className={classes.summaryPaper}>
        <Grid container alignItems={'center'} sx={{ padding: '1em' }}>
          <Grid item display={'flex'} flexDirection={'column'} sx={{ margin: '1em', width: '100%' }}>
            {
              cartDetails?.data?.map((val, ind)=>{
                return  <Typography key={`val-${ind}`} sx={{ textAlign: 'left', display: 'flex' }}>{val.name}<span className={classes.mrpRate}>{val.total ? `${translate("SAR")} ${val.total}`: `${translate("SAR")} ${val.price}`}</span></Typography>
              })
            }
            <Divider sx={{ margin: '10px 0' }} />
            <Typography sx={{ textAlign: 'left', fontWeight: 600, display: 'flex' }}>{translate('Total Amount')}<span className={classes.mrpRate}>{`${translate("SAR")} ${getTotalAmnt()}`}</span></Typography>
            <Button className={classes.proceedBtn} onClick={handlePlaceOrder}>{translate('PLACE ORDER')}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <CommonDialog options={{ maxWidth: 'md', dialogOpen: dialogOpen }} onDialogClose={handleDialog} >
      {getDialogContent()}
    </CommonDialog>

  </>
}

export default CartTotal;