const Images = {
    logo: require("../Images/logo.png"),
    product1: require("../Images/product1.png"),
    product2: require("../Images/product2.png"),
    product3: require("../Images/product3.png"),
    banner: require("../Images/home/banner.png"),
    whyawall1: require("../Images/home/Group 48702@2x.png"),
    whyawall3: require("../Images/home/Group 48498@2x.png"),
    whyawall2: require("../Images/home/Group 48493@2x.png"),
    brand1: require("../Images/home/original-samsung-logo-10@2x.png"),
    brand2: require("../Images/home/images@2x.png"),
    star: require("../Images/home/Icon awesome-star@2x.png"),
    heart: require("../Images/home/Group 48701@2x.png"),
    tproduct1: require("../Images/home/Mask Group 124@2x.png"),
    tproduct2: require("../Images/home/vmachine.png"),
    facebook: require("../Images/footer/Icon awesome-facebook-square@2x.png"),
    twitter: require("../Images/footer/Icon feather-instagram@2x.png"),
    youtube: require("../Images/footer/Icon awesome-youtube@2x.png"),
    istore: require("../Images/footer/Mask Group 76@2x.png"),
    gstore: require("../Images/footer/google.png"),
    store1: require("../Images/products/product1@2x.png"),
    store2: require("../Images/products/product2@2x.png"),
    store3: require("../Images/products/Mask Group 56@2x.png"),
    warranty: require("../Images/products/Icon material-security@2x.png"),
    return: require("../Images/products/Icon awesome-exchange-alt@2x.png"),
    cart: require("../Images/products/Icon feather-shopping-cart@2x.png"),
    wishlist: require("../Images/products/Icon feather-heart@2x.png"),
    placeholder: require("../Images/products/placeholder_img.png"),
    whiteStar: require("../Images/products/whiteStar@2x.png"),
    profile: require("../Images/profile.PNG")
}

export default Images;