/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStyle from './style.js'

const Products = (props) => {

    const classes = useStyle();
    const { data } = props;
    const navigate = useNavigate();

    return (
        <div className={classes.wholeContainer}>
            <div className={classes.productContainer}>
                {data?.[0] && data[0].data.map((item, index) => {
                    return <div key={`item-${index}`} className={classes.product} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigate(`/productList`, { state: { type: 'categoryId', id: item?.id } });
                    }}>
                        <img src={item.image} className={classes.productImg} />
                        <p className={classes.paraName}>{item.name}</p>
                    </div>
                })}
            </div>

        </div>
    )
}

export default Products;