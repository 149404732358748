import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import useStyles from './styles';
import Images from '../../Helpers/images';
import 'react-phone-input-2/lib/style.css'
import './style.css';
import OtpInput from 'react-otp-input';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiservice from '../../Helpers/apiservices';
import useSnackBar from '../../hooks/useSnackBar';
import { CircularLoader } from '../../shared/CircularLoader';
import { AuthContext } from '../../contexts/AuthContext';
import { I18nContext } from '../../contexts/i18n';

export default function VerifyOtp() {
    const classes = useStyles();
    const { showSnack } = useSnackBar();
    const navigate = useNavigate();
    const authContext = React.useContext(AuthContext);
    const { translate } = React.useContext(I18nContext);
    const { state = {} } = useLocation();
    const { from = '/' } = state;
    const [otpState, setOtpState] = useState('');
    const [loading, setLoading] = useState({ sendCode: false, verify: false });
    const handleOtpChange = (otp) => setOtpState(otp);
    
    const handleResendCode = async () => {
        if (state?.phone && !loading.sendCode) {
            const postRes = await apiservice.sendOtp(state.phone);
            if (postRes?.success) {
                showSnack({ open: true, type: 'success', message: <>{translate("Code has been resent successfully")}</> })
            } else {
                showSnack({ open: true, type: 'error', message: <>{translate("Something went wrong")}</> });
            }
        }
    };

    const verifyOtpCode = async () => {
        setLoading({ sendCode: true, verify: true });

        const payload = {
            ...state.phone,
            otp: Number(otpState)
        };
        const res = await apiservice.verifyOtp(payload);
        if (res.success) {
            navigate(from);
            authContext.login();
            const authToken = res.token;
            showSnack({ open: true, type: 'success', message: res.massage });
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem('authToken', authToken);
            window.location.reload(true)
        } else {
            showSnack({ open: true, type: 'error', message: res.msg || <>{translate("Something went wrong")}</> });
        }
        setLoading({ sendCode: false, verify: false });
    };

    return <>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <img alt='profile' src={Images.profile} />

                <Typography component="h1" variant="h5" sx={{ mt: 1, fontWeight: 600, marginBottom: '1em' }}>
                    Enter OTP
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OtpInput
                            containerStyle={classes.otpContainer}
                            inputStyle={classes.otpInput}
                            value={otpState}
                            onChange={handleOtpChange}
                            numInputs={4}
                        />
                    </Grid>
                </Grid>
                <Button
                    disabled={!otpState || otpState.length !== 4 || loading.sendCode || loading.verify}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={verifyOtpCode}
                    className={classes.loginBtn}
                >
                    {translate("PROCEED")}{loading.sendCode && <CircularLoader loaderProps={{ sx: { marginLeft: '1em' } }} />}
                </Button>
                <Grid item xs={12} >
                    <Typography className={classes.acceptanceTypo}>Didn't receive OTP ? <span className={classes.resendCode} onClick={handleResendCode}>Resend Code</span></Typography>
                </Grid>
            </Box>

        </Container>
    </>
}