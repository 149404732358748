
import { makeStyles } from '@material-ui/core/styles';

export const GlobalSearchStyles = makeStyles((theme) => ({
  optContainer: {
    display: 'flex',
    cursor: 'pointer',
  },
  emailOption: {
    fontSize: '0.9rem',
  },
  avatar: {
    width: '42px',
    height: '42px',
    marginRight: '0.6rem',
    [theme.breakpoints.down('sm')]: {
      width: '2.4rem',
      height: '2.4rem',
      marginRight: '0.5rem'
    }
  },
  nameOption: {
    fontSize: '1rem',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '200px',
      fontWeight: '600'
    }
  },
  emailOptionContainer: {
    lineHeight: 1.1,
    [theme.breakpoints.down('sm')]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '200px'
    }
  },
  searchPaper: {
    minWidth: '350px',
    [theme.breakpoints.between(0, 350)]: {
      minWidth: '250px'
    },
    [theme.breakpoints.between(350, 600)]: {
      minWidth: '280px'
    }
  },
  searchFieldSection: {
    display: 'flex',
    borderRadius: '0.3em',
    border: '1px solid #ccc3c3',
    flex: '0 1 330px',
    alignSelf: 'center',
  },
  searchField: {
    flex: '1 1 300px'
  },
  searchFieldInput: {
    padding: '0.25em 0.25em 0.4em 0.4em',
    fontSize: theme.typography.fontSize,
    '&::placeholder': {
      fontSize: '0.8em !important',
      fontWeight: 600
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.4em',
    borderRadius: '0.25em',
    cursor: 'pointer',
    alignSelf: 'center'
  },
}));
