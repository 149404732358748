/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from 'react';
import useStyle from '../style.js';
import { Button, Divider, Grid, MenuItem, Paper, Select, Typography } from '@mui/material';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import ProductListInCart from './productListInCart';
import CartTotal from './cartTotal';
import { CartAddress } from './CartAddress.js';
import { I18nContext } from '../../../contexts/i18n.js';

const DeliveryDetails = ({ getDeliveryMethod, vatAmnt, getAddress, setStepperValue, cartDetails, getCartList, cartTotal, step }) => {
  const classes = useStyle();
  const [deliveryMethod, setDeliveryMethod] = useState('Door Delivery');
  const { translate } = useContext(I18nContext);

  const handleChange = (event) => {
    setDeliveryMethod(event.target.value);
    getDeliveryMethod(event.target.value)
  };

  const handleDirections = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`http://maps.google.co.uk/maps?q=${cartDetails.latitude},${cartDetails.longitude}`)
  }

  const pickupData = () => {
    return <Grid container>
      <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Select Address')}</Typography>
      <Paper className={classes.summaryPaper}>
        <Grid container alignItems={'flex-start'} sx={{ padding: '1em' }} flexDirection={'column'}>
          <Grid item>
            {cartDetails.address}
          </Grid>
          <Grid item className={classes.couponGrid}>
            <Button className={classes.proceedBtn} sx={{ margin: '1em 0' }} onClick={(e) => handleDirections(e)}>{translate("GET DIRECTIONS")}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  }

  return (
    <>
      <Grid container sx={{ paddingTop: '1em' }}>
        <Grid container justifyContent={'center'} sx={{ marginBottom: '1em' }}>
          <Typography sx={{ fontWeight: 600 }}>{translate('Delivery Detail')}</Typography>
        </Grid>
        <Grid item sx={{ flex: '1 1 50%', padding: '0.5em 1em 0 1em' }}>
          <Grid container>
            <Typography sx={{ fontWeight: 600, ml: '1em' }}>{translate('Delivery Method')}</Typography>
            <Paper className={classes.summaryPaper}>
              <Grid container alignItems={'center'} sx={{ padding: '1em' }}>
                <Grid item className={classes.couponGrid}>
                  <DoorSlidingOutlinedIcon />
                </Grid>
                <Grid item>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={deliveryMethod}
                    label={translate('Delivery Method')}
                    variant="standard"
                    onChange={handleChange}
                  >
                    <MenuItem value={'Door Delivery'}>{translate("Door Delivery")}</MenuItem>
                    <MenuItem value={'Pick Up'}>{translate("Pick Up")}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {
            deliveryMethod === 'Door Delivery' ? <CartAddress getAddress={getAddress} /> : pickupData()
          }
          <ProductListInCart step={step} setStepperValue={setStepperValue} cartDetails={cartDetails} getCartList={getCartList} />
        </Grid>

        <Grid sx={{ flex: '0 0 0%', background: '#dad1d1d4' }}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item sx={{ flex: '1 1 49%', padding: '0.5em 1em 0 1em' }}>
          <CartTotal vatAmnt={vatAmnt} setStepperValue={setStepperValue} cartTotal={cartTotal} cartDetails={cartDetails} />
        </Grid>

      </Grid>
    </>
  )

}

export default DeliveryDetails;