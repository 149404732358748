import Images from "../../Helpers/images";

export   const PRODUCT_LIST = [
  { id: 1, brand: 'SAMSUNG', productName: 'LED TV', oldPrice: 'SAR 400', originalPrice: 'SAR 350', productImg: Images.tproduct1, rating: '4.5' },
  { id: 2, brand: 'SAMSUNG', productName: 'Washing Machine', oldPrice: 'SAR 400', originalPrice: 'SAR 350', productImg: Images.tproduct2, rating: '4.5' },
  { id: 3, brand: 'SAMSUNG', productName: 'LED TV', oldPrice: 'SAR 400', originalPrice: 'SAR 350', productImg: Images.tproduct1, rating: '4.5' },
  { id: 4, brand: 'SAMSUNG', productName: 'Washing Machine', oldPrice: 'SAR 400', originalPrice: 'SAR 350', productImg: Images.tproduct2, rating: '4.5' },
  { id: 5, brand: 'SAMSUNG', productName: 'LED TV', oldPrice: 'SAR 400', originalPrice: 'SAR 350', productImg: Images.tproduct1, rating: '4.5' },
  { id: 6, brand: 'SAMSUNG', productName: 'Washing Machine', oldPrice: 'SAR 400', originalPrice: 'SAR 350', productImg: Images.tproduct2, rating: '4.5' },
  { id: 7, brand: 'SAMSUNG', productName: 'LED TV', oldPrice: 'SAR 400', originalPrice: 'SAR 350', productImg: Images.tproduct1, rating: '4.5' },
  { id: 8, brand: 'SAMSUNG', productName: 'Washing Machine', oldPrice: 'SAR 400', originalPrice: 'SAR 350', productImg: Images.tproduct2, rating: '4.5' },
]

export const CAROUSEL_BREAKPOINTS = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 }
];

export const CHECK_AWAL_PRODUCT = [
  { label: 'Awal Product', value: 'awalProduct'},
  { label: 'Other', value: 'other'}
]

export const PRODUCT_ISSUES = [
  { headerText: 'AC Maintenance', subText: 'awalProducefnnveivnerivnivnrvt'},
  { headerText: 'AC Deep Cleaning', subText: 'awalProducefnnveivnerivnivnrvt'},
  { headerText: 'AC Installation', subText: 'awalProducefnnveivnerivnivnrvt'},
]

export const SAVED_ADDRESSES = [
  {name:'Priya', addressLine1: '909/24', addressLine2: 'Gandth Nagar', city: 'Chennai', state: 'Tamil Nadu', pinCode: '90900'},
  {name:'Priya', addressLine1: '909/24', addressLine2: 'Gandth Nagar', city: 'Chennai', state: 'Tamil Nadu', pinCode: '90900'},
  {name:'Priya', addressLine1: '909/24', addressLine2: 'Gandth Nagar', city: 'Chennai', state: 'Tamil Nadu', pinCode: '90900'},
  {name:'Priya', addressLine1: '909/24', addressLine2: 'Gandth Nagar', city: 'Chennai', state: 'Tamil Nadu', pinCode: '90900'},
  {name:'Priya', addressLine1: '909/24', addressLine2: 'Gandth Nagar', city: 'Chennai', state: 'Tamil Nadu', pinCode: '90900'},
  {name:'Priya', addressLine1: '909/24', addressLine2: 'Gandth Nagar', city: 'Chennai', state: 'Tamil Nadu', pinCode: '90900'}
]
