/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import useStyles from '../styles';
import { Button, Divider, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { CommonDialog } from '../../../shared/CommonDialog';
import AddAddress from './addAddress';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { CircularLoader } from '../../../shared/CircularLoader';
import apiservice from '../../../Helpers/apiservices';
import useSnackBar from '../../../hooks/useSnackBar';
import { I18nContext } from '../../../contexts/i18n';

const ProductSubServices = (props) => {
  const classes = useStyles();
  const { translate, locale } = useContext(I18nContext);
  const { handleStepClick = () => { }, mainService, getSubService = () => { }, getAddress = () => { }, subService = [], address={} } = props;
  const [value, setValue] = React.useState({
    addressValue: address?.addressValue ? address.addressValue: '',
    dateValue: address?.dateValue ? address.dateValue: '',
    serviceValue: ''
  });
  const [dialogOpen, setDialogOpen] = useState({
    selectAddressDialog: false,
    addAddressDialog: false,
    calendarDialog: false
  });
  const { selectAddressDialog, addAddressDialog, calendarDialog } = dialogOpen;
  const { addressValue, dateValue, serviceValue } = value;
  const [loader, setLoader] = useState(true);
  const [subServices, setSubServices] = useState([]);
  const [savedAddress, setSavedAddress] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);
  const { showSnack } = useSnackBar();

  useEffect(() => {
    getAddresses();
  }, [])

  const getAddresses = async () => {
    setAddressLoader(true);
    const res = await apiservice.getAddress(1);
    if (res.success) {
      setSavedAddress([...res?.data]);
    } else {
      console.error(res.error);
      setSavedAddress([]);
    }
    setAddressLoader(false);
  };

  useEffect(() => {
    getSubServices();
  }, [])

  const getSubServices = async () => {
    setLoader(true)
    const res = await apiservice.getSubServices(mainService);
    if (res.success) {
      setSubServices(res?.data);
    } else {
      console.error(res.error);
      setSubServices([]);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (addressValue && dateValue && serviceValue) {
      setDialogOpen({ ...dialogOpen, selectAddressDialog: false });
    } else {
      //setDialogOpen({ ...dialogOpen, selectAddressDialog: true });
    }
  }, [value])

  const handleSelectAddressDialog = () => {
    setDialogOpen({ ...dialogOpen, selectAddressDialog: !selectAddressDialog })
  }

  const handleAddAddressDialog = () => {
    setDialogOpen({ ...dialogOpen, selectAddressDialog: !selectAddressDialog, addAddressDialog: !addAddressDialog });
    getAddresses();
  }

  const handleBookClick = () => {
    if ( value?.serviceValue && value?.dateValue) {
      getSubService(value?.serviceValue);
      handleStepClick();
    } else if (!value?.serviceValue) {
      showSnack({ open: true, type: 'error', message: <>{translate('Please Select a service')}</> });
    } else {
      setDialogOpen({ ...dialogOpen, selectAddressDialog: !selectAddressDialog })
      getAddresses();
    }
  }

  const handleRadioClick = (e, data) => {
    let subServiceIds = [];
    subService?.forEach((service) => {
      subServiceIds.push(service?.id)
    });
    if (subServiceIds?.includes(data?.id)) {
      e.preventDefault();
      e.stopPropagation();
      e.target.checked = false;
      showSnack({ open: true, type: 'error', message: <>{translate("Service is already added")}</> });
    } else {
      setValue({ ...value, serviceValue: data });
    }
  }

  const handleAddressBtnClick = () => {
    setDialogOpen({ ...dialogOpen, selectAddressDialog: !selectAddressDialog, addAddressDialog: !addAddressDialog })
  }

  const handleDatePicker = () => {
    //handleSelectAddressDialog()
    setDialogOpen({ ...dialogOpen, calendarDialog: !calendarDialog })
    //setCalendarOpen(!calendarOpen)
  }

  const getAddressVal = (value) => {
    const filteredArr = savedAddress.filter((item) => {
      return item?.id == value
    })
    return filteredArr?.[0] || {};
  }

  const getDialogContent = () => {
    return <>
      <div className={classes.addressGrid}>
        {addressLoader && <Grid><CircularLoader overlay={true} /></Grid>}
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={addressValue}
            onChange={(e) => {
              setValue({ ...value, addressValue: e.target.value });
              getAddress({ ...value, addressValue: getAddressVal(e.target.value) });
            }}
          >
            {
              savedAddress?.map((item, index) => {
                return <Grid key={`item-${index}`} sx={{ display: 'flex', margin: '1em 0' }}>
                  <FormControlLabel value={item.id} sx={{ display: 'block' }} control={<Radio className={classes.radioBtn} />} label={<Typography sx={{ fontWeight: 600 }}>{item.name}</Typography>} />
                  <Grid>
                    <Typography sx={{ fontWeight: 600 }}>{item.addressName}</Typography>
                    <Typography className={classes.addressText}>{`${item.buildingName}`}</Typography>
                    <Typography className={classes.addressText}>{`${item.address}`}</Typography>
                    <Typography className={classes.addressText}>{item.landmark}</Typography>
                  </Grid>
                </Grid>
              })
            }
          </RadioGroup>
        </FormControl>
      </div>
      <Grid display={'flex'} flexDirection={'column'} sx={{ mt: '1em' }}>
        <Button className={classes.addressBtn} onClick={handleAddressBtnClick}>{translate('Add Address')}</Button>
        <Button className={classes.proceedBtn} onClick={handleDatePicker}>{translate('Select Slot')}  </Button>
      </Grid>
    </>
  }

  function disablePrevDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    }
  }

  return <>
    <Grid container sx={{ paddingTop: '1em' }}>
      {loader && <Grid><CircularLoader overlay={true} /></Grid>}
      <Grid item sx={{ flex: '1 1 50%', padding: '0.5em 1em 0 1em' }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {
              subServices?.map((item, index) => {
                return <Paper key={`item-${index}`} className={classes.issuesPaper}>
                  <Grid container alignItems={'center'} sx={{ margin: '1em' }}>
                    <Grid item display={'flex'} flexDirection={'column'} sx={{ marginLeft: '1em' }}>
                      <FormControlLabel value={item.name} control={<Radio size="small" className={classes.radioBtn} onClick={(e) => handleRadioClick(e, item)} />} label={item.name} />
                      <Typography sx={{ textAlign: 'left' }}>{item.enDescription}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              })
            }
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid sx={{ flex: '0 0 0%', background: '#dad1d1d4' }}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item sx={{ flex: '1 1 49%', padding: '0.5em 1em 0 1em' }}>
        <Paper className={classes.issuesPaper} sx={{ margin: '1em 0 0 0 !important' }}>
          <Grid container alignItems={'center'}>
            <Grid item display={'flex'} flexDirection={'column'} sx={{ margin: '0 1em' }}>
              <Typography sx={{ textAlign: 'left', fontWeight: 600 }}>{translate('Advance Amount')}</Typography>
              <Typography className={classes.subText}>{`${translate("SAR")} 50`}</Typography>
            </Grid>
            <Grid item sx={{ m: locale.code === 'ar' ? '0 auto 0 0': '0 0 0 auto' }}>
              <Button className={classes.proceedBtn} sx={{ marginRight: '1em !important' }} onClick={handleBookClick}>{translate('Book')}</Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <CommonDialog options={{ maxWidth: 'lg', dialogOpen: selectAddressDialog }} onDialogClose={handleSelectAddressDialog} title={translate('Select Address')}>
        {getDialogContent()}
      </CommonDialog>

      <CommonDialog options={{ maxWidth: 'lg', dialogOpen: addAddressDialog }} onDialogClose={handleAddAddressDialog} title={<>{translate('Add Address')}</>}>
        <AddAddress onClose={handleAddAddressDialog} />
      </CommonDialog>

      <CommonDialog options={{ maxWidth: 'md', dialogOpen: calendarDialog }} onDialogClose={handleDatePicker} title={<>{translate('Select Slot')} </>}>
        <StaticDatePicker
          id={`col.key`}
          shouldDisableDate={disablePrevDates(new Date())}
          open={calendarDialog ? true : false}
          closeOnSelect
          onClose={() => handleDatePicker()}
          disableHighlightToday
          value={dateValue}
          onChange={(newValue) => {
            setValue({ ...value, dateValue: newValue });
            getAddress({ ...value, dateValue: newValue, addressValue: getAddressVal(value?.addressValue) });
          }}
          renderInput={(params) => null}
          OpenPickerButtonProps={{ style: { display: 'none' } }}
          orientation="portrait" />
      </CommonDialog>

    </Grid>
  </>
}

export default ProductSubServices;