
import { makeStyles } from '@material-ui/core/styles';
import { THEME_COLORS } from '../../theme';

const useStyles = makeStyles((theme) => ({
  acceptanceTypo: {
    fontSize: '12px',
    color: THEME_COLORS.textGrey
  },
  acceptanceSpan: {
    fontWeight: 600,
    fontSize: '12px',
  },
  loginBtn: {
    backgroundColor: THEME_COLORS.primaryColor,
    '&:hover': {
      backgroundColor: THEME_COLORS.primaryColor
    }
  },
  navLink: {
    textDecoration: 'none'
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  otpInput: {
    minWidth: '3em',
    marginRight: '1em',
    lineHeight: '2.5em',
    fontSize: '1em',
    border: 'none',
    background: '#e8e9ea',
    borderRadius: '5px'
  },
  resendCode: {
    fontWeight: 600,
    fontSize: '12px',
    cursor: 'pointer'
  },
}));

export default useStyles;
